import React from 'react';
import { DialogContentText } from '@mui/material';

function MUIDialogContentText(props) {
  /**
   * props
   */
  const { children, ...rest } = props;

  return <DialogContentText {...rest}>{children}</DialogContentText>;
}

export default MUIDialogContentText;
