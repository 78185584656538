import React, { useState } from 'react';
import MUIBox from '../Common/MUIBox';
import MUICircularProgress from '../Common/MUICircularProgress';

const withLoader = (WrappedComponent) => {
  return function WithLoadingComponent(props) {
    const [isLoading, setLoading] = useState(false);
    const setLoadingState = (isComponentLoading) => {
      setLoading(isComponentLoading);
    };

    return (
      <>
        {isLoading && (
          <MUIBox
            sx={{
              width: '100%',
              height: '100%',
              margin: 'auto',
              position: 'fixed',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#f5f5f5',
              opacity: 0.6,
              zIndex: 2000,
            }}
          >
            <MUICircularProgress size="2.5rem" />
          </MUIBox>
        )}
        <WrappedComponent {...props} setLoading={setLoadingState} />
      </>
    );
  };
};

export default withLoader;
