import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import HomeIcon from '@mui/icons-material/Home';
import DiamondIcon from '@mui/icons-material/Diamond';
import WalletIcon from '@mui/icons-material/Wallet';
import RedeemIcon from '@mui/icons-material/Redeem';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import GroupIcon from '@mui/icons-material/Group';
import { useDispatch, useSelector } from 'react-redux';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import MUITypography from '../components/Common/MUITypography';
import MUIGrid from '../components/Common/MUIGrid';
import MUIContainer from '../components/Common/MUIContainer';
import { fetchAdminDashboard, getAdminDashboardDetails, getLoadingState } from '../Redux/Reducer/authReducer';
import withLoader from '../components/HOC/withLoader';

function DashboardAppPage(props) {
  /**
   * props
   */
  const { setLoading } = props;
  /**
   * hooks
   */
  const dispatch = useDispatch();

  /**
   * get admin dashboard details
   */
  const userData = useSelector(getAdminDashboardDetails)?.data;

  useEffect(() => {
    dispatch(fetchAdminDashboard());
  }, [dispatch]);

  /**
   * get loading state
   */
  const isLoading = useSelector(getLoadingState);

  useEffect(() => {
    setLoading(isLoading);
  }, [setLoading, isLoading]);

  return (
    <>
      <Helmet>
        <title> ApexTrade | Dashboard </title>
      </Helmet>

      <MUIContainer maxWidth="xl">
        <MUITypography variant="h4" sx={{ mb: 2 }}>
          Dashboard
        </MUITypography>

        <MUIGrid container spacing={2}>
          <MUIGrid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Daily Cashback Income"
              total={userData?.daily_cashback_income || 0}
              icon={<AccountBalanceWalletIcon />}
            />
          </MUIGrid>
          <MUIGrid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Daily Level Income"
              total={userData?.daily_level_income || 0}
              color="info"
              icon={<AccountBalanceWalletIcon />}
            />
          </MUIGrid>
          <MUIGrid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Total Daily Income"
              total={userData?.total_daily_income || 0}
              color="warning"
              icon={<AccountBalanceWalletIcon />}
            />
          </MUIGrid>
          <MUIGrid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Total Cashback Income"
              total={userData?.total_cashback_income || 0}
              color="error"
              icon={<AccountBalanceWalletIcon />}
            />
          </MUIGrid>
          <MUIGrid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Total Level Income"
              total={userData?.total_level_income || 0}
              color="success"
              icon={<AccountBalanceWalletIcon />}
            />
          </MUIGrid>
          <MUIGrid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Total Earning"
              total={userData?.total_earning || 0}
              color="secondary"
              icon={<DiamondIcon />}
            />
          </MUIGrid>
          <MUIGrid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Total Business"
              total={userData?.total_business || 0}
              color="secondary"
              icon={<BusinessCenterIcon />}
            />
          </MUIGrid>
          <MUIGrid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Total Direct"
              total={userData?.total_direct || 0}
              color="error"
              icon={<GroupIcon />}
            />
          </MUIGrid>
          <MUIGrid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Total Members"
              total={userData?.total_members || 0}
              color="warning"
              icon={<GroupIcon />}
            />
          </MUIGrid>
          <MUIGrid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Total Paid Earning"
              total={userData?.total_paid_earning || 0}
              color="error"
              icon={<HomeIcon />}
            />
          </MUIGrid>
          <MUIGrid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Wallet"
              total={userData?.wallet || 0}
              color="warning"
              icon={<WalletIcon />}
            />
          </MUIGrid>
          <MUIGrid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Withdraw Pending"
              total={userData?.withdraw_pending || 0}
              color="error"
              icon={<RedeemIcon />}
            />
          </MUIGrid>

          {/* <MUIGrid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Total Registered Users"
              total={userData?.register_user || 0}
              icon={<GroupIcon />}
            />
          </MUIGrid>

          <MUIGrid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Registered In Last 10 Days"
              color="info"
              total={userData?.register_last_10_days || 0}
              icon={<GroupIcon />}
            />
          </MUIGrid>

          <MUIGrid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              title="Registered In Last 30 Days"
              color="warning"
              total={userData?.register_last_30_days || 0}
              icon={<GroupIcon />}
            />
          </MUIGrid> */}
        </MUIGrid>
      </MUIContainer>
    </>
  );
}

export default withLoader(DashboardAppPage);
