import React from 'react';
import { TextField } from '@mui/material';
import MUIFormHelperText from './MUIFormHelperText';

function MUITextField(props) {
  const {
    name,
    label,
    placeholder,
    value,
    onChange,
    error,
    errors,
    type,
    accept,
    readOnly,
    size,
    fullWidth,
    inputRef,
    InputProps,
    rows,
    multiline,
  } = props;
  return (
    <>
      <TextField
        fullWidth={fullWidth}
        autoComplete="off"
        rows={rows}
        multiline={multiline}
        size={size}
        type={type}
        accept={accept}
        name={name}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        error={error}
        inputRef={inputRef}
        inputProps={{ readOnly }}
        InputProps={InputProps}
      />
      <MUIFormHelperText sx={{ color: 'error.main', ml: 0 }}>{errors}</MUIFormHelperText>
    </>
  );
}

export default MUITextField;
