import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { alpha } from '@mui/material/styles';
import { Avatar, Popover } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfile, getProfileDetails } from '../../../Redux/Reducer/authReducer';
import MUIBox from '../../../components/Common/MUIBox';
import MUIDivider from '../../../components/Common/MUIDivider';
import MUITypography from '../../../components/Common/MUITypography';
import MUIStack from '../../../components/Common/MUIStack';
import MUIIconButton from '../../../components/Common/MUIIconButton';
import MUIMenuItem from '../../../components/Common/MUIMenuItem';

const MENU_OPTIONS = [
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    route: '/profile',
  },
];

export default function AccountPopover() {
  /**
   * hooks
   */
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /**
   * states
   */
  const [open, setOpen] = useState(null);

  /**
   * get profile data
   */
  const getProfileData = useSelector(getProfileDetails)?.data;

  useEffect(() => {
    dispatch(fetchProfile());
  }, []);

  /**
   * handle open popup
   * @param {*} event
   */
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  /**
   * handle close popup
   */
  const handleClose = () => {
    setOpen(null);
  };

  /**
   * handle logout function
   */
  const handleLogout = () => {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    localStorage.removeItem('role');
    navigate('/');
    toast.success('Logout successful.')
  };

  return (
    <>
      <MUIIconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar />
      </MUIIconButton>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MUIBox sx={{ my: 1.5, px: 2.5 }}>
          <MUITypography variant="subtitle2" noWrap sx={{ textTransform: 'capitalize' }}>
            {getProfileData?.first_name} {getProfileData?.last_name}
          </MUITypography>
          <MUITypography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {localStorage.getItem('username')}
          </MUITypography>
        </MUIBox>

        <MUIDivider sx={{ borderStyle: 'dashed' }} />

        <MUIStack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MUIMenuItem
              key={option.label}
              onClick={() => {
                navigate(option.route);
                handleClose();
              }}
            >
              {option.label}
            </MUIMenuItem>
          ))}
        </MUIStack>

        <MUIDivider sx={{ borderStyle: 'dashed' }} />

        <MUIMenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MUIMenuItem>
      </Popover>
    </>
  );
}
