import React, { useState } from 'react';
import toast from 'react-hot-toast';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Avatar, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import UpdateProfileModal from './UpdateProfileModal';
import MUIBox from '../../components/Common/MUIBox';
import MUIIconButton from '../../components/Common/MUIIconButton';
import MUIGrid from '../../components/Common/MUIGrid';
import MUITypography from '../../components/Common/MUITypography';
import MUITableContainer from '../../components/Common/MUITableContainer';
import MUITable from '../../components/Common/MUITable';
import MUITableBody from '../../components/Common/MUITableBody';
import MUITableRow from '../../components/Common/MUITableRow';
import MUITableCell from '../../components/Common/MUITableCell';

function ProfileDetails({ profileData }) {
  /**
   * states
   */
  const [openModal, setOpenModal] = useState(false);

  /**
   * handle click open modal
   */
  const handleOpen = () => {
    setOpenModal(true);
  };

  /**
   * handle click close modal
   */
  const handleClose = () => {
    setOpenModal(false);
  };

  const {
    first_name: firstName,
    middle_name: middleName,
    last_name: lastName,
    email,
    unique_id: uniqueId,
  } = profileData || {};

  /**
   * handle copy function
   * @param {*} row
   */
  const handleCopy = (data) => {
    navigator.clipboard.writeText(data);
    toast.success('User ID copied successfully!');
  };

  return (
    <>
      <MUIBox
        sx={{ padding: 4, maxWidth: 600, margin: 'auto', border: '1px solid rgb(224, 224, 224)', borderRadius: 2 }}
      >
        <MUIGrid container spacing={2} alignItems="center">
          <MUIGrid item>
            <Avatar sx={{ bgcolor: 'primary.main', width: 56, height: 56 }}>
              <PersonIcon fontSize="large" />
            </Avatar>
          </MUIGrid>
          <MUIGrid item xs>
            <MUITypography variant="h5" sx={{ textTransform: 'capitalize' }}>
              {`${firstName || ''} ${middleName || ''} ${lastName || ''}`}
            </MUITypography>
            <MUIGrid container spacing={1}>
              <MUIGrid item>
                <EmailIcon color="action" />
              </MUIGrid>
              <MUIGrid item>
                <MUITypography variant="body1">{email || 'N/A'}</MUITypography>
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
          <MUIGrid item>
            <MUIIconButton onClick={handleOpen}>
              <EditIcon />
            </MUIIconButton>
          </MUIGrid>
        </MUIGrid>

        <MUITableContainer sx={{ marginTop: 4 }}>
          <MUITable>
            <MUITableBody>
              <MUITableRow>
                <MUITableCell>
                  <MUITypography variant="subtitle1" fontWeight="bold">
                    User ID
                  </MUITypography>
                </MUITableCell>
                <MUITableCell>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <MUITypography variant="body1" sx={{ textTransform: 'capitalize' }}>
                      {uniqueId}
                    </MUITypography>
                    <Tooltip title="Copy">
                      <IconButton size="small" onClick={() => handleCopy(uniqueId)} style={{ marginLeft: 5 }}>
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </span>
                </MUITableCell>
              </MUITableRow>
              <MUITableRow>
                <MUITableCell>
                  <MUITypography variant="subtitle1" fontWeight="bold">
                    First name
                  </MUITypography>
                </MUITableCell>
                <MUITableCell>
                  <MUITypography variant="body1" sx={{ textTransform: 'capitalize' }}>
                    {firstName || 'N/A'}
                  </MUITypography>
                </MUITableCell>
              </MUITableRow>
              <MUITableRow>
                <MUITableCell>
                  <MUITypography variant="subtitle1" fontWeight="bold">
                    Middle name
                  </MUITypography>
                </MUITableCell>
                <MUITableCell>
                  <MUITypography variant="body1" sx={{ textTransform: 'capitalize' }}>
                    {middleName || 'N/A'}
                  </MUITypography>
                </MUITableCell>
              </MUITableRow>
              <MUITableRow>
                <MUITableCell>
                  <MUITypography variant="subtitle1" fontWeight="bold">
                    Last name
                  </MUITypography>
                </MUITableCell>
                <MUITableCell>
                  <MUITypography variant="body1" sx={{ textTransform: 'capitalize' }}>
                    {lastName || 'N/A'}
                  </MUITypography>
                </MUITableCell>
              </MUITableRow>
              <MUITableRow>
                <MUITableCell>
                  <MUITypography variant="subtitle1" fontWeight="bold">
                    Email
                  </MUITypography>
                </MUITableCell>
                <MUITableCell>
                  <MUITypography variant="body1">{email || 'N/A'}</MUITypography>
                </MUITableCell>
              </MUITableRow>
            </MUITableBody>
          </MUITable>
        </MUITableContainer>

        <UpdateProfileModal open={openModal} handleClose={handleClose} selectedRow={profileData} />
      </MUIBox>
    </>
  );
}

export default ProfileDetails;
