import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import ClearIcon from '@mui/icons-material/Clear';
import {
  deleteEpin,
  fetchEpinsList,
  getEpinList,
  getLoadingState,
  UpdateUsedPackage,
} from '../../Redux/Reducer/epinReducer';
import { getRoleInLocaleStorage, UserActions } from '../../utils/Helper';
import MUIContainer from '../../components/Common/MUIContainer';
import TableHeader from '../../components/Common/TableHeader';
import withLoader from '../../components/HOC/withLoader';
import MUIDataGrid from '../../components/Common/MUIDataGrid';
import GenerateEpins from './GenerateEpins';
import RowOptions from '../../components/action-popover';
import AddPackageConfirmation from '../../components/add-package-confirmation/AddPackageConfirmation';
import DeleteConfirmation from '../../components/delete-popup/index';

function ConfigurationList(props) {
  /**
   * props
   */
  const { setLoading } = props;

  /**
   * hooks
   */
  const dispatch = useDispatch();

  /**
   * states
   */
  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [selectedData, setSelectedData] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const search = searchValue?.length > 2 ? searchValue : '';

  /**
   * get role in localStorage
   */
  const rolesArray = getRoleInLocaleStorage();
  const role = JSON.parse(rolesArray);

  /**
   * get epin list
   */
  const epinList = useSelector(getEpinList);
  const rowCount = epinList?.data?.count;

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  const paginationPageSize = paginationModel.pageSize;
  const paginationPage = paginationModel.page;

  const offset = paginationPage * paginationPageSize;
  const limit = paginationPageSize;

  useEffect(() => {
    dispatch(fetchEpinsList({ limit, offset, search }));
  }, [dispatch, limit, offset, search]);

  /**
   * get loading state
   */
  const isLoading = useSelector(getLoadingState);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  /**
   * clear filter
   */
  const handleClearInputValue = () => {
    setSearchValue('');
  };

  /**
   * get search value
   */
  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  /**
   * handle click modal open function
   */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /**
   * handle close
   */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * useractions
   * @param {*} data
   * @param {*} action
   */
  const handleAction = (data, action) => {
    switch (action) {
      case UserActions.ADD:
        handleUpdateUsedPackage(data);
        break;
      case UserActions.DELETE:
        handleEpinDeletion(data);
        break;
      default:
        break;
    }
  };

  /**
   * handle epin delete
   * @param {*} data
   */
  const handleEpinDeletion = (data) => {
    setSelectedData(data);
  };

  /**
   * handle epin delete
   * @param {*} data
   */
  const handleUpdateUsedPackage = (data) => {
    setSelectedData(data);
  };

  /**
   * passed selected row data and action in handleAction function
   * @param {*} data
   * @param {*} action
   */
  const handleActions = (data, action) => {
    handleAction(data, action);
  };

  /**
   * handle delete function
   * @param {*} id
   */
  const handleDeleteEpin = (data) => {
    dispatch(deleteEpin(data?.epin)).then((res) => {
      dispatch(fetchEpinsList({ limit, offset, search }));
    });
  };

  /**
   * handle click modal open function
   */
  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };

  /**
   * handle close
   */
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  /**
   * dispatch update used packages function
   * @param {*} data
   */
  const handleAddPackage = (data) => {
    dispatch(UpdateUsedPackage(data?.epin)).then((res) => {
      dispatch(fetchEpinsList({ limit, offset, search }));
    });
  };

  /**
   * handle copy function
   * @param {*} row
   */
  const handleCopy = (row) => {
    navigator.clipboard.writeText(row?.used_by_unique_id);
    toast.success('User ID copied successfully!');
  };

  /**
   * columns
   */
  const columns = [
    {
      flex: 1,
      minWidth: 100,
      field: 'used_by_unique_id',
      headerName: 'User Id',
      sortable: false,
      renderCell: ({ row }) => (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title={row?.used_by_unique_id}>
            <span>{row?.used_by_unique_id}</span>
          </Tooltip>
          <Tooltip title="Copy">
            <IconButton size="small" onClick={() => handleCopy(row)} style={{ marginLeft: 5 }}>
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </span>
      ),
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'used_by',
      headerName: 'Used by',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.used_by}>
          <span style={{ textTransform: 'capitalize' }}>{row?.used_by}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'epin',
      headerName: 'Epin',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.epin}>
          <span>{row?.epin}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 60,
      field: 'amount',
      headerName: 'Amount',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.amount}>
          <span>{row?.amount}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'used_date',
      headerName: 'Generated date',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.used_date}>
          <span>{row?.used_date}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'status',
      headerName: 'Status',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.status}>
          <span style={{ textTransform: 'capitalize' }}>{row?.status}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 120,
      sortable: false,
      field: 'actions',
      headerName: 'Actions',
      renderCell: ({ row }) => (
        <RowOptions
          handleClickOpen={handleClickOpen}
          handleUserAction={handleActions}
          selectionModel={row}
          deletebtn={role?.includes('Admin')}
          disabled={row?.active}
        />
      ),
    },
  ];

  /**
   * filtered action column when role is guest
   */
  const filteredColumns = role?.includes('Guest') ? columns.filter((column) => column.field !== 'actions') : columns;

  return (
    <>
      <Helmet>
        <title>ApexTrade | Epins </title>
      </Helmet>
      <MUIContainer>
        {role?.includes('Admin') && <GenerateEpins />}
        <TableHeader
          muiTitle
          muiClearBtn
          muiTextField
          title="E-Pins Details"
          textFieldLabel="Search"
          textFieldPlaceholder="Search"
          textFieldSize="small"
          onChange={handleChange}
          clearBtnTitle="Clear filter"
          clearBtnVariant="contained"
          clearOnClick={handleClearInputValue}
          clearBtnStartIcon={<ClearIcon />}
          clearDisabled={!searchValue?.length >= 1}
          searchValue={searchValue}
        />
        <MUIDataGrid
          rows={epinList?.data?.results || []}
          columns={filteredColumns}
          rowCount={rowCount}
          getRowId={(row) => row.id}
          onRowSelectionModelChange={undefined}
          selectionModel={undefined}
          columnHeaderHeight={60}
          page={paginationModel.page}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
        <DeleteConfirmation
          title="Delete Epin"
          contentText="Are you sure you want to delete Epin?"
          selectedData={selectedData}
          handleDelete={handleDeleteEpin}
          open={open}
          handleClose={handleClose}
        />
        <AddPackageConfirmation
          title="Add Package"
          contentText="Are you sure you want to Add Package?"
          open={openAdd}
          handleClose={handleCloseAdd}
          handleAddPackage={handleAddPackage}
          selectedData={selectedData}
        />
      </MUIContainer>
    </>
  );
}

export default withLoader(ConfigurationList);
