import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Chip, IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MUIBox from '../../../components/Common/MUIBox';
import MUIContainer from '../../../components/Common/MUIContainer';
import TableHeader from '../../../components/Common/TableHeader';
import {
  fetchLoggedUserGeneologyList,
  getLoadingState,
  getLoggedUserGeneologyList,
} from '../../../Redux/Reducer/levelsReducer';
import withLoader from '../../../components/HOC/withLoader';
import '../Genealogy/Style.css';
import MUIButton from '../../../components/Common/MUIButton';
import AddGenealogyUser from '../AddGenealogyUser/AddGenealogyUser';
import MUITypography from '../../../components/Common/MUITypography';
import MUIDivider from '../../../components/Common/MUIDivider';

const MyGenealogy = (props) => {
  /**
   * props object
   */
  const { setLoading } = props;

  /**
   * states
   */
  const [expandedNodes, setExpandedNodes] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState();

  /**
   * hooks
   */
  const dispatch = useDispatch();

  /**
   * get logged iser genealogy list
   */
  const geneology = useSelector(getLoggedUserGeneologyList);

  useEffect(() => {
    dispatch(fetchLoggedUserGeneologyList());
  }, [dispatch]);

  /**
   * get loading state
   */
  const isLoading = useSelector(getLoadingState);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  const toggleNode = (name) => {
    setExpandedNodes((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  /**
   * handle click open
   */
  const handleClickOpen = (node) => {
    setOpen(true);
    setSelectedRow(node);
  };

  /**
   * handle click modal close
   */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * handle copy function
   * @param {*} row
   */
  const handleCopy = (data) => {
    navigator.clipboard.writeText(data);
    toast.success('User ID copied successfully!');
  };

  const renderTree = (node) => {
    const isExpanded = !!expandedNodes[node.full_name];
    const iconColor = node?.earning_status ? '#28A745' : '#F44336';
    return (
      <li key={node.id}>
        <div
          className="member-view-box"
          onClick={() => toggleNode(node.full_name)}
          role="button"
          tabIndex={0}
          onKeyDown={() => toggleNode(node.full_name)}
        >
          <div className="member-image">
            <Tooltip
              key={node.id}
              placement="bottom"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: '#5A72A0',
                    '& .MuiTooltip-arrow': {
                      color: 'common.black',
                    },
                  },
                },
              }}
              title={
                <>
                  <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                    <span style={{ fontWeight: 'bold' }}>User Id: </span>
                    {node?.unique_id}
                  </MUITypography>
                  <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                    <span style={{ fontWeight: 'bold' }}>Total own business: </span>
                    {node?.total_package_amount}
                  </MUITypography>
                  <MUIDivider sx={{ bgcolor: 'white' }} />
                  {node?.user_package?.map((item) => (
                    <>
                      <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                        <span style={{ fontWeight: 'bold' }}>Package amount: </span>
                        {item?.amount}
                      </MUITypography>
                      <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                        <span style={{ fontWeight: 'bold' }}>Package entry date: </span>
                        {item?.entry_date}
                      </MUITypography>
                      <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                        <span style={{ fontWeight: 'bold' }}>Package start date: </span>
                        {item?.earning_start_date}
                      </MUITypography>
                      <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                        <span style={{ fontWeight: 'bold' }}>Package end date: </span>
                        {item?.earning_end_date}
                      </MUITypography>
                      <MUIDivider sx={{ bgcolor: 'white' }} />
                    </>
                  ))}
                </>
              }
            >
              <div className="member-details" style={{ flex: 1 }}>
                <AccountCircleIcon sx={{ color: iconColor }} fontSize="large" />
                <p style={{ fontWeight: 'bold', color: '#003366', margin: 0, textTransform: 'capitalize' }}>
                  {node?.full_name}
                </p>
                <div className="copy-section">
                  <p style={{ fontWeight: 'bold', color: iconColor, margin: 0 }}>{node?.unique_id}</p>
                  <Tooltip title="Copy">
                    <IconButton size="small" onClick={() => handleCopy(node?.unique_id)} style={{ marginLeft: 5 }}>
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
                <p style={{ fontWeight: 'bold', color: 'black', margin: 0 }}>Package: {node?.total_package_amount}</p>
                <Chip
                  size="small"
                  skin="light"
                  label={node?.is_active ? 'Active' : 'Inactive'}
                  sx={{
                    '& .MuiChip-label': { textTransform: 'capitalize' },
                    color: node?.is_active ? '#4CAF50' : '#F44336',
                    backgroundColor: node?.is_active ? '#E8F5E9' : '#FFEBEE',
                    cursor: 'context-menu',
                  }}
                />
              </div>
            </Tooltip>
          </div>
          <div>
            <ul>
              <li>
                <AccountCircleIcon sx={{ color: '#007BFF' }} fontSize="large" />
                <p style={{ fontWeight: 'bold', color: '#003366', margin: 0 }}>
                  <MUIButton
                    title="Add member"
                    startIcon={<AddIcon />}
                    onClick={() => handleClickOpen(node)}
                    style={{ marginLeft: 'auto' }}
                    size="small"
                    variant="outlined"
                  />
                </p>
              </li>
            </ul>
          </div>
        </div>
        {node.children && node.children.length > 0 && (
          <ul className={isExpanded ? 'expanded' : 'collapsed'}>
            {node.children.map((child) => renderTree(child))}
          </ul>
        )}
      </li>
    );
  };

  return (
    <MUIContainer>
      <TableHeader muiTitle title="My Genealogy" />
      <MUIBox sx={{ border: '1px solid rgb(224, 224, 224)', borderRadius: '5px', p: 2 }}>
        <MUIBox className="genealogy-body genealogy-scroll">
          <MUIBox className="genealogy-tree">
            {geneology?.data?.data && <ul>{renderTree(geneology.data.data[0] || [])}</ul>}
          </MUIBox>
        </MUIBox>
      </MUIBox>
      <AddGenealogyUser
        open={open}
        handleClose={handleClose}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
    </MUIContainer>
  );
};

export default withLoader(MyGenealogy);
