import React from 'react';
import { DialogActions } from '@mui/material';

function MUIDialogActions(props) {
  /**
   * props
   */
  const { children, ...rest } = props;

  return <DialogActions {...rest}>{children}</DialogActions>;
}

export default MUIDialogActions;
