import React from 'react';
import { CircularProgress } from '@mui/material';

function MUICircularProgress(props) {
  /**
   * props
   */
  const { sx, size } = props;

  return <CircularProgress sx={sx} size={size} />;
}

export default MUICircularProgress;
