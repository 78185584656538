import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Checkbox } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getLoadingState, loginUser } from '../../../Redux/Reducer/authReducer';
import MUITextField from '../../../components/Common/MUITextField';
import InputPassword from '../../../components/Common/InputPassword';
import MUIButton from '../../../components/Common/MUIButton';
import MUICircularProgress from '../../../components/Common/MUICircularProgress';
import MUIFormControlLabel from '../../../components/Common/MUIFormControlLabel';
import MUIStack from '../../../components/Common/MUIStack';
import { isValidEmail, isValidMobileNumber, isValidUserID } from '../../../utils/Helper';
import withLoader from '../../../components/HOC/withLoader';

function LoginForm(props) {
  /**
   * props
   */
  const { setLoading } = props;
  /**
   * hooks
   */
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /**
   * states
   */
  const [rememberMe, setRememberMe] = useState(true);

  /**
   * form fields
   */
  const [inputValues, setInputValues] = useState({
    email_or_mobile: '',
    password: '',
  });

  /**
   * Form Error
   */
  const [errors, setErrors] = useState({
    email_or_mobile: '',
    password: '',
  });

  /**
   * get loading state
   */
  const isLoading = useSelector(getLoadingState);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  /**
   * fetch email_or_mobile/password page load
   */
  useEffect(() => {
    const newInputValues = {
      email_or_mobile: localStorage.getItem('unique_id') || '',
      password: localStorage.getItem('password') || '',
    };
    setInputValues(newInputValues);
  }, []);

  /**
   * validation function
   * @param {*} name
   * @param {*} value
   * @returns
   */
  const validateForm = (name, value) => {
    switch (name) {
      case 'email_or_mobile':
        // if (!value || value.trim() === '') {
        //   return 'Email or mobile number is required';
        // }
        // if (!isValidEmail(value) && !isValidMobileNumber(value)) {
        //   return 'Enter valid email or mobile number';
        // }
        // case 'user_id':
        if (!isValidUserID(value) && !isValidMobileNumber(value)) {
          return 'Enter valid User ID with 6 digit e.g 847245';
        }

        return '';
      case 'password':
        if (!value || value.trim() === '') {
          return 'Password is required';
        }
        return '';
      default: {
        return '';
      }
    }
  };

  /**
   * Onchange event
   * @param {*} event
   */
  const handleOnChange = (event) => {
    const { name } = event.target;
    const value = event.target.value;

    setErrors({ ...errors, [name]: validateForm(name, value) });
    setInputValues({ ...inputValues, [name]: value });
  };

  /**
   * handleSubmit function
   * @param {*} event
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = {};
    Object.keys(inputValues).forEach((name) => {
      const error = validateForm(name, inputValues[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      setErrors({ ...errors, ...validationErrors });
      return;
    }

    /**
     * passed inputvalues in loginUser action
     */
    dispatch(loginUser(inputValues)).then((res) => {
      const role = res?.payload?.data?.data?.roles?.map((item) => item?.role);
      if (res?.payload?.status === 200) {
        if (role?.includes('Admin')) {
          navigate('/admin-dashboard');
        } else {
          navigate('/dashboard');
        }
      }
    });

    /**
     * remember me functionality
     */
    if (inputValues.email_or_mobile && inputValues.password) {
      if (rememberMe) {
        localStorage.setItem('rememberMe', 'true');
        localStorage.setItem('unique_id', inputValues.email_or_mobile);
        localStorage.setItem('password', inputValues.password);
      } else {
        localStorage.removeItem('unique_id');
        localStorage.removeItem('password');
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} autoComplete="off">
        <MUIStack spacing={1}>
          {/* <MUITextField
            name="email_or_mobile"
            label="Email or mobile number"
            placeholder="Email or mobile number"
            value={inputValues.email_or_mobile}
            onChange={handleOnChange}
            error={!!errors.email_or_mobile}
            errors={errors.email_or_mobile && errors.email_or_mobile}
          /> */}
          <MUITextField
            name="email_or_mobile"
            label="User ID"
            placeholder="User ID of 6 digit e.g. 847245"
            value={inputValues.email_or_mobile}
            onChange={handleOnChange}
            error={!!errors.email_or_mobile}
            errors={errors.email_or_mobile && errors.email_or_mobile}
          />
          <InputPassword
            name="password"
            label="Password"
            placeholder="Password"
            value={inputValues.password}
            onChange={handleOnChange}
            error={!!errors.password}
            errors={errors.password && errors.password}
          />
        </MUIStack>
        <MUIStack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <MUIFormControlLabel
            label="Remember Me"
            control={<Checkbox checked={rememberMe} onChange={(e) => setRememberMe(!rememberMe)} />}
          />
          {/* <Link
            onClick={() => {
              navigate('/forgotpassword');
            }}
            sx={{ cursor: 'pointer' }}
            variant="subtitle2"
            underline="hover"
          >
            Forgot Password?
          </Link> */}
        </MUIStack>
        <MUIButton
          fullWidth
          title={isLoading ? 'Loading...' : 'Log In'}
          size="large"
          type="submit"
          variant="contained"
          startIcon={isLoading && <MUICircularProgress sx={{ color: 'white' }} size={20} />}
        />
      </form>
    </>
  );
}

export default withLoader(LoginForm);
