import toast from 'react-hot-toast';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getEarningReportURL,
  getWithdrawHistoryReportURL,
  getWithdrawRequestReportURL,
} from '../../Services/ApiConfig';
import axiosInstance from '../../Services/API';
import { getDownloadCSVFile } from '../../utils/Helper';

/**
 * Define the initialstate
 */
const initialState = {
  earningReport: [],
  withdrawRequestReport: [],
  withdrawHistoryReport: [],
  isLoading: false,
  error: {
    message: '',
    code: '',
  },
};

/**
 * view earning report
 */
export const fetchEarningReport = createAsyncThunk('/reports/earning-report', async (parameters) => {
  const uniqueId = parameters.userId;
  const packageOwnerId = parameters.refId;
  const startDate = parameters.startDate;
  const endDate = parameters.endDate;

  try {
    const response = await axiosInstance.get(getEarningReportURL(uniqueId, packageOwnerId, startDate, endDate));
    if (response?.status === 200) {
      toast.success(response?.data?.message);
      getDownloadCSVFile(response?.data?.data);
    }
    return response;
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 404) {
      toast.error(error?.response?.data?.error?.message);
    }
    throw error;
  }
});

/**
 * view withdraw request report
 */
export const fetchWithdrawRequestReport = createAsyncThunk('/reports/withdrawal-request-report', async (parameters) => {
  const uniqueId = parameters.searchValue;
  try {
    const response = await axiosInstance.get(getWithdrawRequestReportURL(uniqueId));
    if (response?.status === 200) {
      toast.success(response?.data?.message);
      getDownloadCSVFile(response?.data?.data);
    }
    return response;
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 404) {
      toast.error(error?.response?.data?.error?.message);
    }
    throw error;
  }
});

/**
 * view withdraw history report
 */
export const fetchWithdrawHistoryReport = createAsyncThunk('/reports/withdrawal-history-report', async (parameters) => {
  const uniqueId = parameters.userId;
  const startDate = parameters.startDate;
  const endDate = parameters.endDate;
  try {
    const response = await axiosInstance.get(getWithdrawHistoryReportURL(uniqueId, startDate, endDate));
    if (response?.status === 200) {
      toast.success(response?.data?.message);
      getDownloadCSVFile(response?.data?.data);
    }
    return response;
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 404) {
      toast.error(error?.response?.data?.error?.message);
    }
    throw error;
  }
});

/**
 * Define the slice using createSlice from @reduxjs/toolkit
 */
const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEarningReport.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchEarningReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.earningReport = action.payload;
      })

      .addCase(fetchEarningReport.rejected, (state) => {
        state.isLoading = false;
        state.earningReport = [];
      })

      .addCase(fetchWithdrawRequestReport.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchWithdrawRequestReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.withdrawRequestReport = action.payload;
      })

      .addCase(fetchWithdrawRequestReport.rejected, (state) => {
        state.isLoading = false;
        state.withdrawRequestReport = [];
      })

      .addCase(fetchWithdrawHistoryReport.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchWithdrawHistoryReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.withdrawHistoryReport = action.payload;
      })

      .addCase(fetchWithdrawHistoryReport.rejected, (state) => {
        state.isLoading = false;
        state.withdrawHistoryReport = [];
      });
  },
});

export const getEarningReport = (state) => state.report.earningReport;
export const getWithdrawRequestReport = (state) => state.report.withdrawRequestReport;
export const getWithdrawHistoryReport = (state) => state.report.withdrawHistoryReport;
export const getLoadingState = (state) => state.report.isLoading;

/**
 * Export the reducer
 */
export default reportSlice.reducer;
