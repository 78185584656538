import { Route, Routes } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import LoginPage from '../pages/LoginPage';
import SetNewPasswordPage from '../pages/SetNewPasswordPage';
import UserList from '../pages/User/UserList';
import SignUpSuccess from '../pages/SignUpSuccess';
import AuthRoute from './AuthRoute';
import ProtectedRoute from './ProtectedRoute';
import Page404 from '../pages/Page404';
import ProfilePage from '../pages/Profile/ProfilePage';
import Earning from '../pages/Earning/Earning';
import WithdrawRequests from '../pages/WithdrawRequests/WithdrawRequests';
import WithdrawHistory from '../pages/WithdrawHistory/WithdrawHistory';
import EPins from '../pages/E-Pins/EPins';
import Genealogy from '../pages/Levels/Genealogy/Genealogy';
import MyGenealogy from '../pages/Levels/MyGenealogy/MyGenealogy';
import Dashboard from '../pages/Dashboard/Dashboard';
import MyDirect from '../pages/MyDirect/MyDirect';
import DashboardAppPage from '../pages/DashboardAppPage';
import MyGenealogyView from '../pages/Levels/GenealogyView/MyGenealogyView';

export default function RouteManager() {
  return (
    <Routes>
      {/* Auth Route */}
      <Route element={<AuthRoute />}>
        <Route path="/" element={<LoginPage />} />
      </Route>
      {/* <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
      <Route path="/setnewpassword" element={<SetNewPasswordPage />} /> */}
      <Route path="/success" element={<SignUpSuccess />} />

      {/* Protected Route */}
      <Route element={<ProtectedRoute />}>
        <Route element={<DashboardLayout />}>
          <Route index path="/dashboard" element={<Dashboard />} />
          <Route index path="/admin-dashboard" element={<DashboardAppPage />} />
          <Route path="user-list/:sponsorId?/:userName?" element={<UserList />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="genealogy" element={<Genealogy />} />
          <Route path="my-genealogy" element={<MyGenealogy />} />
          <Route path="earnings" element={<Earning />} />
          <Route path="withdraw-requests" element={<WithdrawRequests />} />
          <Route path="withdraw-history" element={<WithdrawHistory />} />
          <Route path="e-pins" element={<EPins />} />
          <Route path="my-directs" element={<MyDirect />} />
          <Route path="my-genealogy-view" element={<MyGenealogyView />} />
        </Route>
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
