import React from 'react';
import { InputAdornment } from '@mui/material';

function MUIInputAdornment(props) {
  /**
   * props
   */
  const { children, ...rest } = props;

  return <InputAdornment {...rest}>{children}</InputAdornment>;
}

export default MUIInputAdornment;
