import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MUITextField from './MUITextField';

function MUIDatePicker(props) {
  const { value, label, onChange, fullWidth } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ mx: 2 }}>
      <DatePicker
        label={label}
        value={value}
        format="dd-MM-yyyy"
        onChange={(newValue) => {
          onChange(newValue);
        }}
        slotProps={{ textField: { size: 'small', fullWidth } }}
        renderInput={(params) => <MUITextField size="small" autoComplete="off" {...params} />}
      />
    </LocalizationProvider>
  );
}

export default MUIDatePicker;
