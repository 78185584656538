import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet-async';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import MUIContainer from '../../components/Common/MUIContainer';
import TableHeader from '../../components/Common/TableHeader';
import withLoader from '../../components/HOC/withLoader';
import MUIDataGrid from '../../components/Common/MUIDataGrid';
import { fetchEarningList, getEarningList, getLoadingState } from '../../Redux/Reducer/financehubReducer';
import EarningSearchHeader from './EarningSearchHeader';
import { fetchEarningReport } from '../../Redux/Reducer/reportReducer';
import { getRoleInLocaleStorage } from '../../utils/Helper';

function Earning(props) {
  /**
   * props
   */
  const { setLoading } = props;

  /**
   * states
   */
  const [filters, setFilters] = useState({
    userId: '',
    refId: '',
    startDate: '',
    endDate: '',
  });

  // Format date to YYYY-MM-DD
  const formatDate = (date) => {
    if (date instanceof Date && !Number.isNaN(date.getTime())) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    }

    return '';
  };

  const startDate = filters.startDate ? new Date(filters.startDate) : null;
  const endDate = filters.endDate ? new Date(filters.endDate) : null;

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  /**
   * hooks
   */
  const dispatch = useDispatch();

  const rolesArray = getRoleInLocaleStorage();
  const role = JSON.parse(rolesArray);

  /**
   * get earning list
   */
  const earningList = useSelector(getEarningList);
  const rowCount = earningList?.data?.count;

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  const paginationPageSize = paginationModel.pageSize;
  const paginationPage = paginationModel.page;

  const offset = paginationPage * paginationPageSize;
  const limit = paginationPageSize;

  useEffect(() => {
    dispatch(
      fetchEarningList({
        limit,
        offset,
        search: filters?.userId,
        refId: filters?.refId,
        startDate: filters?.startDate && filters?.endDate && formattedStartDate,
        endDate: filters?.startDate && filters?.endDate && formattedEndDate,
      })
    );
  }, [dispatch, limit, offset, filters?.userId, filters?.refId, formattedStartDate, formattedEndDate]);

  /**
   * get loading state
   */
  const isLoading = useSelector(getLoadingState);

  useEffect(() => {
    setLoading(isLoading);
  }, [setLoading, isLoading]);

  /**
   * clear filter
   */
  const handleClearInputValue = () => {
    setFilters({
      userId: '',
      refId: '',
      startDate: '',
      endDate: '',
    });
  };

  /**
   * handle copy function
   * @param {*} row
   */
  const handleCopy = (row) => {
    navigator.clipboard.writeText(row?.unique_id);
    toast.success('User ID copied successfully!');
  };

  const handleCopyRefId = (row) => {
    navigator.clipboard.writeText(row?.package_owner_id);
    toast.success('Reference Id copied successfully!');
  };

  /**
   * filter onchange
   * @param {*} field
   * @param {*} value
   */
  const handleInputChange = (field, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleExport = () => {
    const userId = filters.userId;
    const refId = filters.refId;
    const startDate = formattedStartDate;
    const endDate = formattedEndDate;
    dispatch(fetchEarningReport({ userId, refId, startDate, endDate }));
  };

  /**
   * columns
   */
  const columns = [
    {
      flex: 0.2,
      minWidth: 100,
      field: 'unique_id',
      headerName: 'User Id',
      sortable: false,
      renderCell: ({ row }) => (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title={row?.unique_id}>
            <span>{row?.unique_id}</span>
          </Tooltip>
          <Tooltip title="Copy">
            <IconButton size="small" onClick={() => handleCopy(row)} style={{ marginLeft: 5 }}>
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </span>
      ),
    },
    {
      flex: 0.2,
      minWidth: 80,
      field: 'amount',
      headerName: 'Amount',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.amount}>
          <span>{row?.amount}</span>
        </Tooltip>
      ),
    },
    {
      flex: 0.2,
      minWidth: 140,
      field: 'cashback_type',
      headerName: 'Type',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.cashback_type}>
          <span style={{ textTransform: 'capitalize' }}>{row?.cashback_type}</span>
        </Tooltip>
      ),
    },
    {
      flex: 0.2,
      minWidth: 100,
      field: 'package_owner_id',
      headerName: 'REF Id',
      sortable: false,
      renderCell: ({ row }) => (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title={row?.package_owner_id}>
            <span>{row?.package_owner_id}</span>
          </Tooltip>
          <Tooltip title="Copy">
            <IconButton size="small" onClick={() => handleCopyRefId(row)} style={{ marginLeft: 5 }}>
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </span>
      ),
    },
    {
      flex: 0.2,
      minWidth: 100,
      field: 'date_of_earning',
      headerName: 'Date',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.date_of_earning}>
          <span>{row?.date_of_earning}</span>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>ApexTrade | Earnings </title>
      </Helmet>
      <MUIContainer>
        <TableHeader
          muiTitle
          muiClearBtn
          title="Earnings"
          textFieldLabel="Search user id"
          textFieldPlaceholder="Search user id"
          textFieldSize="small"
          buttonVariant="contained"
          clearBtnTitle="Clear filter"
          clearBtnVariant="contained"
          clearOnClick={handleClearInputValue}
          clearBtnStartIcon={<ClearIcon />}
          showExportButton={role?.includes('Admin')}
          exportBtnTitle="Export"
          exportBtnVariant="outlined"
          handleExport={handleExport}
        />
        <EarningSearchHeader
          textFieldLabel="Search user id"
          textFieldPlaceholder="Search user id"
          textFieldSize="small"
          userIdValue={filters?.userId}
          refIdValue={filters?.refId}
          startDateValue={filters?.startDate}
          endDateValue={filters?.endDate}
          onChange={(e) => handleInputChange('userId', e.target.value)}
          onRefIdChange={(e) => handleInputChange('refId', e.target.value)}
          onStartDateChange={(date) => handleInputChange('startDate', date)}
          onEndDateChange={(date) => handleInputChange('endDate', date)}
        />
        <MUIDataGrid
          rows={earningList?.data?.results || []}
          columns={columns}
          rowCount={rowCount}
          getRowId={(row) => row.id}
          onRowSelectionModelChange={undefined}
          selectionModel={undefined}
          columnHeaderHeight={60}
          page={paginationModel.page}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
      </MUIContainer>
    </>
  );
}

export default withLoader(Earning);
