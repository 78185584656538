import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';
import { IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import MUIContainer from '../../components/Common/MUIContainer';
import TableHeader from '../../components/Common/TableHeader';
import withLoader from '../../components/HOC/withLoader';
import MUIDataGrid from '../../components/Common/MUIDataGrid';
import WithdrawFund from './WithdrawFund';
import { getRoleInLocaleStorage } from '../../utils/Helper';
import {
  fetchWithdrawDetailsList,
  fetchWithdrawDetailsPaidRejuctList,
  getLoadingState,
  getWithdrawDetailsList,
  getWithdrawDetailsPaidRejuctList,
} from '../../Redux/Reducer/financehubReducer';
import WithdrawHistorySearchingHeader from './WithdrawHistorySearchingHeader';
import { fetchWithdrawHistoryReport } from '../../Redux/Reducer/reportReducer';

function WithdrawHistory(props) {
  /**
   * props object
   */
  const { setLoading } = props;

  /**
   * states
   */
  const [searchValue, setSearchValue] = useState('');

  const rolesArray = getRoleInLocaleStorage();
  const role = JSON.parse(rolesArray);

  const [filters, setFilters] = useState({
    userId: '',
    startDate: '',
    endDate: '',
  });

  const search = filters?.userId?.length > 2 ? filters?.userId : '';

  // Format date to YYYY-MM-DD
  const formatDate = (date) => {
    if (date instanceof Date && !Number.isNaN(date.getTime())) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    }

    return '';
  };

  const startDate = filters.startDate ? new Date(filters.startDate) : null;
  const endDate = filters.endDate ? new Date(filters.endDate) : null;

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  /**
   * hooks
   */
  const dispatch = useDispatch();

  /**
   * get withdraw history
   */
  const withdrawDetailsList = useSelector(
    role?.includes('Admin') ? getWithdrawDetailsPaidRejuctList : getWithdrawDetailsList
  );
  const rowCount = withdrawDetailsList?.data?.count;

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  const paginationPageSize = paginationModel.pageSize;
  const paginationPage = paginationModel.page;

  const offset = paginationPage * paginationPageSize;
  const limit = paginationPageSize;

  useEffect(() => {
    if (role?.includes('Admin')) {
      dispatch(fetchWithdrawDetailsPaidRejuctList({ limit, offset, search, formattedStartDate, formattedEndDate }));
    } else {
      dispatch(fetchWithdrawDetailsList({ limit, offset, search }));
    }
  }, [dispatch, limit, offset, search, formattedStartDate, formattedEndDate]);

  /**
   * get loading state
   */
  const isLoading = useSelector(getLoadingState);

  useEffect(() => {
    setLoading(isLoading);
  }, [setLoading, isLoading]);

  /**
   * get search value
   */
  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  /**
   * clear filter
   */
  const handleClearInputValue = () => {
    setFilters({
      userId: '',
      startDate: '',
      endDate: '',
    });
  };

  /**
   * filter onchange
   * @param {*} field
   * @param {*} value
   */
  const handleInputChange = (field, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  /**
   * handle copy function
   * @param {*} row
   */
  const handleCopy = (row) => {
    navigator.clipboard.writeText(row?.user_unique_id);
    toast.success('User ID copied successfully!');
  };

  const handleCopyAddress = (row) => {
    navigator.clipboard.writeText(row?.withdraw_wallet_address);
    toast.success('Wallet address copied successfully!');
  };

  const handleExport = () => {
    const userId = filters.userId;
    const startDate = formattedStartDate;
    const endDate = formattedEndDate;
    dispatch(fetchWithdrawHistoryReport({ userId, startDate, endDate }));
  };

  /**
   * columns
   */
  const columns = [
    {
      flex: 1,
      minWidth: 100,
      field: 'user_unique_id',
      headerName: 'User Id',
      sortable: false,
      renderCell: ({ row }) => (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title={row?.user_unique_id}>
            <span>{row?.user_unique_id}</span>
          </Tooltip>
          <Tooltip title="Copy">
            <IconButton size="small" onClick={() => handleCopy(row)} style={{ marginLeft: 5 }}>
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </span>
      ),
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'user_first_name',
      headerName: 'First name',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.user_first_name}>
          <span style={{ textTransform: 'capitalize' }}>{row?.user_first_name}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'user_last_name',
      headerName: 'Last name',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.user_last_name}>
          <span style={{ textTransform: 'capitalize' }}>{row?.user_last_name}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'amount',
      headerName: 'Amount',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.amount}>
          <span>{row?.amount}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'admin_amount',
      headerName: 'Admin',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.admin_amount}>
          <span>{row?.admin_amount}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'user_amount',
      headerName: 'User amount',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.user_amount}>
          <span>{row?.user_amount}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'withdraw_wallet_address',
      headerName: 'Wallet address',
      sortable: false,
      renderCell: ({ row }) => (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title={row?.withdraw_wallet_address}>
            <span>{row?.withdraw_wallet_address}</span>
          </Tooltip>
          <Tooltip title="Copy">
            <IconButton size="small" onClick={() => handleCopyAddress(row)} style={{ marginLeft: 5 }}>
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </span>
      ),
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'request_status',
      headerName: 'Status',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.request_status}>
          <span style={{ textTransform: 'capitalize' }}>{row?.request_status}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'requested_date',
      headerName: 'Date',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.requested_date}>
          <span>{row?.requested_date}</span>
        </Tooltip>
      ),
    },
  ];

  /**
   * filtered admin_amount and user_amount column when role is guest
   */
  const filteredColumns = role?.includes('Guest')
    ? columns.filter((column) => column.field !== 'admin_amount' && column.field !== 'user_amount')
    : columns;

  return (
    <>
      <Helmet>
        <title>ApexTrade | Withdraw History </title>
      </Helmet>
      <MUIContainer>
        <WithdrawFund limit={limit} offset={offset} />
        <TableHeader
          muiTitle
          muiClearBtn
          title="Withdraw History"
          textFieldLabel="Search"
          textFieldPlaceholder="Search"
          textFieldSize="small"
          buttonVariant="contained"
          onChange={handleChange}
          clearBtnTitle="Clear filter"
          clearBtnVariant="contained"
          clearOnClick={handleClearInputValue}
          clearBtnStartIcon={<ClearIcon />}
          searchValue={searchValue}
          showExportButton={role?.includes('Admin')}
          exportBtnTitle="Export"
          exportBtnVariant="outlined"
          handleExport={handleExport}
        />
        <WithdrawHistorySearchingHeader
          textFieldLabel="Search user id"
          textFieldPlaceholder="Search user id"
          textFieldSize="small"
          userIdValue={filters?.userId}
          startDateValue={filters?.startDate}
          endDateValue={filters?.endDate}
          onChange={(e) => handleInputChange('userId', e.target.value)}
          onRefIdChange={(e) => handleInputChange('refId', e.target.value)}
          onStartDateChange={(date) => handleInputChange('startDate', date)}
          onEndDateChange={(date) => handleInputChange('endDate', date)}
        />
        <MUIDataGrid
          rows={withdrawDetailsList?.data?.results || []}
          columns={filteredColumns}
          rowCount={rowCount}
          getRowId={(row) => row.id}
          onRowSelectionModelChange={undefined}
          selectionModel={undefined}
          columnHeaderHeight={60}
          page={paginationModel.page}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
      </MUIContainer>
    </>
  );
}

export default withLoader(WithdrawHistory);
