const BaseURL = process.env.REACT_APP_FOREX_BASE_URL;

/**
 * Auth Section
 * @returns
 */
export const getSignInURL = () => `${BaseURL}/accounts/token-access/`;
export const getForgotPasswordURL = () => `${BaseURL}/accounts/generate-otp/`;
export const getSetNewPasswordURL = () => `${BaseURL}/accounts/forgot-user-password/`;
export const getProfileDetailsURL = () => `${BaseURL}/accounts/profile/`;
export const getRefreshTokenURL = () => `${BaseURL}/accounts/token-refresh/`;
export const getUpdateProfileURL = () => `${BaseURL}/accounts/update-profile/`;
export const getChangePasswordURL = () => `${BaseURL}/accounts/change-password/`;
export const getUserDashboardURL = () => `${BaseURL}/accounts/user-dashboard/`;
export const getAdminDashboardURL = () => `${BaseURL}/accounts/admin-dashboard/`;

/**
 * User Section
 */
export const getUserListURL = (limit, offset, firstName, middleName, lastName, email, parentSponsorid, uniqueId) =>
  `${BaseURL}/levels/view-all-users/?limit=${limit}&offset=${offset}&first_name=${firstName}&middle_name=${middleName}&last_name=${lastName}&email=${email}&sponsor_id=${parentSponsorid}&unique_id=${uniqueId}`;
export const getAddUserURL = () => `${BaseURL}/accounts/create-user/`;
export const getUpdateUserURL = (id) => `${BaseURL}/accounts/update-user/${id}/`;
export const getDeleteUserURL = (id) => `${BaseURL}/accounts/delete-user/${id}/`;
export const getUserChangePasswordURL = (id) => `${BaseURL}/accounts/user-change-password/${id}/`;

/**
 * logs sections url`s
 */
export const getOtpLogsURL = (limit, offset, search) =>
  `${BaseURL}/logs/view-otp-logs/?limit=${limit}&offset=${offset}&email=${search}`;
export const getViewPermissionLogsURL = (limit, offset, search) =>
  `${BaseURL}/logs/view-permissions-logs/?limit=${limit}&offset=${offset}&search=${search}`;

/**
 * levels sections url`s
 */
export const getLoggedUserHierarchyURL = () => `${BaseURL}/levels/view-logged-user-hierarchy/`;
export const getViewAllHierarchyURL = (search) => `${BaseURL}/levels/view-all-hierarchy/?user_email=${search}`;
export const getViewSingleUserHierarchyURL = (id) => `${BaseURL}/levels/get-user-hierarchy/${id}/`;
export const getCreateUserHierarchyURL = () => `${BaseURL}/levels/create-user-hierarchy/`;
export const getCreateLoggedUserHierarchyURL = () => `${BaseURL}/levels/create-logged-user-hierarchy/`;
export const getParentsListURL = (search) => `${BaseURL}/levels/view-parents-list/?email=${search}`;
export const getLoggedUserChildrenListURL = () => `${BaseURL}/levels/view-logged-user-children/`;
export const getGeneologyListURL = () => `${BaseURL}/levels/view-geneology/`;
export const getGeneologyViewListURL = () => `${BaseURL}/levels/get-logged-user-geneology/`;
export const getOneGeneologyURL = (id) => `${BaseURL}/levels/get-geneology/${id}/`;
export const getLoggedUserGeneologyURL = () => `${BaseURL}/levels/view-logged-user-geneology/`;
export const getViewFirstLevelUserURL = () => `${BaseURL}/levels/view-first-level-users/`;

/**
 * e-pin sections url`s
 */
export const getViewEpinURL = (limit, offset, search) =>
  `${BaseURL}/financehub/view-epins/?limit=${limit}&offset=${offset}&unique_id=${search}`;
export const getCreateEpinURL = () => `${BaseURL}/financehub/create-epin/`;
export const getDeleteEpinURL = (id) => `${BaseURL}/financehub/delete-epin/${id}/`;
export const getUpdateUsedPackageURL = (id) => `${BaseURL}/financehub/update-used-package/${id}/`;

/**
 * view earnings
 */
export const getViewEarningURL = (limit, offset, search, refId, startDate, endDate) => {
  const params = new URLSearchParams();

  // Add parameters conditionally
  if (limit) params.append('limit', limit);
  if (offset) params.append('offset', offset);
  if (search) params.append('unique_id', search);
  if (refId) params.append('package_owner_id', refId);
  if (startDate) params.append('start_date', startDate);
  if (endDate) params.append('end_date', endDate);

  // Build the URL with the query parameters
  return `${BaseURL}/financehub/view-earning/?${params.toString()}`;
};

/**
 * view wallet transaction
 */
export const getViewWalletTransactionURL = (limit, offset) =>
  `${BaseURL}/financehub/view-wallet-transactions/?limit=${limit}&offset=${offset}`;

/**
 * view wallet balance
 */
export const getViewWalletBalanceURL = (limit, offset) =>
  `${BaseURL}/financehub/view-wallet-balance/?limit=${limit}&offset=${offset}`;

/**
 * view withdraw details
 */
export const getViewWithdrawDetailsURL = (limit, offset, search) =>
  `${BaseURL}/financehub/view-withdraw-details/?limit=${limit}&offset=${offset}&unique_id=${search}`;

/**
 * view withdraw details
 */
export const getCreateWithdrawRequestURL = () => `${BaseURL}/financehub/create-withdraw-request/`;

/**
 * view balance
 */
export const getViewBalanceURL = () => `${BaseURL}/financehub/view-balance/`;

/**
 * update withdraw request status
 */
export const getUpdateWithdrawRequestStatusURL = (id) => `${BaseURL}/financehub/update-withdraw-request-status/${id}/`;

/**
 * rejuct withdraw request status
 */
export const getRejuctWithdrawRequestStatusURL = (id) => `${BaseURL}/financehub/reject-withdraw-request/${id}/`;

/**
 * view withdraw details unpaid
 */
export const getViewWithdrawDetailsUnpaidURL = (limit, offset, search) =>
  `${BaseURL}/financehub/view-withdraw-details-unpaid/?limit=${limit}&offset=${offset}&unique_id=${search}`;

/**
 * view withdraw details paid rejuct
 */
// export const getViewWithdrawDetailsPaidRejuctURL = (limit, offset, search) =>
//   `${BaseURL}/financehub/view-withdraw-details-paid-reject/?limit=${limit}&offset=${offset}&unique_id=${search}`;

/**
 * view withdraw details paid rejuct
 */
export const getViewWithdrawDetailsPaidRejuctURL = (limit, offset, search, formattedStartDate, formattedEndDate) => {
  const params = new URLSearchParams();

  // Add parameters conditionally
  if (limit) params.append('limit', limit);
  if (offset) params.append('offset', offset);
  if (search) params.append('unique_id', search);
  if (formattedStartDate) params.append('start_requested_date', formattedStartDate);
  if (formattedEndDate) params.append('end_requested_date', formattedEndDate);

  // Build the URL with the query parameters
  return `${BaseURL}/financehub/view-withdraw-details-paid-reject/?${params.toString()}`;
};

/**
 * view all package
 */
export const getViewAllPackageURL = (limit, offset) =>
  `${BaseURL}/financehub/view-all-package/?limit=${limit}&offset=${offset}`;

/**
 * view all package
 */
export const getViewAllUserDashboardURL = () => `${BaseURL}/financehub/view-all-package-user-dashboard/`;

/**
 * reports api url`s
 */
export const getEarningReportURL = (uniqueId, packageOwnerId, startDate, endDate) => {
  const params = new URLSearchParams();

  // Add parameters conditionally
  if (uniqueId) params.append('unique_id', uniqueId);
  if (packageOwnerId) params.append('package_owner_id', packageOwnerId);
  if (startDate) params.append('start_date', startDate);
  if (endDate) params.append('end_date', endDate);

  // Build the URL with the query parameters
  return `${BaseURL}/reports/earning-report/?${params.toString()}`;
};

export const getWithdrawRequestReportURL = (uniqueId) => `${BaseURL}/reports/withdrawal-request-report/?unique_id=${uniqueId}`;

export const getWithdrawHistoryReportURL = (uniqueId, startDate, endDate) => {
  const params = new URLSearchParams();

  // Add parameters conditionally
  if (uniqueId) params.append('unique_id', uniqueId);
  if (startDate) params.append('start_date', startDate);
  if (endDate) params.append('end_date', endDate);

  // Build the URL with the query parameters
  return `${BaseURL}/reports/withdrawal-history-report/?${params.toString()}`;
};

