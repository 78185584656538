/**
 * regex
 */
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const mobileRegex = /^[789]\d{9}$/;
export const uniqueIdRegex = /^\d{6}$/;
export const uppercaseTextRegex = /(?=.*[A-Z])/;
export const specialSymbolRegex = /(?=.*[!@#$%^&*(),.?":{}|<>])/;
export const numericValueRegex = /(?=.*[0-9])/;
export const alphabetsCharRegex = /^[A-Za-z]+$/;
export const acceptDigitRegex = /^\d+$/;
export const lowercaseTextRegex = /(?=.*[a-z])/;

/**
 * email validation regx
 */
export const isValidEmail = (email) => {
  const emailRegex =
    /^(([^<>()[\],;:\s@"]+(\.[^<>()[\],;:\s@"]+)*)|(".+"))@(([^<>()[\],;:\s@"]+\.)+[^<>()[\],;:\s@"]{2,})$/;
  return emailRegex.test(email);
};

/**
 * test number validation regx
 */
export const testNumber = (value) => {
  const numberRegex = /\d/;
  return numberRegex.test(value);
};

export const specialCharacters = /[!@#$%^&*(),.?":{}|<>]/g;
export const hasUppercase = /[A-Z]/;
export const hasDigit = /\d/;

/**
 * mobile number regx
 * @param {*} number
 * @returns
 */
export const isValidMobileNumber = (number) => {
  const mobileNumberRegex = /^[0-9]{10}$/;
  return mobileNumberRegex.test(number);
};

export const isValidUserID = (number) => {
  const userIdRegex = /^[0-9]{6}$/;
  return userIdRegex.test(number);
};

/**
 * number regx
 * @param {*} number
 * @returns
 */
export const isValidNumber = (number) => {
  const numberRegex = /^[+]?\d+(\.\d+)?$/;
  return numberRegex.test(number);
};

/**
 * Save access token in locale storage
 * @param {*} key
 * @param {*} token
 */
export const saveAccessToken = (key, token) => {
  localStorage.setItem(key, token);
};

/**
 * get access toekn in locale storage
 * @returns
 */
export const getAccessToken = () => {
  const token = localStorage.getItem('access');
  return token;
};

/**
 * save refresh token in locale storage
 * @param {*} key
 * @param {*} token
 */
export const saveRefreshToken = (key, token) => {
  localStorage.setItem(key, token);
};

/**
 * get refresh toekn in locale storage
 * @returns
 */
export const getRefreshToken = () => {
  const token = localStorage.getItem('refresh');
  return token;
};

/**
 * save role in locale storage
 */
export const saveRoleInLocaleStorage = (key, role) => {
  localStorage.setItem(key, role);
};

/**
 * get role in locale storage
 */
export const getRoleInLocaleStorage = () => {
  const role = localStorage.getItem('role');
  return role;
};

export const getDownloadCSVFile = (data) => {
  const link = document.createElement('a');
  link.href = data;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * user actions
 */
export const UserActions = {
  SHOW_DETAILS: 1,
  ADD: 2,
  EDIT: 3,
  DELETE: 4,
  ADDMEMBER: 5,
  CHANGE_PASSWORD: 6,
};
