import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoIcon from '@mui/icons-material/Info';
import Avatar from '@mui/material/Avatar';
import { IconButton, Tooltip } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import MUIBox from '../../components/Common/MUIBox';
import MUIGrid from '../../components/Common/MUIGrid';
import MUITypography from '../../components/Common/MUITypography';
import { fetchAllUserDashboard, getViewAllUserDashboard } from '../../Redux/Reducer/financehubReducer';

const StyledBox = styled(MUIBox)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  mt: 2,
  backgroundColor: '#d0f2ff',
}));

const Label = styled(MUITypography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 'bold',
}));

function MyDetails() {
  /**
   * hooks
   */
  const dispatch = useDispatch();

  /**
   * get all user details
   */
  const viewPackages = useSelector(getViewAllUserDashboard)?.data?.data;

  useEffect(() => {
    dispatch(fetchAllUserDashboard());
  }, [dispatch]);

  /**
   * handle copy function
   * @param {*} row
   */
  const handleCopy = (data) => {
    navigator.clipboard.writeText(data);
    toast.success('User ID copied successfully!');
  };

  const handleCopySponsorId = (data) => {
    navigator.clipboard.writeText(data);
    toast.success('Sponsor Id copied successfully!');
  };

  return (
    <StyledBox>
      <MUIGrid container spacing={3}>
        <MUIGrid item xs={12}>
          <MUIBox
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 2,
              mb: 3,
            }}
          >
            <Avatar sx={{ bgcolor: 'primary.main', width: 64, height: 64 }}>
              <PersonIcon sx={{ fontSize: 32, width: 50, height: 50 }} />
            </Avatar>
            <MUITypography variant="h4" sx={{ textTransform: 'capitalize' }}>
              {viewPackages?.user_info?.user_name || 'N/A'}
            </MUITypography>
          </MUIBox>
          <MUIBox
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
              p: 1,
            }}
          >
            <Label variant="subtitle1">User Id:</Label>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <MUITypography variant="body1">{viewPackages?.user_info?.unique_id}</MUITypography>{' '}
              <Tooltip title="Copy">
                <IconButton
                  size="small"
                  onClick={() => handleCopy(viewPackages?.user_info?.unique_id)}
                  style={{ marginLeft: 5 }}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </span>
          </MUIBox>
          <MUIBox
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
              p: 1,
            }}
          >
            <Label variant="subtitle1">Joining Date:</Label>
            <MUITypography variant="body1">{viewPackages?.user_info?.joining_date}</MUITypography>
          </MUIBox>
          <MUIBox
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
              p: 1,
            }}
          >
            <Label variant="subtitle1">Sponsor Id:</Label>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <MUITypography variant="body1">{viewPackages?.user_info?.parent_sponsor_id}</MUITypography>
              <Tooltip title="Copy">
                <IconButton
                  size="small"
                  onClick={() => handleCopySponsorId(viewPackages?.user_info?.parent_sponsor_id)}
                  style={{ marginLeft: 5 }}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </span>
          </MUIBox>
          <MUIBox
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
              p: 1,
            }}
          >
            <Label variant="subtitle1">Open Levels:</Label>
            <MUITypography variant="body1">{viewPackages?.open_level}</MUITypography>
          </MUIBox>
          {viewPackages?.packages?.map((item, index) => (
            <React.Fragment key={index}>
              <MUIBox
                sx={{
                  mb: 3,
                  backgroundColor: item?.earning_status ? '#4CAF50' : '#dc3545',
                  color: 'white',
                  borderRadius: '10px',
                  p: 1,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#009688',
                    transition: '0.5s',
                  },
                }}
              >
                <Tooltip
                  placement="bottom"
                  arrow
                  title={
                    <>
                      <MUITypography variant="subtitle2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                        <span>Package: {item?.package} </span>
                      </MUITypography>
                      <MUITypography variant="subtitle2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                        <span>Entry date: {item?.entry_date} </span>
                      </MUITypography>
                      <MUITypography variant="subtitle2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                        <span>Earing start date: {item?.earing_start_date} </span>
                      </MUITypography>
                      <MUITypography variant="subtitle2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                        <span>Earing end date: {item?.earning_end_date} </span>
                      </MUITypography>
                      <MUITypography variant="subtitle2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                        <span>Package status: {item?.earning_status ? 'Active' : 'InActive'}</span>
                      </MUITypography>
                    </>
                  }
                >
                  <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <MUITypography variant="subtitle1">Package:</MUITypography>
                    <InfoIcon fontSize="small" />
                    <MUITypography variant="body1">{item?.package}</MUITypography>
                  </span>
                </Tooltip>
              </MUIBox>
            </React.Fragment>
          ))}
          <MUIBox
            sx={{
              mb: 3,
              p: 1,
              backgroundColor: '#2065d1',
              color: 'white',
              borderRadius: '10px',
              cursor: 'pointer',
            }}
          >
            <Tooltip
              title={
                <>
                  <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                    Open Levels: {viewPackages?.open_level}
                  </MUITypography>
                  <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                    My earning Levels: {viewPackages?.myearning_levels}
                  </MUITypography>
                  <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                    Direct open Levels: {viewPackages?.direct_open_level}
                  </MUITypography>
                  <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                    Packages open Levels: {viewPackages?.packages_open_level}
                  </MUITypography>
                  <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                    Active pacakge amount: {viewPackages?.active_pacakge_amount}
                  </MUITypography>
                  <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                    Total business: {viewPackages?.total_business}
                  </MUITypography>
                </>
              }
              arrow
              placement="bottom"
            >
              <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <MUITypography variant="subtitle1">Total Business:</MUITypography>
                <InfoIcon fontSize="small" />
                <MUITypography variant="body1">{viewPackages?.total_business}</MUITypography>
              </span>
            </Tooltip>
          </MUIBox>
        </MUIGrid>
      </MUIGrid>
    </StyledBox>
  );
}

export default MyDetails;
