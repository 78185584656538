import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MUIBox from '../../components/Common/MUIBox';
import MUIDivider from '../../components/Common/MUIDivider';
import MUITypography from '../../components/Common/MUITypography';
import MUIGrid from '../../components/Common/MUIGrid';
import MUITextField from '../../components/Common/MUITextField';
import MUIButton from '../../components/Common/MUIButton';
import { CreateEpin, fetchEpinsList, getCreateLoadingState } from '../../Redux/Reducer/epinReducer';
import withLoader from '../../components/HOC/withLoader';
import MUISearchable from '../../components/Common/MUISearchable';
import { fetchUserList, getUserList } from '../../Redux/Reducer/userReducer';

function GenerateEpins(props) {
  /**
   * props
   */
  const { setLoading } = props;

  /**
   * hooks
   */
  const dispatch = useDispatch();

  /**
   * states
   */
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  /**
   * form fields
   */
  const [inputValues, setInputValues] = useState({
    amount: '',
    used_by: '',
  });

  /**
   * form errors
   */
  const [errors, setErrors] = useState({
    amount: '',
    used_by: '',
  });

  /**
   * get loading state
   */
  const isLoading = useSelector(getCreateLoadingState);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  /**
   * get user list and implement pagination
   */
  const userList = useSelector(getUserList);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  const rowCount = userList?.data?.count;

  const paginationPageSize = paginationModel.pageSize;
  const paginationPage = paginationModel.page;

  const offset = paginationPage * paginationPageSize;
  const limit = paginationPageSize;

  useEffect(() => {
    dispatch(
      fetchUserList({
        limit,
        offset,
        firstName: '',
        middleName: '',
        lastName: '',
        userEmail: '',
        parentSponsorid: '',
        uniqueId: searchValue,
      })
    );
  }, [dispatch, limit, offset, searchValue]);

  /**
   * handle clear input fields
   */
  const handleClear = () => {
    setInputValues({ amount: '', used_by: '' });
    setErrors({ amount: '', used_by: '' });
  };

  /**
   * validation functions
   * @param {*} name
   * @param {*} value
   * @returns
   */
  const validateForm = (name, value) => {
    switch (name) {
      case 'amount':
        if (!value) {
          return 'E-pin amount is required';
        }
        if (!/^\d+(\.\d+)?$/.test(value)) {
          return 'Amount must be a valid number';
        }
        return '';
      case 'used_by':
        if (!value) {
          return 'User id is required';
        }
        return '';
      default: {
        return '';
      }
    }
  };

  /**
   * Onchange event
   * @param {*} event
   */
  const handleOnChange = (event) => {
    const { name } = event.target;
    const value = event.target.value;

    setErrors({ ...errors, [name]: validateForm(name, value) });
    setInputValues({ ...inputValues, [name]: value });
  };

  /**
   * handle input user change event
   * @param {*} event
   * @param {*} newInputValue
   */
  const handleInputChange = (event, newInputValue) => {
    const match = newInputValue.match(/\(([^)]+)\)/);
    const value = match ? match[1] : null;
    setSearchValue(newInputValue);
    const selectedUser = userList?.data?.results.find((option) => String(option?.user_unique_id) === String(value));

    setSelectedUserId(selectedUser ? selectedUser?.user : null);
  };

  /**
   * handle dropdown change event
   * @param {*} value
   * @param {*} name
   */
  const handleDropdownChange = (value, name) => {
    setErrors({ ...errors, [name]: validateForm(name, value) });
    setInputValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [name]: value,
    }));
  };

  /**
   * form submit event
   * @param {*} event
   * @returns
   */
  const onSubmit = (event) => {
    event.preventDefault();
    const validationErrors = {};
    Object.keys(inputValues).forEach((name) => {
      const error = validateForm(name, inputValues[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      setErrors({ ...errors, ...validationErrors });
      return;
    }

    /**
     * parameters
     */
    const parameters = {
      amount: inputValues.amount,
      used_by: selectedUserId,
    };
    dispatch(CreateEpin(parameters)).then((res) => {
      if (res?.payload?.status === 200) {
        handleClear();
        dispatch(fetchEpinsList({ limit, offset, search: '' }));
      }
    });
  };

  return (
    <MUIBox sx={{ border: '1px solid rgb(224, 224, 224)', p: 2, borderRadius: '5px', mb: 3 }}>
      <MUITypography variant="h4" gutterBottom>
        Generate Epins
      </MUITypography>
      <MUIDivider />
      <form autoComplete="off">
        <MUIGrid container spacing={3} pt={3}>
          <MUIGrid item xs={12} md={12} lg={12}>
            <MUITypography variant="subtitle2">Enter E-pin Amount</MUITypography>
            <MUITextField
              fullWidth
              name="amount"
              placeholder="Enter Amount"
              value={inputValues.amount}
              onChange={handleOnChange}
              error={!!errors.amount}
              errors={errors.amount && errors.amount}
            />
          </MUIGrid>
          <MUIGrid item xs={12} md={12} lg={12}>
            <MUITypography variant="subtitle2">Enter userid whome to issue</MUITypography>
            <MUISearchable
              sx={{ paddingTop: '0px' }}
              placeholder="Select user"
              size="large"
              onChange={(event, newValue) => {
                handleDropdownChange(newValue, 'used_by');
              }}
              onInputChange={handleInputChange}
              options={
                userList?.data?.results?.map(
                  (option) =>
                    `${option?.user_first_name ? option?.user_first_name : ''} ${
                      option?.user_middle_name ? option?.user_middle_name : ''
                    } ${option?.user_last_name ? option?.user_last_name : ''} ${
                      option?.user_unique_id ? `(${option?.user_unique_id})` : ''
                    } `
                ) || []
              }
              value={inputValues.used_by}
              error={!!errors.used_by}
              errorMessage={errors.used_by}
            />
          </MUIGrid>
          <MUIGrid item xs={12} md={12} lg={12}>
            <MUIButton title={'Submit'} variant="contained" onClick={onSubmit} />
          </MUIGrid>
        </MUIGrid>
      </form>
    </MUIBox>
  );
}

export default withLoader(GenerateEpins);
