import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword, getForgotpasswordLoadingState } from '../../../Redux/Reducer/authReducer';
import MUITextField from '../../../components/Common/MUITextField';
import MUIButton from '../../../components/Common/MUIButton';
import MUIBox from '../../../components/Common/MUIBox';
import MUICircularProgress from '../../../components/Common/MUICircularProgress';
import MUITypography from '../../../components/Common/MUITypography';
import MUIStack from '../../../components/Common/MUIStack';
import { isValidEmail } from '../../../utils/Helper';
import withLoader from '../../../components/HOC/withLoader';

function ForgotPasswordForm(props) {
  /**
   * props
   */
  const { setLoading } = props;

  /**
   * hooks
   */
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /**
   * form values
   */
  const [inputValues, setInputValues] = useState({
    email: '',
  });

  /**
   * form errors
   */
  const [errors, setErrors] = useState({
    email: '',
  });

  /**
   * loading state
   */
  const isLoading = useSelector(getForgotpasswordLoadingState);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  /**
   * validation function
   * @param {*} name
   * @param {*} value
   * @returns
   */
  const validateForm = (name, value) => {
    switch (name) {
      case 'email':
        if (!value || value.trim() === '') {
          return 'Email is required';
        }
        if (!isValidEmail(value)) {
          return 'Enter valid email address';
        }
        return '';
      default: {
        return '';
      }
    }
  };

  /**
   * Onchange event
   * @param {*} event
   */
  const handleOnChange = (event) => {
    const { name } = event.target;
    const value = event.target.value;

    setErrors({ ...errors, [name]: validateForm(name, value) });
    setInputValues({ ...inputValues, [name]: value });
  };

  /**
   * handleSubmit function
   * @param {*} event
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = {};
    Object.keys(inputValues).forEach((name) => {
      const error = validateForm(name, inputValues[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      setErrors({ ...errors, ...validationErrors });
      return;
    }
    dispatch(forgotPassword(inputValues)).then((res) => {
      if (res?.payload?.status === 200) {
        navigate('/setnewpassword');
      }
    });
  };

  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <MUIStack spacing={1}>
          <MUITextField
            name="email"
            label="Email"
            placeholder="Email"
            value={inputValues.email}
            onChange={handleOnChange}
            error={!!errors.email}
            errors={errors.email && errors.email}
          />
        </MUIStack>
        <MUIStack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {' '}
        </MUIStack>
        <MUIButton
          fullWidth
          title={isLoading ? 'Loading...' : 'Sent OTP'}
          size="large"
          type="submit"
          variant="contained"
          startIcon={isLoading && <MUICircularProgress sx={{ color: 'white' }} size={20} />}
        />
        <MUIBox sx={{ m: 1, textAlign: 'center' }}>
          <MUITypography variant="body2">
            <Link
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                navigate('/');
              }}
              variant="subtitle2"
              underline="hover"
            >
              Back To Login
            </Link>
          </MUITypography>
        </MUIBox>
      </form>
    </>
  );
}

export default withLoader(ForgotPasswordForm);
