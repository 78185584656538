import { Stack } from '@mui/material';
import React from 'react';

function MUIStack(props) {
  /**
   * props
   */
  const { children, ...rest } = props;

  return <Stack {...rest}>{children}</Stack>;
}

export default MUIStack;
