import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import {
  getCreateLoggedUserHierarchyURL,
  getCreateUserHierarchyURL,
  getGeneologyListURL,
  getGeneologyViewListURL,
  getLoggedUserChildrenListURL,
  getLoggedUserGeneologyURL,
  getLoggedUserHierarchyURL,
  getOneGeneologyURL,
  getParentsListURL,
  getViewAllHierarchyURL,
  getViewFirstLevelUserURL,
  getViewSingleUserHierarchyURL,
} from '../../Services/ApiConfig';
import axiosInstance from '../../Services/API';

/**
 * Define the initialstate
 */
const initialState = {
  loggedUserHierarchyList: [],
  viewAllHierarchy: [],
  viewSingleUserHierarchy: [],
  viewParentList: [],
  viewPaymentTransactionDetails: [],
  viewLoggedUserChildrenList: [],
  viewLoggedUserLedgerPayoutList: [],
  viewGeneology: [],
  viewGeneologyList: [],
  viewLoggedUserGeneology: [],
  viewFirstLevelUser: [],
  viewOneGenealogyUser: [],
  createUserHierarchyLoading: false,
  createLoggedUserHierarchyLoading: false,
  isLoading: false,
  error: {
    message: '',
    code: '',
  },
};

/**
 * fetch logged user hierarchy action
 */
export const fetchLoggedUserHierarchy = createAsyncThunk('/levels/logged-user-hierarchy', async () => {
  try {
    const response = await axiosInstance.get(getLoggedUserHierarchyURL());
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

/**
 * fetch first level user action
 */
export const fetchFirstLevelUser = createAsyncThunk('/levels/view-first-level-users', async () => {
  try {
    const response = await axiosInstance.get(getViewFirstLevelUserURL());
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

/**
 * view all hierarchy action
 */
export const fetchAllHierarchyList = createAsyncThunk('/levels/view-all-hierarchy', async (parameters) => {
  const { search } = parameters;
  try {
    const response = await axiosInstance.get(getViewAllHierarchyURL(search));
    return response;
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 403) {
      toast.error(error?.response?.data?.detail);
    }
    throw error;
  }
});

/**
 * view single user hierarchy action
 */
export const fetchSingleUserHierarchy = createAsyncThunk('/levels/get-user-hierarchy/3', async (id) => {
  try {
    const response = await axiosInstance.get(getViewSingleUserHierarchyURL(id));
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

/**
 * fetch parent list action
 */
export const fetchParentList = createAsyncThunk('/levels/parents-list', async (parameters) => {
  const { searchParentUserEmail } = parameters;
  try {
    const response = await axiosInstance.get(getParentsListURL(searchParentUserEmail));
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

/**
 * fetch logged user childrens list action
 */
export const fetchLoggedUserChildrenList = createAsyncThunk('/levels/view-logged-user-children', async () => {
  try {
    const response = await axiosInstance.get(getLoggedUserChildrenListURL());
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

/**
 * fetch geneology list action
 */
export const fetchGeneologyList = createAsyncThunk('/levels/view-geneology', async () => {
  try {
    const response = await axiosInstance.get(getGeneologyListURL());
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

/**
 * fetch geneology view list action
 */
export const fetchGeneologyViewList = createAsyncThunk('/levels/get-logged-user-geneology', async () => {
  try {
    const response = await axiosInstance.get(getGeneologyViewListURL());
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

/**
 * fetch one geneology action
 */
export const fetchOneGeneology = createAsyncThunk('/levels/get-geneology', async (id) => {
  try {
    const response = await axiosInstance.get(getOneGeneologyURL(id));
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

/**
 * fetch geneology list action
 */
export const fetchLoggedUserGeneologyList = createAsyncThunk('/levels/view-logged-user-geneology', async () => {
  try {
    const response = await axiosInstance.get(getLoggedUserGeneologyURL());
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

/**
 * create user hierarchy action
 */
export const CreateUserHierarchy = createAsyncThunk('/levels/create-user-hierarchy', async (parameters) => {
  try {
    const response = await axiosInstance.post(getCreateUserHierarchyURL(), parameters);
    if (response.status === 200) {
      toast.success(response?.data?.message);
    }
    return response;
  } catch (error) {
    if (error?.response?.status === 400) {
      toast.error(error?.response?.data?.user[0]);
    }
    throw error;
  }
});

/**
 * create logged user hierarchy action
 */
export const CreateLoggedUserHierarchy = createAsyncThunk(
  '/levels/create-logged-user-hierarchy',
  async (parameters) => {
    try {
      const response = await axiosInstance.post(getCreateLoggedUserHierarchyURL(), parameters);
      if (response.status === 200) {
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.error?.detail?.user[0]);
      }
      throw error;
    }
  }
);

/**
 * Define the slice using createSlice from @reduxjs/toolkit
 */
const levelsSlice = createSlice({
  name: 'hierarchy',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoggedUserHierarchy.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchLoggedUserHierarchy.fulfilled, (state, action) => {
        state.loggedUserHierarchyList = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchLoggedUserHierarchy.rejected, (state) => {
        state.isLoading = false;
        state.loggedUserHierarchyList = [];
      })

      .addCase(fetchFirstLevelUser.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchFirstLevelUser.fulfilled, (state, action) => {
        state.viewFirstLevelUser = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchFirstLevelUser.rejected, (state) => {
        state.isLoading = false;
        state.viewFirstLevelUser = [];
      })

      .addCase(fetchParentList.pending, (state) => {
        state.isLoading = false;
      })

      .addCase(fetchParentList.fulfilled, (state, action) => {
        state.viewParentList = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchParentList.rejected, (state) => {
        state.isLoading = false;
        state.viewParentList = [];
      })

      .addCase(fetchAllHierarchyList.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchAllHierarchyList.fulfilled, (state, action) => {
        state.viewAllHierarchy = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchAllHierarchyList.rejected, (state) => {
        state.isLoading = false;
        state.viewAllHierarchy = [];
      })

      .addCase(fetchSingleUserHierarchy.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchSingleUserHierarchy.fulfilled, (state, action) => {
        state.viewSingleUserHierarchy = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchSingleUserHierarchy.rejected, (state) => {
        state.isLoading = false;
        state.viewSingleUserHierarchy = [];
      })

      .addCase(fetchLoggedUserChildrenList.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchLoggedUserChildrenList.fulfilled, (state, action) => {
        state.viewLoggedUserChildrenList = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchLoggedUserChildrenList.rejected, (state) => {
        state.isLoading = false;
        state.viewLoggedUserChildrenList = [];
      })

      .addCase(fetchGeneologyList.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchGeneologyList.fulfilled, (state, action) => {
        state.viewGeneology = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchGeneologyList.rejected, (state) => {
        state.isLoading = false;
        state.viewGeneology = [];
      })

      .addCase(fetchGeneologyViewList.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchGeneologyViewList.fulfilled, (state, action) => {
        state.viewGeneologyList = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchGeneologyViewList.rejected, (state) => {
        state.isLoading = false;
        state.viewGeneologyList = [];
      })

      .addCase(fetchOneGeneology.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchOneGeneology.fulfilled, (state, action) => {
        state.viewOneGenealogyUser = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchOneGeneology.rejected, (state) => {
        state.isLoading = false;
        state.viewOneGenealogyUser = [];
      })

      .addCase(fetchLoggedUserGeneologyList.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchLoggedUserGeneologyList.fulfilled, (state, action) => {
        state.viewLoggedUserGeneology = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchLoggedUserGeneologyList.rejected, (state) => {
        state.isLoading = false;
        state.viewLoggedUserGeneology = [];
      })

      .addCase(CreateUserHierarchy.pending, (state) => {
        state.createUserHierarchyLoading = true;
      })

      .addCase(CreateUserHierarchy.fulfilled, (state, action) => {
        state.createUserHierarchyLoading = false;
      })

      .addCase(CreateUserHierarchy.rejected, (state) => {
        state.createUserHierarchyLoading = false;
      })

      .addCase(CreateLoggedUserHierarchy.pending, (state) => {
        state.createLoggedUserHierarchyLoading = true;
      })

      .addCase(CreateLoggedUserHierarchy.fulfilled, (state, action) => {
        state.createLoggedUserHierarchyLoading = false;
      })

      .addCase(CreateLoggedUserHierarchy.rejected, (state) => {
        state.createLoggedUserHierarchyLoading = false;
      });
  },
});

export const getloggedUserHierarchy = (state) => state.hierarchy.loggedUserHierarchyList;
export const getViewFirstLevelUserList = (state) => state.hierarchy.viewFirstLevelUser;
export const getViewAllHierarchyList = (state) => state.hierarchy.viewAllHierarchy;
export const getViewSingleUserHierarchyList = (state) => state.hierarchy.viewSingleUserHierarchy;
export const getViewParentList = (state) => state.hierarchy.viewParentList;
export const getLoggedUserChildrenList = (state) => state.hierarchy.viewLoggedUserChildrenList;
export const getGeneologyList = (state) => state.hierarchy.viewGeneology;
export const getGeneologyViewList = (state) => state.hierarchy.viewGeneologyList;
export const getOneGeneology = (state) => state.hierarchy.viewOneGenealogyUser;
export const getLoggedUserGeneologyList = (state) => state.hierarchy.viewLoggedUserGeneology;
export const getLoggedUserLedgerPayoutList = (state) => state.hierarchy.viewLoggedUserLedgerPayoutList;
export const getLoadingState = (state) => state.hierarchy.isLoading;
export const getCreateUserHierarchyLoadingState = (state) => state.hierarchy.createUserHierarchyLoading;
export const getCreateLoggedUserHierarchyLoadingState = (state) => state.hierarchy.createLoggedUserHierarchyLoading;

/**
 * Export the reducer
 */
export default levelsSlice.reducer;
