import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProfile,
  getLoadingState,
  getProfileDetails,
} from '../../Redux/Reducer/authReducer';
import ProfileDetails from './ProfileDetails';
import ChangePassword from './ChangePassword';
import MUIBox from '../../components/Common/MUIBox';
import MUIGrid from '../../components/Common/MUIGrid';
import withLoader from '../../components/HOC/withLoader';
import MUIDivider from '../../components/Common/MUIDivider';

const ProfilePage = (props) => {
  /**
   * props
   */
  const { setLoading } = props;
  
  /**
   * states
   */
  const [tabIndex, setTabIndex] = useState(0);

  /**
   * hooks
   */
  const dispatch = useDispatch();

  /**
   * get profile details
   */
  const profileData = useSelector(getProfileDetails)?.data;

  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);

  /**
   * get loading state
   */
  const isLoading = useSelector(getLoadingState);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  /**
   * handle change tabs
   * @param {*} event
   * @param {*} newValue
   */
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <MUIBox sx={{ flexGrow: 1 }}>
      <MUIGrid container spacing={2} sx={{ padding: 2 }}>
        <MUIGrid item xs={12} md={12} lg={12}>
          <MUIBox sx={{ border: '1px solid rgb(224, 224, 224)', borderRadius: '10px' }}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="profile tabs"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <Tab label="Profile Details" />
              <Tab label="Change Password" />
            </Tabs>
            <MUIDivider />
            <MUIBox sx={{ padding: 2 }}>
              {tabIndex === 0 && <ProfileDetails profileData={profileData} />}
              {tabIndex === 1 && <ChangePassword />}
            </MUIBox>
          </MUIBox>
        </MUIGrid>
      </MUIGrid>
    </MUIBox>
  );
};

export default withLoader(ProfilePage);
