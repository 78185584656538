import React from 'react';
import { useNavigate } from 'react-router-dom';
import MUIBox from '../components/Common/MUIBox';
import MUIButton from '../components/Common/MUIButton';
import MUITypography from '../components/Common/MUITypography';
import MUIContainer from '../components/Common/MUIContainer';
import MUIPaper from '../components/Common/MUIPaper';

const SignUpSuccess = () => {
  const navigate = useNavigate();
  return (
    <MUIBox>
      <MUIContainer
        maxWidth="sm"
        sx={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}
      >
        <MUIPaper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
          <MUITypography variant="h4" gutterBottom>
            Account Created Successfully
          </MUITypography>
          <MUITypography variant="body1">Your account has been created successfully.</MUITypography>
          <MUIButton
            title="Log In"
            onClick={() => {
              navigate('/');
            }}
            variant="contained"
            color="primary"
            style={{ marginTop: '20px' }}
          />
        </MUIPaper>
      </MUIContainer>
    </MUIBox>
  );
};

export default SignUpSuccess;
