import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MUIButton from '../../components/Common/MUIButton';
import InputPassword from '../../components/Common/InputPassword';
import { getChangePassword, getChangePasswordLoadingState } from '../../Redux/Reducer/authReducer';
import MUIBox from '../../components/Common/MUIBox';
import MUICircularProgress from '../../components/Common/MUICircularProgress';
import MUIFormControl from '../../components/Common/MUIFormControl';
import MUITypography from '../../components/Common/MUITypography';
import MUIGrid from '../../components/Common/MUIGrid';
import withLoader from '../../components/HOC/withLoader';
import { lowercaseTextRegex, numericValueRegex, specialSymbolRegex, uppercaseTextRegex } from '../../utils/Helper';

function ChangePassword(props) {
  /**
   * props object
   */
  const { setLoading } = props;

  /**
   * hooks
   */
  const dispatch = useDispatch();

  /**
   * form values
   */
  const [inputValues, setInputValues] = useState({
    password: '',
    confirm_password: '',
  });

  /**
   * form errors
   */
  const [errors, setErrors] = useState({
    password: '',
    confirm_password: '',
  });

  /**
   * get loading state
   */
  const isLoading = useSelector(getChangePasswordLoadingState);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  /**
   * validation function
   * @param {*} name
   * @param {*} value
   * @returns
   */
  const validateForm = (name, value) => {
    switch (name) {
      case 'password':
        if (!value || value.trim() === '') {
          return 'New password is required';
        }
        if (!uppercaseTextRegex.test(value)) {
          return 'Password must contain at least one uppercase letter';
        }
        if (!lowercaseTextRegex.test(value)) {
          return 'Password must contain at least one lowercase letter';
        }
        if (!specialSymbolRegex.test(value)) {
          return 'Password must contain at least one special symbol';
        }
        if (!numericValueRegex.test(value)) {
          return 'Password must contain at least one numeric digit';
        }
        if (value.length < 8) {
          return 'Password length minimum 8 character';
        }
        return '';
      case 'confirm_password':
        if (!value || value.trim() === '') {
          return 'Confirm password is required';
        }
        if (inputValues.confirm_password && value !== inputValues.password) {
          return 'Password and Confirm Password does not match.';
        }
        return '';
      default:
        return '';
    }
  };

  /**
   * handle change event
   * @param {*} event
   */
  const handleOnChange = (event) => {
    const { name, value } = event.target;

    setErrors({ ...errors, [name]: validateForm(name, value) });
    setInputValues({ ...inputValues, [name]: value });
  };

  /**
   * handle submit function
   * @param {*} event
   * @returns
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = {};
    Object.keys(inputValues).forEach((name) => {
      const error = validateForm(name, inputValues[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      setErrors({ ...errors, ...validationErrors });
      return;
    }

    dispatch(getChangePassword(inputValues)).then((res) => {
      if (res?.payload?.status === 200) {
        setInputValues({
          password: '',
          confirm_password: '',
        });
      }
    });
  };

  return (
    <MUIBox
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <MUIBox
        sx={{
          width: '100%',
          maxWidth: 500,
          padding: 3,
          border: '1px solid rgb(224, 224, 224)',
          borderRadius: 2,
        }}
      >
        <MUITypography variant="h5" align="center">
          Change Password
        </MUITypography>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <MUIGrid container spacing={2} sx={{ pt: 2 }}>
            <MUIGrid item xs={12}>
              <MUIFormControl fullWidth sx={{ mb: 1 }}>
                <MUITypography variant="subtitle2">New password</MUITypography>
                <InputPassword
                  fullWidth
                  placeholder="New password"
                  variant="outlined"
                  name="password"
                  value={inputValues.password}
                  onChange={handleOnChange}
                  error={!!errors.password}
                  errors={errors.password && errors.password}
                />
              </MUIFormControl>
            </MUIGrid>
            <MUIGrid item xs={12}>
              <MUIFormControl fullWidth sx={{ mb: 1 }}>
                <MUITypography variant="subtitle2">Confirm password</MUITypography>
                <InputPassword
                  fullWidth
                  placeholder="Confirm password"
                  variant="outlined"
                  name="confirm_password"
                  value={inputValues.confirm_password}
                  onChange={handleOnChange}
                  error={!!errors.confirm_password}
                  errors={errors.confirm_password && errors.confirm_password}
                />
              </MUIFormControl>
            </MUIGrid>
            <MUIGrid item xs={12}>
              <MUIButton
                sx={{ float: 'right' }}
                variant="contained"
                title={isLoading ? 'Loading...' : 'Change Password'}
                type="submit"
                startIcon={isLoading && <MUICircularProgress sx={{ color: 'white' }} size={20} />}
              />
            </MUIGrid>
          </MUIGrid>
        </form>
      </MUIBox>
    </MUIBox>
  );
}

export default withLoader(ChangePassword);
