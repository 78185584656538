import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet-async';
import { IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import MUIContainer from '../../components/Common/MUIContainer';
import TableHeader from '../../components/Common/TableHeader';
import withLoader from '../../components/HOC/withLoader';
import MUIDataGrid from '../../components/Common/MUIDataGrid';
import { fetchFirstLevelUser, getViewFirstLevelUserList } from '../../Redux/Reducer/levelsReducer';

function MyDirect() {
  /**
   * hooks
   */
  const dispatch = useDispatch();

  /**
   * get first level user
   */
  const firstLevelUser = useSelector(getViewFirstLevelUserList);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  useEffect(() => {
    dispatch(fetchFirstLevelUser());
  }, [dispatch]);

  /**
   * map JSON to rows
   */
  const rows = firstLevelUser?.data?.results?.[0]?.levels?.level_1?.[0] || [];

  /**
   * handle copy function
   * @param {*} row
   */
  const handleCopy = (row) => {
    navigator.clipboard.writeText(row?.unique_id);
    toast.success('User ID copied successfully!');
  };

  /**
   * columns
   */
  const columns = [
    {
      flex: 1,
      minWidth: 100,
      field: 'unique_id',
      headerName: 'User Id',
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={row?.unique_id}>
              <span>{row?.unique_id}</span>
            </Tooltip>
            <Tooltip title="Copy">
              <IconButton size="small" onClick={() => handleCopy(row)} style={{ marginLeft: 5 }}>
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </span>
        );
      },
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'first_name',
      headerName: 'First Name',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.first_name}>
          <span style={{ textTransform: 'capitalize' }}>{row?.first_name}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'last_name',
      headerName: 'Last Name',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.last_name}>
          <span style={{ textTransform: 'capitalize' }}>{row?.last_name}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'email',
      headerName: 'Email',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.email}>
          <span>{row?.email}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'child_count',
      headerName: 'Total Member',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.child_count}>
          <span>{row?.child_count}</span>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>ApexTrade | My Direct </title>
      </Helmet>
      <MUIContainer>
        <TableHeader
          muiTitle
          title="My Direct"
          textFieldLabel="Search"
          textFieldPlaceholder="Search"
          textFieldSize="small"
          buttonVariant="contained"
          clearBtnTitle="Clear filter"
          clearBtnVariant="contained"
          clearBtnStartIcon={<ClearIcon />}
        />
        <MUIDataGrid
          rows={rows}
          columns={columns}
          rowCount={firstLevelUser?.data?.results?.[0]?.levels?.level_1?.[1]?.count || 0}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[20, 50, 100]}
          getRowId={(row) => row.id}
          columnHeaderHeight={60}
        />
      </MUIContainer>
    </>
  );
}

export default withLoader(MyDirect);
