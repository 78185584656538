import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getAccessToken } from '../utils/Helper';

function ProtectedRoute() {
  const token = getAccessToken();
  if (!token) {
    /**
     * If the user is not authenticated, redirect them to the login page.
     */
    return <Navigate to="/" replace />;
  }

  /**
   * If the user is authenticated, allow access to the protected routes.
   */
  return <Outlet />;
}

export default ProtectedRoute;
