import React from 'react';
import { Paper } from '@mui/material';

function MUIPaper(props) {
  /**
   * props
   */
  const { children, ...rest } = props;

  return <Paper {...rest}>{children}</Paper>;
}

export default MUIPaper;
