import React from 'react';
import { TableContainer } from '@mui/material';

function MUITableContainer(props) {
  /**
   * props
   */
  const { children, ...rest } = props;

  return <TableContainer {...rest}>{children}</TableContainer>;
}

export default MUITableContainer;
