import PropTypes from 'prop-types';
import { useEffect } from 'react';
import GroupIcon from '@mui/icons-material/Group';
import ArticleIcon from '@mui/icons-material/Article';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import LogoutIcon from '@mui/icons-material/Logout';
import TimelineIcon from '@mui/icons-material/Timeline';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SettingsIcon from '@mui/icons-material/Settings';
import KeyIcon from '@mui/icons-material/Key';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { Link, Drawer, Avatar } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import useResponsive from '../../../hooks/useResponsive';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import { fetchProfile, getProfileDetails } from '../../../Redux/Reducer/authReducer';
import MUIBox from '../../../components/Common/MUIBox';
import MUITypography from '../../../components/Common/MUITypography';
import SvgColor from '../../../components/svg-color';
import { getRoleInLocaleStorage } from '../../../utils/Helper';

/**
 * define drawer width
 */
const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  /**
   * hooks
   */
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

  const isDesktop = useResponsive('up', 'lg');

  const rolesArray = getRoleInLocaleStorage();
  const role = JSON.parse(rolesArray);

  /**
   * menu items json
   */
  const navConfig = [
    {
      title: 'dashboard',
      path: '/dashboard',
      icon: icon('ic_analytics'),
      show: role?.includes('Guest'),
    },
    {
      title: 'Dashboard',
      path: '/admin-dashboard',
      icon: icon('ic_analytics'),
      show: role?.includes('Admin'),
    },
    {
      title: 'User list',
      path: '/user-list',
      icon: <GroupIcon />,
      show: true,
    },
    {
      title: 'My Direct',
      path: '/my-directs',
      icon: <RequestPageIcon />,
      show: true,
    },
    {
      title: 'Levels',
      icon: <TimelineIcon />,
      show: true,
      items: [
        {
          title: 'Genealogy',
          path: '/my-genealogy-view',
          icon: <FiberManualRecordIcon sx={{ fontSize: '12px' }} />,
          show: true,
        },
        {
          title: 'All Genealogy',
          path: '/genealogy',
          icon: <FiberManualRecordIcon sx={{ fontSize: '12px' }} />,
          show: role?.includes('Admin'),
        },
        {
          title: 'My genealogy',
          path: '/my-genealogy',
          icon: <FiberManualRecordIcon sx={{ fontSize: '12px' }} />,
          show: true,
        },
      ],
    },
    {
      title: 'Earnings',
      path: '/earnings',
      icon: <RequestPageIcon />,
      show: true,
    },
    // {
    //   title: 'Search Earnings',
    //   path: '/search-earning',
    //   icon: <RequestPageIcon />,
    //   show: role?.includes('Admin'),
    // },
    {
      title: 'Withdraw Requests',
      path: '/withdraw-requests',
      icon: <RequestPageIcon />,
      show: role?.includes('Admin'),
    },
    {
      title: 'Withdraw History',
      path: '/withdraw-history',
      icon: <RequestPageIcon />,
      show: true,
    },
    {
      title: 'E-pins',
      path: '/e-pins',
      icon: <KeyIcon />,
      show: true,
    },
  ];

  /**
   * get profile data
   */
  const getProfileData = useSelector(getProfileDetails)?.data;

  useEffect(() => {
    dispatch(fetchProfile());
  }, []);

  /**
   * handle close nav
   */
  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <MUIBox sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <MUITypography variant="h4">ApexTrade</MUITypography>
      </MUIBox>
      <MUIBox sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar />
            <MUIBox sx={{ ml: 2 }}>
              <MUITypography variant="subtitle2" sx={{ color: 'text.primary', textTransform: 'capitalize' }}>
                {getProfileData?.first_name} {getProfileData?.last_name}
              </MUITypography>
            </MUIBox>
          </StyledAccount>
        </Link>
      </MUIBox>

      <NavSection data={navConfig} />

      <MUIBox sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <MUIBox
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </MUIBox>
  );
}
