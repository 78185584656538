import { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MUIBox from '../Common/MUIBox';
import MUIButton from '../Common/MUIButton';
import MUIDialog from '../Common/MUIDialog';
import MUIDialogTitle from '../Common/MUIDialogTitle';
import MUIDialogContent from '../Common/MUIDialogContent';
import MUIDialogContentText from '../Common/MUIDialogContentText';
import MUIDialogActions from '../Common/MUIDialogActions';
import withLoader from '../HOC/withLoader';
import MUICircularProgress from '../Common/MUICircularProgress';
import MUIIconButton from '../Common/MUIIconButton';
import MUIDivider from '../Common/MUIDivider';

const DeleteConfirmation = (props) => {
  /**
   * props
   */
  const { open, handleClose, handleDelete, selectedData, contentText, title, isLoading, setLoading } = props;

  /**
   * useEffect hook to update loading state
   */
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <MUIBox>
      <MUIDialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <MUIDialogTitle id="alert-dialog-title">
          {title}
          <MUIIconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </MUIIconButton>
        </MUIDialogTitle>
        <MUIDivider />
        <MUIDialogContent>
          <MUIDialogContentText id="alert-dialog-description">{contentText}</MUIDialogContentText>
        </MUIDialogContent>
        <MUIDivider />
        <MUIDialogActions className="dialog-actions-dense">
          <MUIButton title="No" variant="outlined" color="primary" onClick={handleClose} />
          <MUIButton
            title={isLoading ? 'Loading...' : 'Yes'}
            variant="contained"
            color="primary"
            onClick={() => {
              handleDelete(selectedData);
              handleClose();
            }}
            startIcon={isLoading && <MUICircularProgress sx={{ color: 'white' }} size={20} />}
          />
        </MUIDialogActions>
      </MUIDialog>
    </MUIBox>
  );
};

export default withLoader(DeleteConfirmation);
