import { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MUIBox from '../../components/Common/MUIBox';
import MUIButton from '../../components/Common/MUIButton';
import MUIDialog from '../../components/Common/MUIDialog';
import MUIDialogTitle from '../../components/Common/MUIDialogTitle';
import MUIDialogContent from '../../components/Common/MUIDialogContent';
import MUIDialogContentText from '../../components/Common/MUIDialogContentText';
import MUIDialogActions from '../../components/Common/MUIDialogActions';
import withLoader from '../../components/HOC/withLoader';
import MUICircularProgress from '../../components/Common/MUICircularProgress';
import MUIIconButton from '../../components/Common/MUIIconButton';
import MUIDivider from '../../components/Common/MUIDivider';

const WithdrawRequestConfirm = (props) => {
  /**
   * props
   */
  const { open, handleClose, handleAddPackage, selectedData, contentText, title, isLoading, setLoading } = props;

  /**
   * useEffect hook to update loading state
   */
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <MUIBox>
      <MUIDialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <MUIDialogTitle id="alert-dialog-title">
          {title}
          <MUIIconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </MUIIconButton>
        </MUIDialogTitle>
        <MUIDivider />
        <MUIDialogContent>
          <MUIDialogContentText id="alert-dialog-description">{contentText}</MUIDialogContentText>
        </MUIDialogContent>
        <MUIDivider />
        <MUIDialogActions className="dialog-actions-dense">
          <MUIButton title="No" variant="outlined" color="primary" onClick={handleClose} />
          <MUIButton
            title={isLoading ? 'Loading...' : 'Yes'}
            variant="contained"
            color="primary"
            onClick={() => {
              handleAddPackage(selectedData);
              handleClose();
            }}
            startIcon={isLoading && <MUICircularProgress sx={{ color: 'white' }} size={20} />}
          />
        </MUIDialogActions>
      </MUIDialog>
    </MUIBox>
  );
};

export default withLoader(WithdrawRequestConfirm);
