import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddUser,
  fetchUserList,
  getCreateLoadingState,
  getUpdateLoadingState,
  UpdateUser,
} from '../../Redux/Reducer/userReducer';
import { fetchProfile, getProfileDetails } from '../../Redux/Reducer/authReducer';
import { UserActions, getRoleInLocaleStorage, isValidEmail, lowercaseTextRegex, numericValueRegex, specialSymbolRegex, uppercaseTextRegex } from '../../utils/Helper';
import MUITextField from '../../components/Common/MUITextField';
import MUIBox from '../../components/Common/MUIBox';
import MUICircularProgress from '../../components/Common/MUICircularProgress';
import MUIButton from '../../components/Common/MUIButton';
import MUIFormControl from '../../components/Common/MUIFormControl';
import DrawerComponent from '../../components/drawer-component';
import withLoader from '../../components/HOC/withLoader';
import InputPassword from '../../components/Common/InputPassword';
import MUITypography from '../../components/Common/MUITypography';

function AddUserDrawer(props) {
  /**
   * props
   */
  const {
    open,
    handleClose,
    userAction,
    selectedRow,
    setSelectedRow,
    limit,
    offset,
    setLoading,
    firstName,
    middleName,
    lastName,
    userEmail,
    parentSponsorid,
    uniqueId = '',
  } = props;

  /**
   * hooks
   */
  const dispatch = useDispatch();

  /**
   * states
   */
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  /**
   * form fields
   */
  const [inputValues, setInputValues] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    sponsor_id: '',
    sponsor_name: '',
    email: '',
    password: '',
    confirm_password: '',
  });

  /**
   * form errors
   */
  const [errors, setErrors] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    sponsor_id: '',
    email: '',
    password: '',
    confirm_password: '',
  });

  const rolesArray = getRoleInLocaleStorage();
  const role = JSON.parse(rolesArray);

  /**
   * get loading state
   */
  const isCreateLoadingState = useSelector(getCreateLoadingState);
  const isUpdateLoadingState = useSelector(getUpdateLoadingState);

  useEffect(() => {
    setLoading(isCreateLoadingState || isUpdateLoadingState);
    setIsLoading(isCreateLoadingState || isUpdateLoadingState);
  }, [isCreateLoadingState, isUpdateLoadingState]);

  /**
   * get profile data
   */
  const getProfileData = useSelector(getProfileDetails)?.data;

  useEffect(() => {
    if (userAction === UserActions.ADD) {
      dispatch(fetchProfile());
    }
  }, [userAction]);

  useEffect(() => {
    // Handle editing and showing details
    if (userAction === UserActions.EDIT || userAction === UserActions.SHOW_DETAILS) {
      const newInputValues = {
        first_name: selectedRow?.user_first_name || '',
        middle_name: selectedRow?.user_middle_name || '',
        last_name: selectedRow?.user_last_name || '',
        sponsor_id: selectedRow?.parent_sponsor_id || '',
        sponsor_name: selectedRow?.parent_name || '',
        email: selectedRow?.user_email || '',
        password: '',
        confirm_password: '',
      };
      setInputValues(newInputValues);
    } else if (userAction === UserActions.ADD) {
      const newInputValues = {
        sponsor_id: getProfileData?.unique_id || '',
        sponsor_name: `${getProfileData?.first_name || ''} ${getProfileData?.middle_name || ''} ${
          getProfileData?.last_name || ''
        }`,
      };
      setInputValues((prevValues) => ({
        ...prevValues,
        ...newInputValues,
      }));
    } else if (userAction === UserActions.ADDMEMBER) {
      const newInputValues = {
        sponsor_id: selectedRow?.user_unique_id || '',
        sponsor_name: `${selectedRow?.user_first_name || ''} ${selectedRow?.user_middle_name || ''} ${
          selectedRow?.user_last_name || ''
        }`,
      };
      setInputValues((prevValues) => ({
        ...prevValues,
        ...newInputValues,
      }));
    } else {
      setInputValues(inputValues);
    }
  }, [selectedRow, userAction, getProfileData]);

  /**
   * Toggle open/close function
   */
  const handleCloseFun = () => {
    handleClose();

    inputValues.first_name = '';
    inputValues.middle_name = '';
    inputValues.last_name = '';
    inputValues.email = '';
    inputValues.password = '';
    inputValues.confirm_password = '';

    // ** clear form errors
    errors.first_name = '';
    errors.middle_name = '';
    errors.last_name = '';
    errors.email = '';
    errors.password = '';
    errors.confirm_password = '';

    setIsDisabled(false);
    setSelectedRow();
  };

  const handleClick = () => {
    setIsDisabled(!isDisabled);
  };

  /**
   * textfield readonly action
   */
  const disabledAction = !(
    isDisabled ||
    userAction === UserActions.EDIT ||
    userAction === UserActions.ADD ||
    userAction === UserActions.ADDMEMBER
  );

  /**
   * Validation function
   * @param {*} name
   * @param {*} value
   * @returns
   */
  const validateForm = (name, value) => {
    switch (name) {
      case 'first_name':
        if (!value || value.trim() === '') {
          return 'First name is required';
        }
        return '';
      case 'middle_name':
        return '';
      case 'sponsor_id':
        if (!value) {
          return 'Sponsor id is required';
        }
        return '';
      case 'password':
        if (userAction === UserActions.ADD || userAction === UserActions.ADDMEMBER) {
          if (!value || value.trim() === '') {
            return 'Password is required';
          }
          if (!uppercaseTextRegex.test(value)) {
            return 'Password must contain at least one uppercase letter';
          }
          if (!lowercaseTextRegex.test(value)) {
            return 'Password must contain at least one lowercase letter';
          }
          if (!specialSymbolRegex.test(value)) {
            return 'Password must contain at least one special symbol, such as @, #, $, etc.';
          }
          if (!numericValueRegex.test(value)) {
            return 'Password must contain at least one numeric digit';
          }
          if (value.length < 8) {
            return 'Password length minimum 8 character';
          }
        }
        return '';
      case 'confirm_password':
        if (userAction === UserActions.ADD || userAction === UserActions.ADDMEMBER) {
          if (!value || value.trim() === '') {
            return 'Confirm password is required';
          }
          if (inputValues.confirm_password && value !== inputValues.password) {
            return 'Password and Confirm Password does not match.';
          }
        }
        return '';
      default: {
        return '';
      }
    }
  };

  /**
   * Onchange event
   * @param {*} event
   */
  const handleOnChange = (event) => {
    const { name } = event.target;
    const value = event.target.value;

    setErrors({ ...errors, [name]: validateForm(name, value) });
    setInputValues({ ...inputValues, [name]: value });
  };

  /**
   * form submit event
   * @param {*} event
   * @returns
   */
  const onSubmit = (event) => {
    event.preventDefault();
    const validationErrors = {};
    Object.keys(inputValues).forEach((name) => {
      const error = validateForm(name, inputValues[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      setErrors({ ...errors, ...validationErrors });
      return;
    }

    /**
     * Passed inputValues from add user and update user action
     */
    if (userAction === UserActions.ADD || userAction === UserActions.ADDMEMBER) {
      const fields = {
        first_name: inputValues.first_name,
        middle_name: inputValues.middle_name,
        last_name: inputValues.last_name,
        sponsor_id: inputValues.sponsor_id,
        email: inputValues.email,
        password: inputValues.password,
        confirm_password: inputValues.confirm_password,
      };

      dispatch(AddUser(fields)).then((res) => {
        if (res?.payload?.status === 200) {
          handleCloseFun();
          dispatch(
            fetchUserList({ limit, offset, firstName, middleName, lastName, userEmail, parentSponsorid, uniqueId })
          );
        }
      });
    } else {
      const selectedId = selectedRow.user;
      const fields = {
        first_name: inputValues.first_name,
        middle_name: inputValues.middle_name,
        last_name: inputValues.last_name,
        email: inputValues.email,
        sponsor_id: inputValues.sponsor_id,
      };
      const params = {
        selectedUserId: selectedId,
        parameters: fields,
      };

      dispatch(UpdateUser(params)).then((res) => {
        if (res?.payload?.status === 200) {
          handleCloseFun();
          dispatch(
            fetchUserList({ limit, offset, firstName, middleName, lastName, userEmail, parentSponsorid, uniqueId })
          );
        }
      });
    }
  };

  return (
    <DrawerComponent
      open={open}
      anchor="right"
      variant="temporary"
      sx={{ '& .MuiDrawer-paper': { width: { xs: 300, sm: 400 } } }}
      onClose={handleCloseFun}
      title={
        userAction === UserActions.ADDMEMBER
          ? 'Add Member'
          : userAction === UserActions.ADD
          ? 'Add User'
          : userAction === UserActions.EDIT
          ? 'Update User'
          : 'User Details'
      }
      drawerBoxForm={
        <MUIBox sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100vh' }}>
          <form onSubmit={onSubmit} autoComplete="off">
            <MUIFormControl fullWidth sx={{ mb: 2 }}>
              <MUITypography variant="subtitle2">Sponsor Name</MUITypography>
              <MUITextField
                name="sponsor_name"
                placeholder="Sponsor Name"
                value={inputValues.sponsor_name}
                onChange={handleOnChange}
                readOnly
              />
            </MUIFormControl>
            <MUIFormControl fullWidth sx={{ mb: 2 }}>
              <MUITypography variant="subtitle2">Sponsor Id</MUITypography>
              <MUITextField
                name="sponsor_id"
                placeholder="Sponsor Id"
                value={inputValues.sponsor_id}
                onChange={handleOnChange}
                error={!!errors.sponsor_id}
                errors={errors.sponsor_id && errors.sponsor_id}
                readOnly
              />
            </MUIFormControl>
            <MUIFormControl fullWidth sx={{ mb: 2 }}>
              <MUITypography variant="subtitle2">First name</MUITypography>
              <MUITextField
                name="first_name"
                placeholder="First name"
                value={inputValues.first_name}
                onChange={handleOnChange}
                error={!!errors.first_name}
                errors={errors.first_name && errors.first_name}
                readOnly={disabledAction}
              />
            </MUIFormControl>
            <MUIFormControl fullWidth sx={{ mb: 2 }}>
              <MUITypography variant="subtitle2">Middle name</MUITypography>
              <MUITextField
                name="middle_name"
                placeholder="Middle name"
                value={inputValues.middle_name}
                onChange={handleOnChange}
                error={!!errors.middle_name}
                errors={errors.middle_name && errors.middle_name}
                readOnly={disabledAction}
              />
            </MUIFormControl>
            <MUIFormControl fullWidth sx={{ mb: 2 }}>
              <MUITypography variant="subtitle2">Last name</MUITypography>
              <MUITextField
                name="last_name"
                placeholder="Last name"
                value={inputValues.last_name}
                onChange={handleOnChange}
                error={!!errors.last_name}
                errors={errors.last_name && errors.last_name}
                readOnly={disabledAction}
              />
            </MUIFormControl>
            <MUIFormControl fullWidth sx={{ mb: 2 }}>
              <MUITypography variant="subtitle2">Email</MUITypography>
              <MUITextField
                name="email"
                placeholder="Email"
                value={inputValues.email}
                onChange={handleOnChange}
                error={!!errors.email}
                errors={errors.email && errors.email}
                readOnly={disabledAction}
              />
            </MUIFormControl>
            {userAction === UserActions.SHOW_DETAILS || userAction === UserActions.EDIT ? null : (
              <MUIFormControl fullWidth sx={{ mb: 2 }}>
                <MUITypography variant="subtitle2">Password</MUITypography>
                <InputPassword
                  name="password"
                  placeholder="Password"
                  value={inputValues.password}
                  onChange={handleOnChange}
                  error={!!errors.password}
                  errors={errors.password && errors.password}
                  readOnly={disabledAction}
                />
              </MUIFormControl>
            )}
            {userAction === UserActions.SHOW_DETAILS || userAction === UserActions.EDIT ? null : (
              <MUIFormControl fullWidth sx={{ mb: 2 }}>
                <MUITypography variant="subtitle2">Confirm password</MUITypography>
                <InputPassword
                  name="confirm_password"
                  placeholder="Confirm password"
                  value={inputValues.confirm_password}
                  onChange={handleOnChange}
                  error={!!errors.confirm_password}
                  errors={errors.confirm_password && errors.confirm_password}
                  readOnly={disabledAction}
                />
              </MUIFormControl>
            )}
            {userAction === UserActions.SHOW_DETAILS && role?.includes('Admin') ? (
              <MUIBox sx={{ display: 'flex', alignItems: 'center' }}>
                <>
                  {isDisabled === false ? (
                    <MUIButton variant="contained" color="primary" title="Edit" sx={{ mr: 3 }} onClick={handleClick}>
                      Edit
                    </MUIButton>
                  ) : (
                    <MUIButton
                      sx={{ mr: 3 }}
                      variant="contained"
                      title={isLoading ? 'Loading...' : 'Update'}
                      onClick={onSubmit}
                      startIcon={isLoading && <MUICircularProgress sx={{ color: 'white' }} size={20} />}
                    >
                      {isLoading ? 'Loading...' : 'Update'}
                    </MUIButton>
                  )}
                  <MUIButton title={'Cancel'} variant="outlined" onClick={handleCloseFun} />
                </>
              </MUIBox>
            ) : (
              <MUIBox sx={{ pr: 3, pb: 3 }}>
                <MUIBox>
                  {userAction === UserActions.ADD ||
                  userAction === UserActions.EDIT ||
                  userAction === UserActions.ADDMEMBER ? (
                    <>
                      <MUIButton
                        sx={{ mr: 3 }}
                        variant="contained"
                        title={
                          userAction === UserActions.ADD || userAction === UserActions.ADDMEMBER
                            ? isLoading
                              ? 'Loading...'
                              : 'Submit'
                            : isLoading
                            ? 'Loading...'
                            : 'Update'
                        }
                        onClick={onSubmit}
                        startIcon={isLoading && <MUICircularProgress sx={{ color: 'white' }} size={20} />}
                      />
                      <MUIButton title={'Cancel'} variant="outlined" onClick={handleCloseFun} />
                    </>
                  ) : (
                    <MUIButton title={'Cancel'} variant="outlined" onClick={handleCloseFun} />
                  )}
                </MUIBox>
              </MUIBox>
            )}
          </form>
        </MUIBox>
      }
    />
  );
}

export default withLoader(AddUserDrawer);
