import toast from 'react-hot-toast';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCreateEpinURL, getDeleteEpinURL, getUpdateUsedPackageURL, getViewEpinURL } from '../../Services/ApiConfig';
import axiosInstance from '../../Services/API';

/**
 * Define the initialstate
 */
const initialState = {
  epinsList: [],
  isCreateLoading: false,
  isLoading: false,
  deleteEpinisLoading: false,
  updateUsedPackageisLoading: false,
  error: {
    message: '',
    code: '',
  },
};

/**
 * view epins action
 */
export const fetchEpinsList = createAsyncThunk('/financehub/view-epins', async (parameters) => {
  const limit = parameters.limit;
  const offset = parameters.offset;
  const search = parameters.search;
  try {
    const response = await axiosInstance.get(getViewEpinURL(limit, offset, search));
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

/**
 * create e-pin action
 */
export const CreateEpin = createAsyncThunk('/financehub/create-epin', async (parameters) => {
  try {
    const response = await axiosInstance.post(getCreateEpinURL(), parameters);
    if (response.status === 200) {
      toast.success(response.data.message);
    }
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Please contact the administrator');
    throw error;
  }
});

/**
 * delete epin action
 */
export const deleteEpin = createAsyncThunk('/financehub/delete-epin', async (id) => {
  try {
    const response = await axiosInstance.delete(getDeleteEpinURL(id));
    if (response.status === 200) {
      toast.success(response?.data?.message);
    }
  } catch (error) {
    console.log(error);
  }
});

/**
 * update used package action
 */
export const UpdateUsedPackage = createAsyncThunk('/financehub/update-used-package', async (id) => {
  try {
    const response = await axiosInstance.put(getUpdateUsedPackageURL(id));
    if (response.status === 200) {
      toast.success(response.data.message);
    }
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

/**
 * Define the slice using createSlice from @reduxjs/toolkit
 */
const ePinSlice = createSlice({
  name: 'epin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEpinsList.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchEpinsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.epinsList = action.payload;
      })

      .addCase(fetchEpinsList.rejected, (state) => {
        state.isLoading = false;
        state.epinsList = [];
      })

      .addCase(CreateEpin.pending, (state) => {
        state.isCreateLoading = true;
      })

      .addCase(CreateEpin.fulfilled, (state, action) => {
        state.isCreateLoading = false;
      })

      .addCase(CreateEpin.rejected, (state) => {
        state.isCreateLoading = false;
      })

      .addCase(deleteEpin.pending, (state) => {
        state.deleteEpinisLoading = true;
      })

      .addCase(deleteEpin.fulfilled, (state, action) => {
        state.deleteEpinisLoading = false;
      })

      .addCase(deleteEpin.rejected, (state) => {
        state.deleteEpinisLoading = false;
      })

      .addCase(UpdateUsedPackage.pending, (state) => {
        state.updateUsedPackageisLoading = true;
      })

      .addCase(UpdateUsedPackage.fulfilled, (state, action) => {
        state.updateUsedPackageisLoading = false;
      })

      .addCase(UpdateUsedPackage.rejected, (state) => {
        state.updateUsedPackageisLoading = false;
      });
  },
});

export const getEpinList = (state) => state.epin.epinsList;
export const getLoadingState = (state) => state.epin.isLoading;
export const getCreateLoadingState = (state) => state.epin.isCreateLoading;
export const getDeleteLoadingState = (state) => state.epin.deleteEpinisLoading;
export const getUpdateUsedPackageLoadingState = (state) => state.epin.updateUsedPackageisLoading;

/**
 * Export the reducer
 */
export default ePinSlice.reducer;
