import { FormControlLabel } from '@mui/material';
import React from 'react';

function MUIFormControlLabel(props) {
  /**
   * props
   */
  const { sx, label, control } = props;

  return (
    <>
      <FormControlLabel sx={sx} label={label} control={control} />
    </>
  );
}

export default MUIFormControlLabel;
