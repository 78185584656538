import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import {
  getAddUserURL,
  getDeleteUserURL,
  getUpdateUserURL,
  getUserChangePasswordURL,
  getUserListURL,
} from '../../Services/ApiConfig';
import axiosInstance from '../../Services/API';

/**
 * Define the initialstate
 */
const initialState = {
  userList: [],
  addUserData: [],
  isCreateLoading: false,
  isUpdateLoading: false,
  isLoading: false,
  deleteIsLoading: false,
  changePasswordIsLoading: false,
  error: {
    message: '',
    code: '',
  },
};

/**
 * fetch userList action
 */
export const fetchUserList = createAsyncThunk('/accounts/get-all-users', async (parameters) => {
  const limit = parameters.limit;
  const offset = parameters.offset;
  const firstName = parameters?.firstName;
  const middleName = parameters?.middleName;
  const lastName = parameters?.lastName;
  const email = parameters?.userEmail;
  const parentSponsorid = parameters?.parentSponsorid;
  const uniqueId = parameters?.uniqueId;
  try {
    const response = await axiosInstance.get(
      getUserListURL(limit, offset, firstName, middleName, lastName, email, parentSponsorid, uniqueId)
    );
    return response;
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 403) {
      toast.error(error?.response?.data?.detail);
    }
    throw error;
  }
});

/**
 * Add user action
 */
export const AddUser = createAsyncThunk('/accounts/create-user', async (parameters) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  try {
    const response = await axiosInstance.post(getAddUserURL(), parameters, { headers });
    if (response.status === 200) {
      toast.success('User Added Successfully.');
    }
    return response;
  } catch (error) {
    if (error?.response?.status === 400) {
      toast.error(error?.response?.data?.email ? error?.response?.data?.email[0] : error?.response?.data?.mobile_no[0]);
    }
    if (error?.response?.status === 403) {
      toast.error(error?.response?.data?.detail);
    }
    throw error;
  }
});

/**
 * update user action
 */
export const UpdateUser = createAsyncThunk('/accounts/update-user', async (formValues) => {
  const selectedId = formValues.selectedUserId;
  const parameters = formValues.parameters;
  try {
    const response = await axiosInstance.put(getUpdateUserURL(selectedId), parameters);
    if (response.status === 200) {
      toast.success('User updated successfully.');
    }
    return response;
  } catch (error) {
    if (error?.response?.status === 400) {
      toast.error(error?.response?.data?.email ? error?.response?.data?.email[0] : error?.response?.data?.mobile_no[0]);
    }
    if (error?.response?.status === 403) {
      toast.error(error?.response?.data?.detail);
    }
    throw error;
  }
});

/**
 * change user password action
 */
export const ChangeUserPassword = createAsyncThunk('/accounts/user-change-password', async (formValues) => {
  const selectedId = formValues.selectedUserId;
  const parameters = formValues.parameters;
  try {
    const response = await axiosInstance.put(getUserChangePasswordURL(selectedId), parameters);
    if (response.status === 200) {
      toast.success(response.data.message);
    }
    return response;
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 409) {
      toast.error(error?.response?.data?.error?.message);
    }
    if (error?.response?.status === 400) {
      toast.error(error?.response?.data?.error?.detail?.password[0]);
    }
    throw error;
  }
});

/**
 * Delete user action
 */
export const deleteUser = createAsyncThunk('/accounts/delete-user', async (id) => {
  try {
    const response = await axiosInstance.delete(getDeleteUserURL(id));
    if (response.status === 200) {
      toast.success('User deleted successfully.');
    }
  } catch (error) {
    console.log(error);
    console.log('error', error);
    if (error?.response?.status === 403) {
      toast.error(error?.response?.data?.detail);
    }
    if (error?.response?.status === 400) {
      toast.error(error?.response?.data?.error?.message);
    }
    if (error?.response?.status === 500) {
      toast.error('Internal Server Error');
    }
  }
});

/**
 * Define the slice using createSlice from @reduxjs/toolkit
 */
const userSlice = createSlice({
  name: 'userList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserList.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchUserList.fulfilled, (state, action) => {
        state.userList = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchUserList.rejected, (state) => {
        state.isLoading = false;
        state.userList = [];
      })

      .addCase(AddUser.pending, (state) => {
        state.isCreateLoading = true;
      })

      .addCase(AddUser.fulfilled, (state, action) => {
        state.isCreateLoading = false;
      })

      .addCase(AddUser.rejected, (state) => {
        state.isCreateLoading = false;
      })

      .addCase(UpdateUser.pending, (state) => {
        state.isUpdateLoading = true;
      })

      .addCase(UpdateUser.fulfilled, (state, action) => {
        state.isUpdateLoading = false;
      })

      .addCase(UpdateUser.rejected, (state) => {
        state.isUpdateLoading = false;
      })

      .addCase(ChangeUserPassword.pending, (state) => {
        state.changePasswordIsLoading = true;
      })

      .addCase(ChangeUserPassword.fulfilled, (state, action) => {
        state.changePasswordIsLoading = false;
      })

      .addCase(ChangeUserPassword.rejected, (state) => {
        state.changePasswordIsLoading = false;
      })

      .addCase(deleteUser.pending, (state) => {
        state.deleteIsLoading = true;
      })

      .addCase(deleteUser.fulfilled, (state, action) => {
        state.deleteIsLoading = false;
      })

      .addCase(deleteUser.rejected, (state) => {
        state.deleteIsLoading = false;
      });
  },
});

export const getUserList = (state) => state.userList.userList;
export const getCreateLoadingState = (state) => state.userList.isCreateLoading;
export const getUpdateLoadingState = (state) => state.userList.isUpdateLoading;
export const getLoadingState = (state) => state.userList.isLoading;
export const getDeleteLoadingState = (state) => state.userList.deleteIsLoading;
export const getChangeUserPasswordLoadingState = (state) => state.userList.changePasswordIsLoading;

/**
 * Export the reducer
 */
export default userSlice.reducer;
