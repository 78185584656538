import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MUIBox from '../components/Common/MUIBox';
import MUIButton from '../components/Common/MUIButton';
import MUITypography from '../components/Common/MUITypography';
import MUIContainer from '../components/Common/MUIContainer';
import { getAccessToken } from '../utils/Helper';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function Page404() {
  /**
   * hooks
   */
  const navigate = useNavigate();

  /**
   * handle navigate function
   */
  const handleNavigate = () => {
    navigate(token ? '/dashboard' : '/');
  };

  /**
   * get access token
   */
  const token = getAccessToken();
  return (
    <>
      <Helmet>
        <title> 404 Page Not Found </title>
      </Helmet>

      <MUIContainer>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <MUITypography variant="h3" paragraph>
            Sorry, page not found!
          </MUITypography>

          <MUITypography sx={{ color: 'text.secondary' }}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your
            spelling.
          </MUITypography>

          <MUIBox
            component="img"
            src="/assets/illustrations/illustration_404.svg"
            sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
          />

          <MUIButton
            title={token ? 'Go to Dashboard' : 'Go to Login'}
            size="large"
            variant="contained"
            component={RouterLink}
            onClick={handleNavigate}
          />
        </StyledContent>
      </MUIContainer>
    </>
  );
}
