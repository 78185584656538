import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import axiosInstance from '../../Services/API';
import {
  getAdminDashboardURL,
  getChangePasswordURL,
  getForgotPasswordURL,
  getProfileDetailsURL,
  getSetNewPasswordURL,
  getSignInURL,
  getUpdateProfileURL,
  getUserDashboardURL,
} from '../../Services/ApiConfig';
import { saveAccessToken, saveRefreshToken, saveRoleInLocaleStorage } from '../../utils/Helper';

/**
 * Define the initialstate
 */
const initialState = {
  loginUser: [],
  profileData: [],
  userDashboardData: [],
  adminDashboardData: [],
  isLoading: false,
  isForgotPasswordLoading: false,
  isSetNewPasswordLoading: false,
  isUpdateProfileLoading: false,
  isChangePasswordLoading: false,
  error: {
    message: '',
    code: '',
  },
};

/**
 * Login user API Call
 */
export const loginUser = createAsyncThunk('/accounts/token-access', async (formValues) => {
  const parameters = {
    // email_or_mobile: formValues.email_or_mobile,
    unique_id: formValues.email_or_mobile,
    password: formValues.password,
  };
  try {
    const response = await axiosInstance.post(getSignInURL(), parameters);
    if (response.status === 200) {
      toast.success('Login Successfully.');
      saveAccessToken('access', response?.data?.data?.access);
      saveRefreshToken('refresh', response?.data?.data?.refresh);
      saveRoleInLocaleStorage('role', JSON.stringify(response?.data?.data?.roles?.map((item) => item?.role)));
    }
    return response;
  } catch (error) {
    if (error?.response?.status === 401) {
      toast.error(error?.response?.data?.error?.message);
    }
    if (error?.response?.status === 400) {
      toast.error(error?.response?.data?.error?.detail?.email[0]);
    }
    throw error;
  }
});

/**
 * forgot password API Call
 */
export const forgotPassword = createAsyncThunk('/accounts/reset-password', async (formValues) => {
  const parameters = {
    email: formValues.email,
  };
  try {
    const response = await axiosInstance.post(getForgotPasswordURL(), parameters);
    if (response.status === 200) {
      toast.success(response?.data?.message);
      localStorage.setItem('uuid', response?.data?.data?.uuid);
    }
    return response;
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 400) {
      toast.error(error?.response?.data?.error?.message);
    }
    if (error?.response?.status === 409) {
      toast.error(error?.response?.data?.error?.detail?.email[0]);
    }
    if (error?.response?.status === 404) {
      toast.error(error?.response?.data?.error?.message);
    }
    throw error;
  }
});

/**
 * set new password API Call
 */
export const setNewPassword = createAsyncThunk('/accounts/forgot-user-password', async (formValues) => {
  const parameters = {
    otp: formValues.otp,
    uuid: localStorage.getItem('uuid'),
    password: formValues.password,
    confirm_password: formValues.confirm_password,
  };
  try {
    const response = await axiosInstance.put(getSetNewPasswordURL(), parameters);
    if (response.status === 200) {
      toast.success(response?.data?.message);
      localStorage.removeItem('uuid');
    }
    return response;
  } catch (error) {
    if (error?.response?.status === 404) {
      toast.error(error?.response?.data?.error?.message);
    }
    if (error?.response?.status === 400) {
      toast.error(error?.response?.data?.error?.message);
    }
    if (error?.response?.status === 409) {
      toast.error(error?.response?.data?.error?.detail?.non_field_errors[0]);
    }
    throw error;
  }
});

/**
 * get profile details
 */
export const fetchProfile = createAsyncThunk('/accounts/profile', async () => {
  try {
    const response = await axiosInstance.get(getProfileDetailsURL());
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

/**
 * get user dashboard details
 */
export const fetchUserDashboard = createAsyncThunk('/accounts/user-dashboard', async () => {
  try {
    const response = await axiosInstance.get(getUserDashboardURL());
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

/**
 * get admin dashboard details
 */
export const fetchAdminDashboard = createAsyncThunk('/accounts/admin-dashboard', async () => {
  try {
    const response = await axiosInstance.get(getAdminDashboardURL());
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

/**
 * Update profile API Call
 */
export const UpdateProfile = createAsyncThunk('/accounts/update-profile', async (parameters) => {
  try {
    const response = await axiosInstance.put(getUpdateProfileURL(), parameters);
    if (response.status === 200) {
      toast.success(response?.data?.message);
    }
    return response;
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 400) {
      toast.error(error?.response?.data?.email ? error?.response?.data?.email : error?.response?.data?.mobile_no[0]);
    }
    throw error;
  }
});

/**
 * Chnage password API Call
 */
export const getChangePassword = createAsyncThunk('/accounts/change-password', async (parameters) => {
  try {
    const response = await axiosInstance.put(getChangePasswordURL(), parameters);
    if (response.status === 200) {
      toast.success(response?.data?.message);
    }
    return response;
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 409) {
      toast.error(error?.response?.data?.error?.message);
    }
    if (error?.response?.status === 400) {
      toast.error(error?.response?.data?.error?.detail?.confirm_password[0]);
    }
    throw error;
  }
});

/**
 * Define the slice using createSlice from @reduxjs/toolkit
 */
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(loginUser.fulfilled, (state, action) => {
        state.loginUser = action.payload;
        state.isLoading = false;
      })

      .addCase(loginUser.rejected, (state) => {
        state.isLoading = false;
        state.loginUser = [];
      })

      .addCase(forgotPassword.pending, (state) => {
        state.isForgotPasswordLoading = true;
      })

      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isForgotPasswordLoading = false;
      })

      .addCase(forgotPassword.rejected, (state) => {
        state.isForgotPasswordLoading = false;
      })

      .addCase(setNewPassword.pending, (state) => {
        state.isSetNewPasswordLoading = true;
      })

      .addCase(setNewPassword.fulfilled, (state, action) => {
        state.isSetNewPasswordLoading = false;
      })

      .addCase(setNewPassword.rejected, (state) => {
        state.isSetNewPasswordLoading = false;
      })

      .addCase(fetchProfile.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.profileData = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchProfile.rejected, (state) => {
        state.isLoading = false;
        state.profileData = [];
      })

      .addCase(fetchUserDashboard.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchUserDashboard.fulfilled, (state, action) => {
        state.userDashboardData = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchUserDashboard.rejected, (state) => {
        state.isLoading = false;
        state.userDashboardData = [];
      })

      .addCase(fetchAdminDashboard.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchAdminDashboard.fulfilled, (state, action) => {
        state.adminDashboardData = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchAdminDashboard.rejected, (state) => {
        state.isLoading = false;
        state.adminDashboardData = [];
      })

      .addCase(UpdateProfile.pending, (state) => {
        state.isUpdateProfileLoading = true;
      })

      .addCase(UpdateProfile.fulfilled, (state, action) => {
        state.isUpdateProfileLoading = false;
      })

      .addCase(UpdateProfile.rejected, (state) => {
        state.isUpdateProfileLoading = false;
      })

      .addCase(getChangePassword.pending, (state) => {
        state.isChangePasswordLoading = true;
      })

      .addCase(getChangePassword.fulfilled, (state, action) => {
        state.isChangePasswordLoading = false;
      })

      .addCase(getChangePassword.rejected, (state) => {
        state.isChangePasswordLoading = false;
      });
  },
});

export const getLoadingState = (state) => state.auth.isLoading;
export const getForgotpasswordLoadingState = (state) => state.auth.isForgotPasswordLoading;
export const getSetNewPasswordLoadingState = (state) => state.auth.isSetNewPasswordLoading;
export const getProfileDetails = (state) => state.auth.profileData;
export const getUserDashboardDetails = (state) => state.auth.userDashboardData;
export const getAdminDashboardDetails = (state) => state.auth.adminDashboardData;
export const getUpdateProfileLoadingState = (state) => state.auth.isUpdateProfileLoading;
export const getChangePasswordLoadingState = (state) => state.auth.isChangePasswordLoading;

/**
 * Export the reducer
 */
export default authSlice.reducer;
