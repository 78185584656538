import { Grid } from '@mui/material';
import React from 'react';
import MUIDatePicker from '../../components/Common/MUIDatePicker';
import MUITextField from '../../components/Common/MUITextField';

function WithdrawHistorySearchingHeader(props) {
  const { onStartDateChange, onEndDateChange, startDateValue, endDateValue, onChange, userIdValue } = props;

  return (
    <Grid container spacing={2} mb={2}>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MUITextField
          label="Search user id"
          placeholder="Search user id"
          size="small"
          onChange={onChange}
          value={userIdValue}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MUIDatePicker
          label="Start Date"
          onChange={(date) => onStartDateChange(date)}
          value={startDateValue || null}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MUIDatePicker
          label="End Date"
          onChange={(date) => onEndDateChange(date)}
          value={endDateValue || null}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

export default WithdrawHistorySearchingHeader;
