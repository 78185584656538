import React from 'react';
import { FormHelperText } from '@mui/material';

function MUIFormHelperText(props) {
  /**
   * props
   */
  const { children, ...rest } = props;

  return <FormHelperText {...rest}>{children}</FormHelperText>;
}

export default MUIFormHelperText;
