import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import MUIBox from './MUIBox';

function MUIDataGrid(props) {
  /**
   * props
   */
  const {
    rows,
    columns,
    rowCount,
    page,
    getRowId,
    paginationModel,
    onPaginationModelChange,
    onRowSelectionModelChange,
    selectionModel,
    localeText,
    columnHeaderHeight,
    onRowClick
  } = props;
  return (
    <MUIBox>
      <DataGrid
        rows={rows}
        columns={columns}
        rowCount={rowCount || 0}
        page={page}
        getRowId={getRowId}
        paginationMode="server"
        pageSizeOptions={[20, 50, 100]}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        disableRowSelectionOnClick
        onRowSelectionModelChange={onRowSelectionModelChange}
        selectionModel={selectionModel}
        autoHeight
        localeText={localeText}
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        disableSelectionOnClick
        columnHeaderHeight={columnHeaderHeight}
        onRowClick={onRowClick}
        sx={{
          '&.MuiDataGrid-root': {
            border: '1px solid #e0e0e0',
            borderRadius: '4px',
          },
          '& .MuiDataGrid-row': {
            borderBottom: '1px solid #e0e0e0',
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: '2px solid #e0e0e0',
          },
        }}
      />
    </MUIBox>
  );
}

export default MUIDataGrid;
