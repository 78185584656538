import React from 'react';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import Iconify from '../iconify';
import MUITypography from './MUITypography';
import MUITextField from './MUITextField';
import MUIButton from './MUIButton';
import MUIBox from './MUIBox';

const ResponsiveBox = styled(MUIBox)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  flexWrap: 'wrap',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  '& > *': {
    marginBottom: theme.spacing(2),
  },
}));

const LinkBoxStyle = styled(MUIBox)(({ theme }) => ({
  backgroundColor: 'rgb(32, 101, 209)',
  padding: '8px',
  borderRadius: '5px',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: 'rgb(21, 75, 156)',
  },
}));

const LinkStyle = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: 'white',
  fontSize: '14px',
  fontWeight: 'bold',
  fontFamily: 'Public Sans,sans-serif',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

function TableHeader(props) {
  /**
   * props
   */
  const {
    muiTitle,
    muiAddBtn,
    muiClearBtn,
    muiTextField,
    title,
    textFieldLabel,
    textFieldPlaceholder,
    textFieldSize,
    buttonTitle,
    buttonVariant,
    onChange,
    handleAdd,
    clearBtnTitle,
    clearBtnVariant,
    clearOnClick,
    clearBtnStartIcon,
    clearDisabled,
    searchValue,
    addNewUser,
    addTitle,
    printBtn,
    exportBtnVariant,
    exportBtnTitle,
    handleExport,
    showExportButton
  } = props;

  return (
    <ResponsiveBox>
      {muiTitle && (
        <MUIBox>
          <MUITypography variant="h4" gutterBottom>
            {title}
          </MUITypography>
        </MUIBox>
      )}
      {muiTextField && (
        <MUIBox>
          <MUITextField
            label={textFieldLabel}
            placeholder={textFieldPlaceholder}
            value={searchValue}
            size={textFieldSize}
            onChange={onChange}
            fullWidth
          />
        </MUIBox>
      )}
      {muiClearBtn && (
        <MUIBox sx={{ display: 'flex', gap: 2 }}>
          {showExportButton && (
            <MUIBox sx={{ flexGrow: 1 }}>
              <MUIButton
                fullWidth
                title={exportBtnTitle}
                variant={exportBtnVariant}
                onClick={handleExport}
                startIcon={<FileUploadIcon />}
              />
            </MUIBox>
          )}
          <MUIBox sx={{ flexGrow: 1 }}>
            <MUIButton
              title={clearBtnTitle}
              variant={clearBtnVariant}
              onClick={clearOnClick}
              startIcon={clearBtnStartIcon}
              disabled={clearDisabled}
              fullWidth
            />
          </MUIBox>
        </MUIBox>
      )}
      {printBtn && (
        <MUIBox>
          <MUIButton title={'Print this page'} variant={'contained'} fullWidth />
        </MUIBox>
      )}
      {addNewUser && (
        <LinkBoxStyle>
          <LinkStyle>
            <Iconify icon="eva:plus-fill" /> {addTitle}
          </LinkStyle>
        </LinkBoxStyle>
      )}
      {muiAddBtn && (
        <MUIBox>
          <MUIButton
            title={buttonTitle}
            variant={buttonVariant}
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={handleAdd}
            fullWidth
          />
        </MUIBox>
      )}
    </ResponsiveBox>
  );
}

export default TableHeader;
