import React from 'react';
import { FormControl } from '@mui/material';

function MUIFormControl(props) {
  /**
   * props
   */
  const { children, ...rest } = props;

  return <FormControl {...rest}>{children}</FormControl>;
}

export default MUIFormControl;
