import React from 'react';
import { Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import PasswordIcon from '@mui/icons-material/Password';
import { UserActions } from '../../utils/Helper';
import MUIBox from '../Common/MUIBox';
import MUIIconButton from '../Common/MUIIconButton';
import MUIButton from '../Common/MUIButton';
import Iconify from '../iconify';

const RowOptions = React.forwardRef((props, ref) => {
  /**
   * props
   */
  const {
    selectionModel,
    handleUserAction,
    handleClickOpen,
    handleClickOpenAdd,
    viewIcon,
    editIcon,
    deleteIcon,
    addPackage,
    deletebtn,
    disabled,
    paybtn,
    rejuctbtn,
    addNewMemberIcon,
    changePasswordIcon,
  } = props;

  return (
    <MUIBox sx={{ display: 'block', alignItems: 'center' }} ref={ref}>
      {addNewMemberIcon && (
        <Tooltip title="Add Member">
          <MUIIconButton size="small" onClick={() => handleUserAction(selectionModel, UserActions.ADDMEMBER)}>
            <PersonAddIcon fontSize="20" />
          </MUIIconButton>
        </Tooltip>
      )}
      {changePasswordIcon && (
        <Tooltip title="Change Password">
          <MUIIconButton size="small" onClick={() => handleUserAction(selectionModel, UserActions.CHANGE_PASSWORD)}>
            <PasswordIcon fontSize="20" />
          </MUIIconButton>
        </Tooltip>
      )}
      {viewIcon && (
        <Tooltip title="View">
          <MUIIconButton size="small" onClick={() => handleUserAction(selectionModel, UserActions.SHOW_DETAILS)}>
            <VisibilityIcon fontSize="20" />
          </MUIIconButton>
        </Tooltip>
      )}
      {editIcon && (
        <Tooltip title="Edit">
          <MUIIconButton size="small" onClick={() => handleUserAction(selectionModel, UserActions.EDIT)}>
            <CreateOutlinedIcon fontSize="20" />
          </MUIIconButton>
        </Tooltip>
      )}
      {addPackage && (
        <MUIButton
          title="Add Package"
          variant="contained"
          size="small"
          onClick={() => {
            handleUserAction(selectionModel, UserActions.ADD);
            handleClickOpenAdd();
          }}
          startIcon={<Iconify icon="eva:plus-fill" />}
          disabled={disabled}
        />
      )}
      {deleteIcon && (
        <Tooltip title="Delete">
          <MUIIconButton
            size="small"
            onClick={() => {
              handleUserAction(selectionModel, UserActions.DELETE);
              handleClickOpen();
            }}
          >
            <DeleteIcon fontSize="20" />
          </MUIIconButton>
        </Tooltip>
      )}
      {deletebtn && (
        <MUIButton
          title="Delete"
          variant="contained"
          size="small"
          onClick={() => {
            handleUserAction(selectionModel, UserActions.DELETE);
            handleClickOpen();
          }}
          startIcon={<DeleteIcon fontSize="20" />}
          sx={{ ml: 1 }}
          color="error"
          disabled={disabled}
        />
      )}
      {rejuctbtn && (
        <MUIButton
          title="Reject"
          variant="contained"
          size="small"
          onClick={() => {
            handleUserAction(selectionModel, UserActions.DELETE);
            handleClickOpen();
          }}
          color="error"
          disabled={disabled}
        />
      )}
      {paybtn && (
        <MUIButton
          title="Pay"
          variant="contained"
          size="small"
          onClick={() => {
            handleUserAction(selectionModel, UserActions.ADD);
          }}
          disabled={disabled}
        />
      )}
    </MUIBox>
  );
});

export default RowOptions;
