import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import LogoutIcon from '@mui/icons-material/Logout';
import toast from 'react-hot-toast';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import { List, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import MUIBox from '../Common/MUIBox';
import MUIIconButton from '../Common/MUIIconButton';

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  // Filter the data array to include only items with show === true
  const filteredData = data?.filter((item) => item?.show === true);

  const navigate = useNavigate();
  /**
   * handle logout function
   */
  const handleLogout = () => {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    localStorage.removeItem('role');
    navigate('/');
    toast.success('Logout successful.');
  };

  return (
    <MUIBox {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {filteredData.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
        <StyledNavItem
          sx={{
            '&.active': {
              color: 'text.primary',
              bgcolor: 'action.selected',
              fontWeight: 'fontWeightBold',
            },
          }}
          onClick={handleLogout}
        >
          <StyledNavItemIcon>
            <StyledNavItemIcon>
              <LogoutIcon />
            </StyledNavItemIcon>
            <ListItemText disableTypography primary={'Logout'} />
          </StyledNavItemIcon>{' '}
        </StyledNavItem>
      </List>
    </MUIBox>
  );
}

function NavItem({ item }) {
  /**
   * props
   */
  const { title, path, icon, info, items } = item;

  const filteredData = items?.filter((item) => item?.show === true);

  /**
   * hooks
   */
  const nestedListRef = useRef(null);

  /**
   * state
   */
  const [open, setOpen] = useState(false);

  /**
   * set open
   */
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <StyledNavItem
        to={path}
        component={items ? 'div' : RouterLink}
        onClick={handleToggle}
        sx={{
          '&.active': {
            color: 'text.primary',
            bgcolor: 'action.selected',
            fontWeight: 'fontWeightBold',
          },
        }}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
        <ListItemText disableTypography primary={title} />
        {filteredData && (
          <MUIIconButton size="small" sx={{ mr: 1 }} onClick={handleToggle}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </MUIIconButton>
        )}
        {info && info}
      </StyledNavItem>
      {filteredData && (
        <List
          disablePadding
          ref={nestedListRef}
          style={{
            maxHeight: open ? `${nestedListRef.current.scrollHeight}px` : '0',
            overflow: 'hidden',
            transition: 'max-height 0.3s ease-in-out',
          }}
        >
          {filteredData.map((nestedItem) => (
            <NavItem key={nestedItem.title} item={nestedItem} />
          ))}
        </List>
      )}
    </>
  );
}
