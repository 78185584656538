import React from 'react';
import { Divider } from '@mui/material';

function MUIDivider(props) {
  /**
   * props
   */
  const { sx } = props;

  return <Divider sx={sx} />;
}

export default MUIDivider;
