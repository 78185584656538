import React, { useState } from 'react';
import { TextField } from '@mui/material';
import Iconify from '../iconify';
import MUIFormHelperText from './MUIFormHelperText';
import MUIIconButton from './MUIIconButton';
import MUIInputAdornment from './MUIInputAdornment';

function InputPassword(props) {
  const { name, label, placeholder, value, onChange, error, errors, fullWidth } = props;
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <TextField
        name={name}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        error={error}
        fullWidth={fullWidth}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <MUIInputAdornment position="end">
              <MUIIconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
              </MUIIconButton>
            </MUIInputAdornment>
          ),
        }}
      />
      <MUIFormHelperText sx={{ color: 'error.main', ml: 0 }}>{errors}</MUIFormHelperText>
    </>
  );
}

export default InputPassword;
