import React from 'react';
import { Dialog } from '@mui/material';

function MUIDialog(props) {
  /**
   * props
   */
  const { children, ...rest } = props;

  return <Dialog {...rest}>{children}</Dialog>;
}

export default MUIDialog;
