import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import MUIFormHelperText from './MUIFormHelperText';

function MUISearchable(props) {
  const {
    sx,
    onChange,
    options,
    label,
    placeholder,
    size,
    onKeyUp,
    value,
    onInputChange,
    inputValue,
    name,
    error,
    errorMessage,
    fullWidth,
    style,
    className,
    disabled,
    color,
    readOnly,
  } = props;
  return (
    <>
      <Autocomplete
        sx={{
          ...sx,
          fontSize: '14px',
        }}
        className={className}
        onChange={onChange}
        onInputChange={onInputChange}
        inputValue={inputValue}
        onKeyUp={onKeyUp}
        options={options}
        name={name}
        value={value}
        fullWidth={fullWidth}
        disabled={disabled}
        color={color}
        readOnly={readOnly}
        renderInput={(params) => (
          <TextField
            sx={style}
            className={className}
            {...params}
            color={color}
            error={error}
            name={name}
            size={size}
            label={label}
            placeholder={placeholder}
          />
        )}
      />
      <MUIFormHelperText
        sx={{
          color: 'error.main',
          ml: 0,
        }}
      >
        {errorMessage}
      </MUIFormHelperText>
    </>
  );
}

export default MUISearchable;
