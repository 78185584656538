import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import MUITypography from '../Common/MUITypography';
import MUIIconButton from '../Common/MUIIconButton';
import MUIDivider from '../Common/MUIDivider';
import MUIBox from '../Common/MUIBox';
import MUIButton from '../Common/MUIButton';

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3, 4),
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.default,
}));

const DrawerComponent = ({
  open,
  onClose,
  anchor,
  variant,
  sx,
  children,
  title,
  drawerBoxForm,
  ...rest
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(location?.state?.from);
  };
  
  return (
    <Drawer
      open={open}
      anchor={anchor}
      variant={variant}
      onClose={onClose}
      ModalProps={{ keepMounted: true }}
      {...rest}
      sx={sx}
    >
      <Header sx={{ p: 2 }}>
        <MUIIconButton onClick={onClose}>
          <CloseIcon sx={{ cursor: 'pointer' }} />
        </MUIIconButton>
      </Header>
      <MUIDivider />
      <MUIBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pl: 2,
          pr: 2,
          pt: 2,
        }}
      >
        <MUITypography variant="h4">{title}</MUITypography>
      </MUIBox>
      {drawerBoxForm}
    </Drawer>
  );
};

export default DrawerComponent;
