import { useEffect } from 'react';
import MUIBox from '../Common/MUIBox';
import MUIButton from '../Common/MUIButton';
import MUIDialog from '../Common/MUIDialog';
import MUIDialogTitle from '../Common/MUIDialogTitle';
import MUIDialogContent from '../Common/MUIDialogContent';
import MUIDialogContentText from '../Common/MUIDialogContentText';
import MUIDialogActions from '../Common/MUIDialogActions';
import withLoader from '../HOC/withLoader';
import MUICircularProgress from '../Common/MUICircularProgress';

const AddPackageConfirmation = (props) => {
  /**
   * props
   */
  const { open, handleClose, handleAddPackage, selectedData, contentText, title, isLoading, setLoading } = props;

  /**
   * useEffect hook to update loading state
   */
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <MUIBox>
      <MUIDialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <MUIDialogTitle id="alert-dialog-title">{title}</MUIDialogTitle>
        <MUIDialogContent>
          <MUIDialogContentText id="alert-dialog-description">{contentText}</MUIDialogContentText>
        </MUIDialogContent>
        <MUIDialogActions className="dialog-actions-dense">
          <MUIButton title="No" variant="outlined" color="primary" onClick={handleClose} />
          <MUIButton
            title={isLoading ? 'Loading...' : 'Yes'}
            variant="contained"
            color="primary"
            onClick={() => {
              handleAddPackage(selectedData);
              handleClose();
            }}
            startIcon={isLoading && <MUICircularProgress sx={{ color: 'white' }} size={20} />}
          />
        </MUIDialogActions>
      </MUIDialog>
    </MUIBox>
  );
};

export default withLoader(AddPackageConfirmation);
