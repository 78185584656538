import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import {
  getCreateWithdrawRequestURL,
  getRejuctWithdrawRequestStatusURL,
  getUpdateWithdrawRequestStatusURL,
  getViewAllPackageURL,
  getViewAllUserDashboardURL,
  getViewBalanceURL,
  getViewEarningURL,
  getViewWalletBalanceURL,
  getViewWalletTransactionURL,
  getViewWithdrawDetailsPaidRejuctURL,
  getViewWithdrawDetailsUnpaidURL,
  getViewWithdrawDetailsURL,
} from '../../Services/ApiConfig';
import axiosInstance from '../../Services/API';

/**
 * Define the initialstate
 */
const initialState = {
  earningList: [],
  walletTransactionList: [],
  walletBalanceList: [],
  withdrawDetailsList: [],
  withdrawDetailsUnpaidList: [],
  withdrawDetailsPaidRejuctList: [],
  viewBalanceDetails: [],
  viewAllPackages: [],
  viewAllUserDashboard: [],
  isLoading: false,
  isCreateLoading: false,
  updateWithdrawRequestisLoading: false,
  rejectWithdrawRequestisLoading: false,
  error: {
    message: '',
    code: '',
  },
};

/**
 * fetch earning list action
 */
export const fetchEarningList = createAsyncThunk('/financehub/view-earning', async (parameters) => {
  const limit = parameters.limit;
  const offset = parameters.offset;
  const search = parameters.search;
  const refId = parameters.refId;
  const startDate = parameters.startDate;
  const endDate = parameters.endDate;
  try {
    const response = await axiosInstance.get(getViewEarningURL(limit, offset, search, refId, startDate, endDate));
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

/**
 * fetch wallet transaction list action
 */
export const fetchWalletTransactionList = createAsyncThunk(
  '/financehub/view-wallet-transactions',
  async (parameters) => {
    const limit = parameters.limit;
    const offset = parameters.offset;
    try {
      const response = await axiosInstance.get(getViewWalletTransactionURL(limit, offset));
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

/**
 * fetch wallet balance list action
 */
export const fetchWalletBalanceList = createAsyncThunk('/financehub/view-wallet-balance', async (parameters) => {
  const limit = parameters.limit;
  const offset = parameters.offset;
  try {
    const response = await axiosInstance.get(getViewWalletBalanceURL(limit, offset));
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

/**
 * fetch withdraw details list action
 */
export const fetchWithdrawDetailsList = createAsyncThunk('/financehub/view-withdraw-details', async (parameters) => {
  const limit = parameters.limit;
  const offset = parameters.offset;
  const search = parameters.search;
  try {
    const response = await axiosInstance.get(getViewWithdrawDetailsURL(limit, offset, search));
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

/**
 * fetch withdraw details list action
 */
export const fetchWithdrawDetailsUnpaidList = createAsyncThunk(
  '/financehub/view-withdraw-details-unpaid',
  async (parameters) => {
    const limit = parameters.limit;
    const offset = parameters.offset;
    const search = parameters.search;
    try {
      const response = await axiosInstance.get(getViewWithdrawDetailsUnpaidURL(limit, offset, search));
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

/**
 * fetch withdraw details list action
 */
export const fetchWithdrawDetailsPaidRejuctList = createAsyncThunk(
  '/financehub/view-withdraw-details-paid-reject',
  async (parameters) => {
    const limit = parameters.limit;
    const offset = parameters.offset;
    const search = parameters.search;
    const formattedStartDate = parameters.formattedStartDate;
    const formattedEndDate = parameters.formattedEndDate;
    try {
      const response = await axiosInstance.get(
        getViewWithdrawDetailsPaidRejuctURL(limit, offset, search, formattedStartDate, formattedEndDate)
      );
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

/**
 * fetch all packages list action
 */
export const fetchAllPackages = createAsyncThunk('/financehub/view-all-package', async (parameters) => {
  const limit = parameters.limit;
  const offset = parameters.offset;
  try {
    const response = await axiosInstance.get(getViewAllPackageURL(limit, offset));
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

/**
 * fetch view balance action
 */
export const fetchViewBalance = createAsyncThunk('/financehub/view-balance', async () => {
  try {
    const response = await axiosInstance.get(getViewBalanceURL());
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

/**
 * fetch all user dashboard
 */
export const fetchAllUserDashboard = createAsyncThunk('/financehub/view-all-package-user-dashboard', async () => {
  try {
    const response = await axiosInstance.get(getViewAllUserDashboardURL());
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

/**
 * create withdraw request action
 */
export const CreateWithdrawRequest = createAsyncThunk('/financehub/create-withdraw-request', async (parameters) => {
  try {
    const response = await axiosInstance.post(getCreateWithdrawRequestURL(), parameters);
    if (response.status === 200) {
      toast.success(response?.data?.message);
    }
    return response;
  } catch (error) {
    console.log(error);
    if (error?.response?.status === 400) {
      toast.error(error?.response?.data?.error?.message);
    }
    if (error?.response?.status === 404) {
      toast.error(error?.response?.data?.error?.message);
    }
    throw error;
  }
});

/**
 * update withdraw request status action
 */
export const UpdateWithdrawRequestStatus = createAsyncThunk(
  '/financehub/update-withdraw-request-status',
  async (id) => {
    try {
      const response = await axiosInstance.put(getUpdateWithdrawRequestStatusURL(id));
      if (response.status === 200) {
        toast.success(response.data.message);
      }
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

/**
 * reject withdraw request status action
 */
export const RejuctWithdrawRequestStatus = createAsyncThunk('/financehub/reject-withdraw-request', async (id) => {
  try {
    const response = await axiosInstance.put(getRejuctWithdrawRequestStatusURL(id));
    if (response.status === 200) {
      toast.success(response.data.message);
    }
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

/**
 * Define the slice using createSlice from @reduxjs/toolkit
 */
const financeHubSlice = createSlice({
  name: 'financeHub',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEarningList.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchEarningList.fulfilled, (state, action) => {
        state.earningList = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchEarningList.rejected, (state) => {
        state.isLoading = false;
        state.earningList = [];
      })

      .addCase(fetchWalletTransactionList.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchWalletTransactionList.fulfilled, (state, action) => {
        state.walletTransactionList = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchWalletTransactionList.rejected, (state) => {
        state.isLoading = false;
        state.walletTransactionList = [];
      })

      .addCase(fetchWalletBalanceList.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchWalletBalanceList.fulfilled, (state, action) => {
        state.walletBalanceList = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchWalletBalanceList.rejected, (state) => {
        state.isLoading = false;
        state.walletBalanceList = [];
      })

      .addCase(fetchWithdrawDetailsList.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchWithdrawDetailsList.fulfilled, (state, action) => {
        state.withdrawDetailsList = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchWithdrawDetailsList.rejected, (state) => {
        state.isLoading = false;
        state.withdrawDetailsList = [];
      })

      .addCase(fetchWithdrawDetailsUnpaidList.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchWithdrawDetailsUnpaidList.fulfilled, (state, action) => {
        state.withdrawDetailsUnpaidList = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchWithdrawDetailsUnpaidList.rejected, (state) => {
        state.isLoading = false;
        state.withdrawDetailsUnpaidList = [];
      })

      .addCase(fetchAllPackages.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchAllPackages.fulfilled, (state, action) => {
        state.viewAllPackages = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchAllPackages.rejected, (state) => {
        state.isLoading = false;
        state.viewAllPackages = [];
      })

      .addCase(fetchWithdrawDetailsPaidRejuctList.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchWithdrawDetailsPaidRejuctList.fulfilled, (state, action) => {
        state.withdrawDetailsPaidRejuctList = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchWithdrawDetailsPaidRejuctList.rejected, (state) => {
        state.isLoading = false;
        state.withdrawDetailsPaidRejuctList = [];
      })

      .addCase(fetchViewBalance.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchViewBalance.fulfilled, (state, action) => {
        state.viewBalanceDetails = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchViewBalance.rejected, (state) => {
        state.isLoading = false;
        state.viewBalanceDetails = [];
      })

      .addCase(fetchAllUserDashboard.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchAllUserDashboard.fulfilled, (state, action) => {
        state.viewAllUserDashboard = action.payload;
        state.isLoading = false;
      })

      .addCase(fetchAllUserDashboard.rejected, (state) => {
        state.isLoading = false;
        state.viewAllUserDashboard = [];
      })

      .addCase(CreateWithdrawRequest.pending, (state) => {
        state.isCreateLoading = true;
      })

      .addCase(CreateWithdrawRequest.fulfilled, (state, action) => {
        state.isCreateLoading = false;
      })

      .addCase(CreateWithdrawRequest.rejected, (state) => {
        state.isCreateLoading = false;
      })

      .addCase(UpdateWithdrawRequestStatus.pending, (state) => {
        state.updateWithdrawRequestisLoading = true;
      })

      .addCase(UpdateWithdrawRequestStatus.fulfilled, (state, action) => {
        state.updateWithdrawRequestisLoading = false;
      })

      .addCase(UpdateWithdrawRequestStatus.rejected, (state) => {
        state.updateWithdrawRequestisLoading = false;
      })

      .addCase(RejuctWithdrawRequestStatus.pending, (state) => {
        state.rejectWithdrawRequestisLoading = true;
      })

      .addCase(RejuctWithdrawRequestStatus.fulfilled, (state, action) => {
        state.rejectWithdrawRequestisLoading = false;
      })

      .addCase(RejuctWithdrawRequestStatus.rejected, (state) => {
        state.rejectWithdrawRequestisLoading = false;
      });
  },
});

export const getEarningList = (state) => state.financeHub.earningList;
export const getWalletTransactionList = (state) => state.financeHub.walletTransactionList;
export const getWalletBalanceList = (state) => state.financeHub.walletBalanceList;
export const getWithdrawDetailsList = (state) => state.financeHub.withdrawDetailsList;
export const getWithdrawDetailsUnpaidList = (state) => state.financeHub.withdrawDetailsUnpaidList;
export const getWithdrawDetailsPaidRejuctList = (state) => state.financeHub.withdrawDetailsPaidRejuctList;
export const getViewBalanceDetails = (state) => state.financeHub.viewBalanceDetails;
export const getViewAllPackagesList = (state) => state.financeHub.viewAllPackages;
export const getViewAllUserDashboard = (state) => state.financeHub.viewAllUserDashboard;
export const getLoadingState = (state) => state.financeHub.isLoading;
export const getCreateLoadingState = (state) => state.financeHub.isCreateLoading;
export const getUpdateWithdrawRequestLoadingState = (state) => state.financeHub.updateWithdrawRequestisLoading;
export const getRejectWithdrawRequestLoadingState = (state) => state.financeHub.rejectWithdrawRequestisLoading;

/**
 * Export the reducer
 */
export default financeHubSlice.reducer;
