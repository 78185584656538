import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MUIBox from '../../components/Common/MUIBox';
import MUIDivider from '../../components/Common/MUIDivider';
import MUITypography from '../../components/Common/MUITypography';
import MUIGrid from '../../components/Common/MUIGrid';
import MUITextField from '../../components/Common/MUITextField';
import MUIButton from '../../components/Common/MUIButton';
import withLoader from '../../components/HOC/withLoader';
import {
  CreateWithdrawRequest,
  fetchViewBalance,
  fetchWithdrawDetailsList,
  getCreateLoadingState,
  getViewBalanceDetails,
} from '../../Redux/Reducer/financehubReducer';
import MUICircularProgress from '../../components/Common/MUICircularProgress';

function WithdrawFund(props) {
  /**
   * props object
   */
  const { setLoading, limit, offset } = props;

  /**
   * hooks
   */
  const dispatch = useDispatch();

  /**
   * form values
   */
  const [inputValues, setInputValues] = useState({
    amount: '',
    withdraw_wallet_address: '',
  });

  /**
   * form errors
   */
  const [errors, setErrors] = useState({
    amount: '',
    withdraw_wallet_address: '',
  });

  /**
   * get loading state
   */
  const isCreateLoading = useSelector(getCreateLoadingState);

  useEffect(() => {
    setLoading(isCreateLoading);
  }, [isCreateLoading]);

  /**
   * get view balance
   */
  const viewBalance = useSelector(getViewBalanceDetails);

  const balance = viewBalance?.data?.map((item) => item?.balance);

  useEffect(() => {
    dispatch(fetchViewBalance());
  }, [dispatch]);

  /**
   * validation functions
   * @param {*} name
   * @param {*} value
   * @returns
   */
  const validateForm = (name, value) => {
    switch (name) {
      case 'amount':
        if (viewBalance?.data?.length === 0) {
          return '0 Balance';
        }
        if (!value || value.trim() === '') {
          return 'Amount is required';
        }
        if (!/^\d+(\.\d+)?$/.test(value)) {
          return 'Amount must be a valid number';
        }
        if (value < 10) {
          return 'Amount must be greater than 10';
        }
        if (balance && value > balance[0]) {
          return 'Amount cannot be greater than available balance';
        }
        return '';
      case 'withdraw_wallet_address':
        if (!value || value.trim() === '') {
          return 'Wallet address is required';
        }
        return '';
      default: {
        return '';
      }
    }
  };

  /**
   * Onchange event
   * @param {*} event
   */
  const handleOnChange = (event) => {
    const { name } = event.target;
    const value = event.target.value;

    setErrors({ ...errors, [name]: validateForm(name, value) });
    setInputValues({ ...inputValues, [name]: value });
  };

  /**
   * form submit event
   * @param {*} event
   * @returns
   */
  const onSubmit = (event) => {
    event.preventDefault();
    const validationErrors = {};
    Object.keys(inputValues).forEach((name) => {
      const error = validateForm(name, inputValues[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      setErrors({ ...errors, ...validationErrors });
      return;
    }

    dispatch(CreateWithdrawRequest(inputValues)).then((response) => {
      if (response.payload.status === 200) {
        setInputValues({
          amount: '',
          withdraw_wallet_address: '',
        });
        dispatch(fetchWithdrawDetailsList({ limit, offset }));
        dispatch(fetchViewBalance());
      }
    });
  };

  return (
    <MUIBox sx={{ border: '1px solid rgb(224, 224, 224)', p: 2, borderRadius: '5px', mb: 3 }}>
      <MUITypography variant="h4" gutterBottom>
        Withdraw Fund
      </MUITypography>
      <MUIDivider />
      <form autoComplete="off">
        <MUIGrid container spacing={3} pt={3}>
          <MUIGrid item xs={12} md={12} lg={12}>
            <MUIBox
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: 1,
                mb: 1,
              }}
            >
              <MUITypography variant="h6">Available Balance:</MUITypography>
              <MUITypography sx={{ marginLeft: 1 }}>
                {balance} {viewBalance?.data?.length === 0 && 0}
              </MUITypography>
            </MUIBox>
            <MUIBox>
              <MUITypography variant="subtitle1">Minimum withdraw amount is 10</MUITypography>
            </MUIBox>
          </MUIGrid>
          <MUIGrid item xs={12} md={12} lg={12}>
            <MUITypography variant="subtitle2">AMOUNT</MUITypography>
            <MUITextField
              fullWidth
              name="amount"
              placeholder="Amount"
              value={inputValues.amount}
              onChange={handleOnChange}
              error={!!errors.amount}
              errors={errors.amount && errors.amount}
            />
          </MUIGrid>
          <MUIGrid item xs={12} md={12} lg={12}>
            <MUITypography variant="subtitle2">WALLET ADDRESS</MUITypography>
            <MUITextField
              fullWidth
              name="withdraw_wallet_address"
              placeholder="Wallet Address"
              value={inputValues.withdraw_wallet_address}
              onChange={handleOnChange}
              error={!!errors.withdraw_wallet_address}
              errors={errors.withdraw_wallet_address && errors.withdraw_wallet_address}
            />
          </MUIGrid>
          <MUIGrid item xs={12} md={12} lg={12}>
            <MUIButton
              title={'Submit Request'}
              variant="contained"
              startIcon={isCreateLoading && <MUICircularProgress sx={{ color: 'white' }} size={20} />}
              onClick={onSubmit}
            />
          </MUIGrid>
        </MUIGrid>
      </form>
    </MUIBox>
  );
}

export default withLoader(WithdrawFund);
