import React from 'react';
import { TableRow } from '@mui/material';

function MUITableRow(props) {
  /**
   * props
   */
  const { children, ...rest } = props;

  return <TableRow {...rest}>{children}</TableRow>;
}

export default MUITableRow;
