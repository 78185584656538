import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { Chip, IconButton, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import MUIButton from '../../../components/Common/MUIButton';
import MUIDialog from '../../../components/Common/MUIDialog';
import MUIDialogActions from '../../../components/Common/MUIDialogActions';
import MUIDialogContent from '../../../components/Common/MUIDialogContent';
import MUIDialogTitle from '../../../components/Common/MUIDialogTitle';
import MUIDivider from '../../../components/Common/MUIDivider';
import MUIIconButton from '../../../components/Common/MUIIconButton';
import MUITypography from '../../../components/Common/MUITypography';
import MUIBox from '../../../components/Common/MUIBox';
import { fetchOneGeneology, getOneGeneology } from '../../../Redux/Reducer/levelsReducer';
import AddGenealogyUser from '../AddGenealogyUser/AddGenealogyUser';
import { fetchProfile, getProfileDetails } from '../../../Redux/Reducer/authReducer';

function GenealogyViewModel(props) {
  /**
   * props object
   */
  const { open, handleClose, selectedRow } = props;

  /**
   * hooks
   */
  const dispatch = useDispatch();

  /**
   * states
   */
  const [openAddMember, setOpenAddMember] = useState(false);
  const [selectedAddMember, setSelectedAddMember] = useState();
  const [expandedNodes, setExpandedNodes] = useState({});
  const [selectedId, setSelectedId] = useState();

  /**
   * get profile data
   */
  const getProfileData = useSelector(getProfileDetails)?.data;

  useEffect(() => {
    dispatch(fetchProfile());
  }, []);

  const viewOneGenealogy = useSelector(getOneGeneology)?.data;

  useEffect(() => {
    if (selectedRow) {
      dispatch(fetchOneGeneology(selectedRow?.id));
    }
  }, [dispatch, selectedRow?.id]);

  useEffect(() => {
    if (selectedId) {
      dispatch(fetchOneGeneology(selectedId));
    }
  }, [dispatch, selectedId]);

  const toggleNode = (nodeId) => {
    setExpandedNodes((prevState) => ({
      [nodeId]: !prevState[nodeId],
    }));
  };

  const handleGetUserId = (userId) => {
    setSelectedId(userId);
  };

  const handleClickClose = () => {
    handleClose();
  };

  const handleCopy = (data) => {
    navigator.clipboard.writeText(data);
    toast.success('User ID copied successfully!');
  };

  /**
   * handle click open
   * @param {*} node
   */
  const handleClickOpen = (node) => {
    setOpenAddMember(true);
    setSelectedAddMember(node);
  };

  /**
   * handle click close
   */
  const handleCloseAddMember = () => {
    setOpenAddMember(false);
  };

  const renderTreeView = (node) => {
    if (!node) return null;
    const nodeId = node.id;
    const parentId = node.parent_id;
    const isExpanded = expandedNodes[nodeId];
    const iconColor = node?.earning_status ? '#28A745' : '#F44336';

    return (
      <li key={nodeId} className="genealogy-node">
        <div className="node-header" role="button" tabIndex={0}>
          <div className="member-details">
            <Tooltip
              key={node.id}
              placement="bottom"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: '#5A72A0',
                    '& .MuiTooltip-arrow': {
                      color: 'common.black',
                    },
                  },
                },
              }}
              title={
                <>
                  <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                    <span style={{ fontWeight: 'bold' }}>User Id: </span>
                    {node?.unique_id}
                  </MUITypography>
                  <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                    <span style={{ fontWeight: 'bold' }}>Total own business: </span>
                    {node?.total_package_amount}
                  </MUITypography>
                  <MUIDivider sx={{ bgcolor: 'white' }} />
                  {node?.user_package?.map((item) => (
                    <>
                      <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                        <span style={{ fontWeight: 'bold' }}>Package amount: </span>
                        {item?.amount}
                      </MUITypography>
                      <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                        <span style={{ fontWeight: 'bold' }}>Package entry date: </span>
                        {item?.entry_date}
                      </MUITypography>
                      <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                        <span style={{ fontWeight: 'bold' }}>Package start date: </span>
                        {item?.earning_start_date}
                      </MUITypography>
                      <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                        <span style={{ fontWeight: 'bold' }}>Package end date: </span>
                        {item?.earning_end_date}
                      </MUITypography>
                      <MUIDivider sx={{ bgcolor: 'white' }} />
                    </>
                  ))}
                </>
              }
            >
              {node?.unique_id === getProfileData?.unique_id ? null : (
                <div>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleNode(parentId);
                      handleGetUserId(parentId);
                    }}
                  >
                    <ExpandLessIcon />
                  </IconButton>
                </div>
              )}

              <AccountCircleIcon sx={{ color: iconColor }} fontSize="large" />
              <div className="details-content">
                <p style={{ fontWeight: 'bold', color: '#003366', margin: 0, textTransform: 'capitalize' }}>
                  {node?.full_name}
                </p>
                <div className="copy-section" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={{ fontWeight: 'bold', color: iconColor, margin: 0 }}>{node?.unique_id}</p>
                  <Tooltip title="Copy">
                    <IconButton size="small" onClick={() => handleCopy(node?.unique_id)} style={{ marginLeft: 5 }}>
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
                <p style={{ fontWeight: 'bold', color: 'black', margin: 0 }}>Package: {node?.total_package_amount}</p>
                <Chip
                  size="small"
                  skin="light"
                  label={node?.is_active ? 'Active' : 'Inactive'}
                  sx={{
                    '& .MuiChip-label': { textTransform: 'capitalize' },
                    color: node?.is_active ? '#4CAF50' : '#F44336',
                    backgroundColor: node?.is_active ? '#E8F5E9' : '#FFEBEE',
                    cursor: 'context-menu',
                  }}
                />
              </div>
            </Tooltip>
            <ul>
              <li>
                <MUIButton
                  title="Add member"
                  startIcon={<AddIcon />}
                  onClick={() => handleClickOpen(node)}
                  style={{ marginLeft: 'auto' }}
                  variant="outlined"
                  size="small"
                />
              </li>
            </ul>
            {!node.children && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleNode(nodeId);
                  handleGetUserId(nodeId);
                }}
              >
                <IconButton
                  sx={{
                    backgroundColor: 'primary.main',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'primary.main',
                    },
                    borderRadius: '50%',
                    padding: '0px',
                  }}
                >
                  {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </IconButton>
            )}
          </div>
        </div>
        {node.children && node.children.length > 0 && <ul>{node.children.map((child) => renderTreeView(child))}</ul>}
      </li>
    );
  };

  return (
    <MUIDialog
      open={open}
      onClose={handleClickClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
      fullWidth
    >
      <MUIDialogTitle id="alert-dialog-title" size="small" sx={{ textAlign: 'center', position: 'relative' }}>
        Genealogy
        <MUIIconButton
          aria-label="close"
          onClick={handleClickClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </MUIIconButton>
      </MUIDialogTitle>
      <MUIDivider />
      <MUIDialogContent>
        <MUIBox>
          <MUIBox className="genealogy-body genealogy-scroll">
            <MUIBox className="genealogy-tree">
              {viewOneGenealogy?.data && <ul>{renderTreeView(viewOneGenealogy?.data[0] || {})}</ul>}
            </MUIBox>
          </MUIBox>
        </MUIBox>
      </MUIDialogContent>
      <MUIDivider />
      <MUIDialogActions>
        <MUIButton title="Cancel" variant="outlined" color="secondary" onClick={handleClickClose} />
      </MUIDialogActions>
      <AddGenealogyUser
        open={openAddMember}
        handleClose={handleCloseAddMember}
        selectedRow={selectedAddMember}
        setSelectedRow={setSelectedAddMember}
      />
    </MUIDialog>
  );
}

export default GenealogyViewModel;
