import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';
import { IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import MUIContainer from '../../components/Common/MUIContainer';
import TableHeader from '../../components/Common/TableHeader';
import withLoader from '../../components/HOC/withLoader';
import MUIDataGrid from '../../components/Common/MUIDataGrid';
import { getRoleInLocaleStorage, UserActions } from '../../utils/Helper';
import {
  fetchWithdrawDetailsUnpaidList,
  getLoadingState,
  getWithdrawDetailsUnpaidList,
  RejuctWithdrawRequestStatus,
  UpdateWithdrawRequestStatus,
} from '../../Redux/Reducer/financehubReducer';
import RowOptions from '../../components/action-popover';
import WithdrawRequestConfirm from '../WithdrawHistory/WithdrawRequestConfirm';
import DeleteConfirmation from '../../components/delete-popup/index';
import { fetchWithdrawRequestReport } from '../../Redux/Reducer/reportReducer';

function WithdrawRequests(props) {
  /**
   * props object
   */
  const { setLoading } = props;

  /**
   * states
   */
  const [searchValue, setSearchValue] = useState('');
  const [selectedData, setSelectedData] = useState('');
  const [openAdd, setOpenAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const search = searchValue?.length > 2 ? searchValue : '';

  const rolesArray = getRoleInLocaleStorage();
  const role = JSON.parse(rolesArray);

  /**
   * hooks
   */
  const dispatch = useDispatch();

  /**
   * get withdraw request
   */
  const withdrawDetailsList = useSelector(getWithdrawDetailsUnpaidList);
  const rowCount = withdrawDetailsList?.data?.count;

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  const paginationPageSize = paginationModel.pageSize;
  const paginationPage = paginationModel.page;

  const offset = paginationPage * paginationPageSize;
  const limit = paginationPageSize;

  useEffect(() => {
    dispatch(fetchWithdrawDetailsUnpaidList({ limit, offset, search }));
  }, [dispatch, limit, offset, search]);

  /**
   * get loading state
   */
  const isLoading = useSelector(getLoadingState);

  useEffect(() => {
    setLoading(isLoading);
  }, [setLoading, isLoading]);

  /**
   * clear filter
   */
  const handleClearInputValue = () => {
    setSearchValue('');
  };

  /**
   * get search value
   */
  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  /**
   * useractions
   * @param {*} data
   * @param {*} action
   */
  const handleAction = (data, action) => {
    switch (action) {
      case UserActions.ADD:
        handleWithdrawRequest(data);
        setOpenAdd(true);
        break;
      case UserActions.DELETE:
        handleRejuctWithdrawRequest(data);
        break;
      default:
        break;
    }
  };

  /**
   * handle epin delete
   * @param {*} data
   */
  const handleWithdrawRequest = (data) => {
    setSelectedData(data);
  };

  /**
   * handle epin delete
   * @param {*} data
   */
  const handleRejuctWithdrawRequest = (data) => {
    setSelectedData(data);
  };

  const handleAddWithdraw = (data) => {
    dispatch(UpdateWithdrawRequestStatus(data?.id)).then((res) => {
      if (res?.payload?.status) {
        handleCloseAdd();
        dispatch(fetchWithdrawDetailsUnpaidList({ limit, offset, search }));
      }
    });
  };

  /**
   * handle rejuct function
   * @param {*} id
   */
  const handleRejuctRequest = (data) => {
    dispatch(RejuctWithdrawRequestStatus(data?.id)).then((res) => {
      if (res?.payload?.status === 200) {
        handleCloseAdd();
        dispatch(fetchWithdrawDetailsUnpaidList({ limit, offset, search }));
      }
    });
  };

  const handleActions = (data, action) => {
    handleAction(data, action);
  };

  /**
   * handle click modal open function
   */
  const handleClickOpen = () => {
    setOpen(true);
  };
  /**
   * handle close
   */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * handle click modal open function
   */
  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };

  /**
   * handle close
   */
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  /**
   * handle copy function
   * @param {*} row
   */
  const handleCopy = (row) => {
    navigator.clipboard.writeText(row?.user_unique_id);
    toast.success('User ID copied successfully!');
  };

  const handleCopyAddress = (row) => {
    navigator.clipboard.writeText(row?.withdraw_wallet_address);
    toast.success('Wallet address copied successfully!');
  };

  const handleExport = () => {
    dispatch(fetchWithdrawRequestReport({ searchValue }));
  };

  /**
   * columns
   */
  const columns = [
    {
      flex: 1,
      minWidth: 100,
      field: 'user_unique_id',
      headerName: 'User Id',
      sortable: false,
      renderCell: ({ row }) => (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title={row?.user_unique_id}>
            <span>{row?.user_unique_id}</span>
          </Tooltip>
          <Tooltip title="Copy">
            <IconButton size="small" onClick={() => handleCopy(row)} style={{ marginLeft: 5 }}>
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </span>
      ),
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'user_first_name',
      headerName: 'First name',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.user_first_name}>
          <span style={{ textTransform: 'capitalize' }}>{row?.user_first_name}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'user_last_name',
      headerName: 'Last name',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.user_last_name}>
          <span style={{ textTransform: 'capitalize' }}>{row?.user_last_name}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'amount',
      headerName: 'Amount',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.amount}>
          <span>{row?.amount}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 50,
      field: 'admin_amount',
      headerName: 'Admin',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.admin_amount}>
          <span>{row?.admin_amount}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'user_amount',
      headerName: 'User amount',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.user_amount}>
          <span>{row?.user_amount}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'withdraw_wallet_address',
      headerName: 'Wallet address',
      sortable: false,
      renderCell: ({ row }) => (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title={row?.withdraw_wallet_address}>
            <span>{row?.withdraw_wallet_address}</span>
          </Tooltip>
          <Tooltip title="Copy">
            <IconButton size="small" onClick={() => handleCopyAddress(row)} style={{ marginLeft: 5 }}>
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </span>
      ),
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'request_status',
      headerName: 'Status',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.request_status}>
          <span style={{ textTransform: 'capitalize' }}>{row?.request_status}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'requested_date',
      headerName: 'Date',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.requested_date}>
          <span>{row?.requested_date}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'pay',
      headerName: 'Pay',
      sortable: false,
      renderCell: ({ row }) => (
        <RowOptions
          handleClickOpenAdd={handleClickOpenAdd}
          handleUserAction={handleActions}
          selectionModel={row}
          paybtn={role?.includes('Admin')}
          disabled={row?.request_status === 'Paid' || row?.request_status === 'Rejected'}
        />
      ),
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'rejuct',
      headerName: 'Reject',
      sortable: false,
      renderCell: ({ row }) => (
        <RowOptions
          handleClickOpen={handleClickOpen}
          handleUserAction={handleActions}
          selectionModel={row}
          rejuctbtn={role?.includes('Admin')}
          disabled={row?.request_status === 'Paid' || row?.request_status === 'Rejected'}
        />
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>ApexTrade | Withdraw Requests </title>
      </Helmet>
      <MUIContainer>
        <TableHeader
          muiTitle
          muiClearBtn
          muiTextField
          title="Withdraw Requests"
          textFieldLabel="Search user id"
          textFieldPlaceholder="Search user id"
          textFieldSize="small"
          buttonVariant="contained"
          onChange={handleChange}
          clearBtnTitle="Clear filter"
          clearBtnVariant="contained"
          clearOnClick={handleClearInputValue}
          clearBtnStartIcon={<ClearIcon />}
          clearDisabled={!searchValue?.length >= 1}
          searchValue={searchValue}
          showExportButton={role?.includes('Admin')}
          exportBtnTitle="Export"
          exportBtnVariant="outlined"
          handleExport={handleExport}
        />
        <MUIDataGrid
          rows={withdrawDetailsList?.data?.results || []}
          columns={columns}
          rowCount={rowCount}
          getRowId={(row) => row.id}
          onRowSelectionModelChange={undefined}
          selectionModel={undefined}
          columnHeaderHeight={60}
          page={paginationModel.page}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
        <WithdrawRequestConfirm
          title="Confirm Withdraw Request"
          contentText="Are you sure you want to Pay?"
          open={openAdd}
          handleClose={handleCloseAdd}
          handleAddPackage={handleAddWithdraw}
          selectedData={selectedData}
        />
        <DeleteConfirmation
          title="Reject Withdraw Request"
          contentText="Are you sure you want to Reject Withdraw Request?"
          selectedData={selectedData}
          handleDelete={handleRejuctRequest}
          open={open}
          handleClose={handleClose}
        />
      </MUIContainer>
    </>
  );
}

export default withLoader(WithdrawRequests);
