import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MUITextField from '../../../components/Common/MUITextField';
import MUICircularProgress from '../../../components/Common/MUICircularProgress';
import MUIButton from '../../../components/Common/MUIButton';
import withLoader from '../../../components/HOC/withLoader';
import InputPassword from '../../../components/Common/InputPassword';
import MUITypography from '../../../components/Common/MUITypography';
import { AddUser, getCreateLoadingState } from '../../../Redux/Reducer/userReducer';
import MUIDialog from '../../../components/Common/MUIDialog';
import MUIDialogTitle from '../../../components/Common/MUIDialogTitle';
import MUIDivider from '../../../components/Common/MUIDivider';
import MUIDialogContent from '../../../components/Common/MUIDialogContent';
import MUIGrid from '../../../components/Common/MUIGrid';
import MUIDialogActions from '../../../components/Common/MUIDialogActions';
import {
  fetchGeneologyList,
  fetchGeneologyViewList,
  fetchLoggedUserGeneologyList,
  fetchOneGeneology,
} from '../../../Redux/Reducer/levelsReducer';

function AddRoleModel(props) {
  /**
   * props
   */
  const { open, handleClose, selectedRow, setSelectedRow, setLoading } = props;

  /**
   * hooks
   */
  const dispatch = useDispatch();
  const location = useLocation();

  /**
   * states
   */
  const [isLoading, setIsLoading] = useState(false);

  /**
   * form fields
   */
  const [inputValues, setInputValues] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    sponsor_id: '',
    sponsor_name: '',
    email: '',
    password: '',
    confirm_password: '',
  });

  /**
   * form errors
   */
  const [errors, setErrors] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    sponsor_id: '',
    email: '',
    password: '',
    confirm_password: '',
  });

  /**
   * get loading state
   */
  const isCreateLoadingState = useSelector(getCreateLoadingState);

  useEffect(() => {
    setLoading(isCreateLoadingState);
    setIsLoading(isCreateLoadingState);
  }, [isCreateLoadingState]);

  useEffect(() => {
    if (selectedRow) {
      const newInputValues = {
        sponsor_id: selectedRow?.unique_id || '',
        sponsor_name: selectedRow?.full_name || '',
      };

      setInputValues((prevValues) => ({
        ...prevValues,
        ...newInputValues,
      }));

      setErrors((prevErrors) => ({
        ...prevErrors,
        sponsor_id: validateForm('sponsor_id', newInputValues.sponsor_id),
      }));
    }
  }, [selectedRow]);

  /**
   * Toggle open/close function
   */
  const handleCloseFun = () => {
    handleClose();

    inputValues.first_name = '';
    inputValues.middle_name = '';
    inputValues.last_name = '';
    inputValues.email = '';
    inputValues.password = '';
    inputValues.confirm_password = '';

    // ** clear form errors
    errors.first_name = '';
    errors.middle_name = '';
    errors.last_name = '';
    errors.email = '';
    errors.password = '';
    errors.confirm_password = '';

    setSelectedRow();
  };

  /**
   * Validation function
   * @param {*} name
   * @param {*} value
   * @returns
   */
  const validateForm = (name, value) => {
    switch (name) {
      case 'first_name':
        if (!value || value.trim() === '') {
          return 'First name is required';
        }
        return '';
      case 'middle_name':
        return '';
      case 'sponsor_id':
        if (!value) {
          return 'Sponsor id is required';
        }
        return '';
      case 'password':
        if (!value || value.trim() === '') {
          return 'Password is required';
        }
        return '';
      case 'confirm_password':
        if (!value || value.trim() === '') {
          return 'Confirm password is required';
        }
        if (inputValues.confirm_password && value !== inputValues.password) {
          return 'Password and Confirm Password does not match.';
        }
        return '';
      default: {
        return '';
      }
    }
  };

  /**
   * Onchange event
   * @param {*} event
   */
  const handleOnChange = (event) => {
    const { name } = event.target;
    const value = event.target.value;

    setErrors({ ...errors, [name]: validateForm(name, value) });
    setInputValues({ ...inputValues, [name]: value });
  };

  /**
   * form submit event
   * @param {*} event
   * @returns
   */
  const onSubmit = (event) => {
    event.preventDefault();
    const validationErrors = {};
    Object.keys(inputValues).forEach((name) => {
      const error = validateForm(name, inputValues[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      setErrors({ ...errors, ...validationErrors });
      return;
    }

    /**
     * Passed inputValues from add user action
     */
    const fields = {
      first_name: inputValues.first_name,
      middle_name: inputValues.middle_name,
      last_name: inputValues.last_name,
      sponsor_id: inputValues.sponsor_id,
      email: inputValues.email,
      password: inputValues.password,
      confirm_password: inputValues.confirm_password,
    };

    dispatch(AddUser(fields)).then((res) => {
      if (res?.payload?.status === 200) {
        handleCloseFun();
        if (location.pathname === '/genealogy') {
          dispatch(fetchGeneologyList());
        }
        if (location.pathname === '/my-genealogy-view') {
          dispatch(fetchGeneologyViewList());
        }
        if (location?.pathname === '/my-genealogy') {
          dispatch(fetchLoggedUserGeneologyList());
        }
        if (selectedRow?.id) {
          dispatch(fetchOneGeneology(selectedRow?.id));
        }
      }
    });
  };

  return (
    <MUIDialog
      open={open}
      onClose={handleCloseFun}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <MUIDialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }}>
        Add User
      </MUIDialogTitle>
      <MUIDivider />
      <MUIDialogContent>
        <MUIGrid container spacing={2}>
          <MUIGrid item xs={12} sm={12} md={4} lg={4} pb={1}>
            <MUITypography variant="subtitle2">First name</MUITypography>
            <MUITextField
              fullWidth
              name="first_name"
              placeholder="First name"
              value={inputValues.first_name}
              onChange={handleOnChange}
              error={!!errors.first_name}
              errors={errors.first_name && errors.first_name}
            />
          </MUIGrid>
          <MUIGrid item xs={12} sm={12} md={4} lg={4} pb={1}>
            <MUITypography variant="subtitle2">Middle name</MUITypography>
            <MUITextField
              fullWidth
              name="middle_name"
              placeholder="Middle name"
              value={inputValues.middle_name}
              onChange={handleOnChange}
              error={!!errors.middle_name}
              errors={errors.middle_name && errors.middle_name}
            />
          </MUIGrid>
          <MUIGrid item xs={12} sm={12} md={4} lg={4} pb={1}>
            <MUITypography variant="subtitle2">Last name</MUITypography>
            <MUITextField
              fullWidth
              name="last_name"
              placeholder="Last name"
              value={inputValues.last_name}
              onChange={handleOnChange}
              error={!!errors.last_name}
              errors={errors.last_name && errors.last_name}
            />
          </MUIGrid>
          <MUIGrid item xs={12} sm={12} md={6} lg={6} pb={1}>
            <MUITypography variant="subtitle2">Sponsor Id</MUITypography>
            <MUITextField
              fullWidth
              name="sponsor_id"
              placeholder="Sponsor Id"
              value={inputValues.sponsor_id}
              onChange={handleOnChange}
              error={!!errors.sponsor_id}
              errors={errors.sponsor_id && errors.sponsor_id}
              readOnly
            />
          </MUIGrid>
          <MUIGrid item xs={12} sm={12} md={6} lg={6} pb={1}>
            <MUITypography variant="subtitle2">Sponsor Name</MUITypography>
            <MUITextField
              fullWidth
              name="sponsor_name"
              placeholder="Sponsor Name"
              value={inputValues.sponsor_name}
              onChange={handleOnChange}
              readOnly
            />
          </MUIGrid>
          <MUIGrid item xs={12} sm={12} md={12} lg={12} pb={1}>
            <MUITypography variant="subtitle2">Email</MUITypography>
            <MUITextField
              fullWidth
              name="email"
              placeholder="Email"
              value={inputValues.email}
              onChange={handleOnChange}
              error={!!errors.email}
              errors={errors.email && errors.email}
            />
          </MUIGrid>
          <MUIGrid item xs={12} sm={12} md={6} lg={6} pb={1}>
            <MUITypography variant="subtitle2">Password</MUITypography>
            <InputPassword
              fullWidth
              name="password"
              placeholder="Password"
              value={inputValues.password}
              onChange={handleOnChange}
              error={!!errors.password}
              errors={errors.password && errors.password}
            />
          </MUIGrid>
          <MUIGrid item xs={12} sm={12} md={6} lg={6} pb={1}>
            <MUITypography variant="subtitle2">Confirm password</MUITypography>
            <InputPassword
              fullWidth
              name="confirm_password"
              placeholder="Confirm password"
              value={inputValues.confirm_password}
              onChange={handleOnChange}
              error={!!errors.confirm_password}
              errors={errors.confirm_password && errors.confirm_password}
            />
          </MUIGrid>
        </MUIGrid>
      </MUIDialogContent>
      <MUIDivider />
      <MUIDialogActions>
        <>
          <MUIButton title={'Cancel'} variant="outlined" onClick={handleCloseFun} />
          <MUIButton
            variant="contained"
            title={'Submit'}
            onClick={onSubmit}
            startIcon={isLoading && <MUICircularProgress sx={{ color: 'white' }} size={20} />}
          />
        </>
      </MUIDialogActions>
    </MUIDialog>
  );
}

export default withLoader(AddRoleModel);
