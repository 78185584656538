import React, { useState, useEffect } from 'react';
import { Fab } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useLocation } from 'react-router-dom';
import MUIBox from '../Common/MUIBox';

export default function ScrollToTop() {
  /**
   * states for visible buttons
   */
  const [visible, setVisible] = useState(false);

  /**
   * hooks
   */
  const { pathname } = useLocation();

  /**
   * toggle visibility of buttons
   */
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  /**
   * after click arrow up scroll to top
   */
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return (
    <div>
      {visible && (
        <MUIBox sx={{ pt: 1 }}>
          <Fab
            sx={{
              position: 'fixed',
              bottom: '2rem',
              right: '2rem',
              backgroundColor: '#0A74DA',
              color: 'white',
              zIndex: 11,
            }}
            onClick={scrollToTop}
            color="primary"
            aria-label="edit"
            size="small"
          >
            <ArrowUpwardIcon />
          </Fab>
        </MUIBox>
      )}
    </div>
  );
}
