import React from 'react';
import { TableBody } from '@mui/material';

function MUITableBody(props) {
  /**
   * props
   */
  const { children, ...rest } = props;

  return <TableBody {...rest}>{children}</TableBody>;
}

export default MUITableBody;
