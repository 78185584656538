import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import MUIBox from '../../components/Common/MUIBox';
import DrawerComponent from '../../components/drawer-component';
import withLoader from '../../components/HOC/withLoader';
import MUIFormControl from '../../components/Common/MUIFormControl';
import MUITypography from '../../components/Common/MUITypography';
import InputPassword from '../../components/Common/InputPassword';
import MUICircularProgress from '../../components/Common/MUICircularProgress';
import MUIButton from '../../components/Common/MUIButton';
import { ChangeUserPassword, getChangeUserPasswordLoadingState } from '../../Redux/Reducer/userReducer';
import { lowercaseTextRegex, numericValueRegex, specialSymbolRegex, uppercaseTextRegex } from '../../utils/Helper';

function ChangePasswordDrawer(props) {
  /**
   * props object
   */
  const { open, handleClose, selectedRow, setLoading } = props;

  /**
   * hooks
   */
  const dispatch = useDispatch();

  /**
   * media query
   */
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  /**
   * form values
   */
  const [inputValues, setInputValues] = useState({
    password: '',
    confirm_password: '',
  });

  /**
   * form errors
   */
  const [errors, setErrors] = useState({
    password: '',
    confirm_password: '',
  });

  /**
   * get loading state
   */
  const isLoading = useSelector(getChangeUserPasswordLoadingState);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  /**
   * handle close function
   */
  const handleCloseDrawer = () => {
    handleClose();

    inputValues.password = '';
    inputValues.confirm_password = '';

    errors.password = '';
    errors.confirm_password = '';
  };

  /**
   * validation function
   * @param {*} name
   * @param {*} value
   * @returns
   */
  const validateForm = (name, value) => {
    switch (name) {
      case 'password':
        if (!value || value.trim() === '') {
          return 'Password is required';
        }
        if (!uppercaseTextRegex.test(value)) {
          return 'Password must contain at least one uppercase letter';
        }
        if (!lowercaseTextRegex.test(value)) {
          return 'Password must contain at least one lowercase letter';
        }
        if (!specialSymbolRegex.test(value)) {
          return 'Password must contain at least one special symbol, such as @, #, $, etc.';
        }
        if (!numericValueRegex.test(value)) {
          return 'Password must contain at least one numeric digit';
        }
        if (value.length < 8) {
          return 'Password length minimum 8 character';
        }
        return '';
      case 'confirm_password':
        if (!value || value.trim() === '') {
          return 'Confirm password is required';
        }
        if (inputValues.confirm_password && value !== inputValues.password) {
          return 'Password and Confirm Password does not match.';
        }
        return '';
      default:
        return '';
    }
  };

  /**
   * handle change event
   * @param {*} event
   */
  const handleOnChange = (event) => {
    const { name, value } = event.target;

    setErrors({ ...errors, [name]: validateForm(name, value) });
    setInputValues({ ...inputValues, [name]: value });
  };

  /**
   * handle submit function
   * @param {*} event
   * @returns
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = {};
    Object.keys(inputValues).forEach((name) => {
      const error = validateForm(name, inputValues[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      setErrors({ ...errors, ...validationErrors });
      return;
    }

    /**
     * dispatch change password form values
     */
    const param = {
      selectedUserId: selectedRow?.user_unique_id,
      parameters: inputValues,
    };
    dispatch(ChangeUserPassword(param)).then((res) => {
      if (res?.payload?.status === 200) {
        handleCloseDrawer();
      }
    });
  };

  return (
    <DrawerComponent
      open={open}
      anchor="right"
      variant="temporary"
      sx={{ '& .MuiDrawer-paper': { width: { xs: 300, sm: 400 } } }}
      onClose={handleCloseDrawer}
      title={'Change Password'}
      drawerBoxForm={
        <MUIBox sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100vh' }}>
          <form autoComplete="off">
            <MUIBox fullWidth sx={{ mb: 2, display: 'flex' }}>
              <MUITypography variant="subtitle1">User Id:</MUITypography>
              <MUITypography variant="subtitle1" sx={{ ml: 1 }}>
                {selectedRow?.user_unique_id || ''}
              </MUITypography>
            </MUIBox>
            <MUIBox fullWidth sx={{ mb: 2, display: 'flex' }}>
              <MUITypography variant="subtitle1">User name:</MUITypography>
              <MUITypography
                variant="subtitle1"
                sx={{ ml: 1, textTransform: 'capitalize' }}
              >{`${selectedRow?.user_first_name} ${selectedRow?.user_last_name}`}</MUITypography>
            </MUIBox>
            <MUIFormControl fullWidth sx={{ mb: 2 }}>
              <MUITypography variant="subtitle2">Password</MUITypography>
              <InputPassword
                fullWidth
                placeholder="New password"
                variant="outlined"
                name="password"
                value={inputValues.password}
                onChange={handleOnChange}
                error={!!errors.password}
                errors={errors.password && errors.password}
              />
            </MUIFormControl>
            <MUIFormControl fullWidth sx={{ mb: 2 }}>
              <MUITypography variant="subtitle2">Confirm password</MUITypography>
              <InputPassword
                fullWidth
                placeholder="Confirm password"
                variant="outlined"
                name="confirm_password"
                value={inputValues.confirm_password}
                onChange={handleOnChange}
                error={!!errors.confirm_password}
                errors={errors.confirm_password && errors.confirm_password}
              />
            </MUIFormControl>
            <MUIBox sx={{ pr: 3, pb: 3 }}>
              <MUIBox>
                <>
                  <MUIButton
                    sx={{ mr: 3 }}
                    variant="contained"
                    title={'Change Password'}
                    onClick={handleSubmit}
                    size={isSmallScreen ? 'small' : 'medium'}
                    startIcon={isLoading && <MUICircularProgress sx={{ color: 'white' }} size={20} />}
                  />
                  <MUIButton
                    title={'Cancel'}
                    variant="outlined"
                    onClick={handleCloseDrawer}
                    size={isSmallScreen ? 'small' : 'medium'}
                  />
                </>
              </MUIBox>
            </MUIBox>
          </form>
        </MUIBox>
      }
    />
  );
}

export default withLoader(ChangePasswordDrawer);
