import { configureStore, combineReducers } from '@reduxjs/toolkit';
import userReducer from '../Reducer/userReducer';
import authReducer from '../Reducer/authReducer';
import levelsReducer from '../Reducer/levelsReducer';
import epinReducer from '../Reducer/epinReducer';
import financehubReducer from '../Reducer/financehubReducer';
import reportReducer from '../Reducer/reportReducer';

/**
 * Combine all reducers files
 */
const rootReducer = combineReducers({
  auth: authReducer,
  userList: userReducer,
  hierarchy: levelsReducer,
  epin: epinReducer,
  financeHub: financehubReducer,
  report: reportReducer,
});

/**
 * Create a sotre and pass rootReducer
 */
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
