import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MUITextField from '../../components/Common/MUITextField';
import MUIButton from '../../components/Common/MUIButton';
import { UpdateProfile, fetchProfile, getUpdateProfileLoadingState } from '../../Redux/Reducer/authReducer';
import MUICircularProgress from '../../components/Common/MUICircularProgress';
import MUIGrid from '../../components/Common/MUIGrid';
import MUIDialog from '../../components/Common/MUIDialog';
import MUIDialogTitle from '../../components/Common/MUIDialogTitle';
import MUIDivider from '../../components/Common/MUIDivider';
import MUIDialogContent from '../../components/Common/MUIDialogContent';
import MUIDialogActions from '../../components/Common/MUIDialogActions';
import { isValidEmail, testNumber } from '../../utils/Helper';

function UpdateProfileModal(props) {
  /**
   * Props
   */
  const { open, handleClose, selectedRow } = props;

  /**
   * hooks
   */
  const dispatch = useDispatch();

  /**
   * get loading state
   */
  const isLoading = useSelector(getUpdateProfileLoadingState);

  /**
   * form fields
   */
  const [inputValues, setInputValues] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
  });

  /**
   * form errors
   */
  const [errors, setErrors] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
  });

  /**
   * show selected row data in textfield
   */
  useEffect(() => {
    if (selectedRow) {
      const newInputValues = {
        first_name: selectedRow?.first_name || '',
        middle_name: selectedRow?.middle_name || '',
        last_name: selectedRow?.last_name || '',
        email: selectedRow?.email || '',
      };
      setInputValues(newInputValues);
    }
  }, [selectedRow]);

  /**
   * validation function
   * @param {*} name
   * @param {*} value
   * @returns
   */
  const validateForm = (name, value) => {
    switch (name) {
      case 'first_name':
        if (!value || value.trim() === '') {
          return 'First name is required';
        }
        if (testNumber(value)) {
          return 'First name cannot contain numbers';
        }
        return '';
      case 'middle_name':
        if (testNumber(value)) {
          return 'Middle name cannot contain numbers';
        }
        return '';
      case 'last_name':
        if (!value || value.trim() === '') {
          return 'Last name is required';
        }
        if (testNumber(value)) {
          return 'Last name cannot contain numbers';
        }
        return '';
      case 'email':
        if (!value || value.trim() === '') {
          return 'Email is required';
        }
        if (!isValidEmail(value)) {
          return 'Enter valid email address';
        }
        return '';
      default: {
        return '';
      }
    }
  };

  /**
   * handleClose
   */
  const handleCloseModal = () => {
    handleClose();
  };

  /**
   * Onchange event
   * @param {*} event
   */
  const handleOnChange = (event) => {
    const { name } = event.target;
    const value = event.target.value;

    setErrors({ ...errors, [name]: validateForm(name, value) });
    setInputValues({ ...inputValues, [name]: value });
  };

  /**
   * handle submit function
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = {};
    Object.keys(inputValues).forEach((name) => {
      const error = validateForm(name, inputValues[name]);
      if (error && error.length > 0) {
        validationErrors[name] = error;
      }
    });
    if (Object.keys(validationErrors).length > 0) {
      setErrors({ ...errors, ...validationErrors });
      return;
    }

    const formData = new FormData();
    formData.append('first_name', inputValues.first_name);
    formData.append('middle_name', inputValues.middle_name);
    formData.append('last_name', inputValues.last_name);
    formData.append('email', inputValues.email);
    dispatch(UpdateProfile(formData)).then((res) => {
      if (res?.payload?.status === 200) {
        handleCloseModal();
        dispatch(fetchProfile());
      }
    });
  };

  return (
    <>
      <MUIDialog
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <MUIDialogTitle id="alert-dialog-title" sx={{ textAlign: 'center' }}>
          Update Profile
        </MUIDialogTitle>
        <MUIDivider />
        <MUIDialogContent>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <MUIGrid container spacing={2}>
              <MUIGrid item xs={12} sm={12}>
                <MUITextField
                  fullWidth
                  label="First name"
                  placeholder="First name"
                  variant="outlined"
                  name="first_name"
                  value={inputValues.first_name}
                  onChange={handleOnChange}
                  error={!!errors.first_name}
                  errors={errors.first_name && errors.first_name}
                />
              </MUIGrid>
              <MUIGrid item xs={12} sm={12}>
                <MUITextField
                  fullWidth
                  label="Middle name"
                  placeholder="Middle name"
                  variant="outlined"
                  name="middle_name"
                  value={inputValues.middle_name}
                  onChange={handleOnChange}
                  error={!!errors.middle_name}
                  errors={errors.middle_name && errors.middle_name}
                />
              </MUIGrid>
              <MUIGrid item xs={12} sm={12}>
                <MUITextField
                  fullWidth
                  label="Last name"
                  placeholder="Last name"
                  variant="outlined"
                  name="last_name"
                  value={inputValues.last_name}
                  onChange={handleOnChange}
                  error={!!errors.last_name}
                  errors={errors.last_name && errors.last_name}
                />
              </MUIGrid>
              <MUIGrid item xs={12} sm={12}>
                <MUITextField
                  fullWidth
                  label="Email"
                  placeholder="Email"
                  variant="outlined"
                  name="email"
                  value={inputValues.email}
                  onChange={handleOnChange}
                  error={!!errors.email}
                  errors={errors.email && errors.email}
                />
              </MUIGrid>
            </MUIGrid>
          </form>
        </MUIDialogContent>
        <MUIDivider />
        <MUIDialogActions>
          <MUIButton sx={{ float: 'right' }} variant="outlined" title="Cancel" onClick={handleCloseModal} />
          <MUIButton
            sx={{ float: 'right' }}
            variant="contained"
            onClick={handleSubmit}
            title={isLoading ? 'Loading...' : 'Update'}
            type="submit"
            startIcon={isLoading && <MUICircularProgress sx={{ color: 'white' }} size={20} />}
          />
        </MUIDialogActions>
      </MUIDialog>
    </>
  );
}

export default UpdateProfileModal;
