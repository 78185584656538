import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getAccessToken, getRoleInLocaleStorage } from '../utils/Helper';

function AuthRoute() {
  /**
   * get access token in local storage
   */
  const token = getAccessToken();

  /**
   * get role in local storage
   */

  const rolesArray = getRoleInLocaleStorage();
  const role = JSON.parse(rolesArray);

  if (!token) {
    return <Outlet />;
  }

  /**
   * If the user is already authenticated (has a token), redirect to the dashboard or any other protected route.
   */
  return <Navigate to={role?.includes('Admin') ? '/admin-dashboard' : '/dashboard'} replace />;
}

export default AuthRoute;
