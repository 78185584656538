import React from 'react';
import { DialogContent } from '@mui/material';

function MUIDialogContent(props) {
  /**
   * props
   */
  const { children, ...rest } = props;

  return <DialogContent {...rest}>{children}</DialogContent>;
}

export default MUIDialogContent;
