import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteUser,
  fetchUserList,
  getDeleteLoadingState,
  getLoadingState,
  getUserList,
} from '../../Redux/Reducer/userReducer';
import { getRoleInLocaleStorage, UserActions } from '../../utils/Helper';
import Iconify from '../../components/iconify';
import RowOptions from '../../components/action-popover/index';
import withLoader from '../../components/HOC/withLoader';
import DeleteConfirmation from '../../components/delete-popup/index';
import MUIDataGrid from '../../components/Common/MUIDataGrid';
import MUITextField from '../../components/Common/MUITextField';
import MUITypography from '../../components/Common/MUITypography';
import MUIContainer from '../../components/Common/MUIContainer';
import MUIBox from '../../components/Common/MUIBox';
import TableHeader from '../../components/Common/TableHeader';
import AddUserDrawer from './AddUserDrawer';
import ChangePasswordDrawer from './ChangePasswordDrawer';

function UserList(props) {
  /**
   * props
   */
  const { setLoading } = props;

  /**
   * hooks
   */
  const dispatch = useDispatch();

  /**
   * states
   */
  const [selectedRow, setSelectedRow] = useState();
  const [open, setOpen] = useState(false);
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);

  const [selectedUser, setSelectedUser] = useState();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const [userAction, setUserAction] = useState(UserActions.ADD);

  /**
   * filter row field
   */
  const [inputValues, setInputValues] = useState({
    unique_id: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    parent_sponsor_id: '',
  });

  const uniqueUserId = inputValues?.unique_id?.length > 2 ? inputValues?.unique_id : '';
  const firstName = inputValues?.first_name?.length > 2 ? inputValues?.first_name : '';
  const middleName = inputValues?.middle_name?.length > 2 ? inputValues?.middle_name : '';
  const lastName = inputValues?.last_name?.length > 2 ? inputValues?.last_name : '';
  const userEmail = inputValues?.email?.length > 2 ? inputValues?.email : '';
  const parentSponsorid = inputValues?.parent_sponsor_id?.length > 2 ? inputValues?.parent_sponsor_id : '';

  const rolesArray = getRoleInLocaleStorage();
  const role = JSON.parse(rolesArray);

  /**
   * get user list and implement pagination
   */
  const userList = useSelector(getUserList);

  const rowCount = userList?.data?.count;

  const paginationPageSize = paginationModel.pageSize;
  const paginationPage = paginationModel.page;

  const offset = paginationPage * paginationPageSize;
  const limit = paginationPageSize;

  useEffect(() => {
    dispatch(
      fetchUserList({
        limit,
        offset,
        firstName,
        middleName,
        lastName,
        userEmail,
        parentSponsorid,
        uniqueId: uniqueUserId,
      })
    );
  }, [dispatch, limit, offset, firstName, middleName, lastName, userEmail, parentSponsorid, uniqueUserId]);

  /**
   * get loading state
   */
  const isLoading = useSelector(getLoadingState);
  const deleteLoadingState = useSelector(getDeleteLoadingState);

  useEffect(() => {
    setLoading(isLoading);
  }, [setLoading, isLoading]);

  /**
   * handle click modal open function
   */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /**
   * handle click modal close function
   */
  const handleClose = () => {
    setOpen(false);
  };

  const handleUserDeletion = (data) => {
    setSelectedUser(data);
  };

  /**
   * handle user actions
   * @param {*} data
   * @param {*} action
   */
  const handleAction = (data, action) => {
    switch (action) {
      case UserActions.SHOW_DETAILS:
        setOpenAddUser(true);
        setSelectedRow(data);
        setUserAction(UserActions.SHOW_DETAILS);
        break;
      case UserActions.ADD:
        setOpenAddUser(true);
        setUserAction(UserActions.ADD);
        break;
      case UserActions.ADDMEMBER:
        setOpenAddUser(true);
        setSelectedRow(data);
        setUserAction(UserActions.ADDMEMBER);
        break;
      case UserActions.EDIT:
        setOpenAddUser(true);
        setSelectedRow(data);
        setUserAction(UserActions.EDIT);
        break;
      case UserActions.CHANGE_PASSWORD:
        setOpenChangePassword(true);
        setSelectedRow(data);
        setUserAction(UserActions.CHANGE_PASSWORD);
        break;
      case UserActions.DELETE:
        handleUserDeletion(data);
        break;
      default:
        break;
    }
  };

  const handleActions = (data, action) => {
    handleAction(data, action);
  };

  /**
   * pass id in deleteUser action
   * @param {*} id
   */
  const handleDeleteUser = (selectedData) => {
    dispatch(deleteUser(selectedData?.user)).then(() => {
      dispatch(
        fetchUserList({
          limit,
          offset,
          firstName,
          middleName,
          lastName,
          userEmail,
          parentSponsorid,
          uniqueId: uniqueUserId,
        })
      );
    });
  };

  const handleAddUser = () => {
    handleAction(null, UserActions.ADD);
  };

  /**
   * get search value
   */
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  /**
   * handle Clear Input Value
   */
  const handleClearInputValue = () => {
    setInputValues({
      first_name: '',
      middle_name: '',
      last_name: '',
      email: '',
      unique_id: '',
      parent_sponsor_id: '',
    });
  };

  /**
   * toggle add user open
   * @param {*} value
   */
  const toggleAddUserDrawer = (value) => {
    setOpenAddUser(value);
  };

  /**
   * toggle change password open
   * @param {*} value
   */
  const toggleChangePasswordDrawer = (value) => {
    setOpenChangePassword(value);
  };

  /**
   * handle copy function
   * @param {*} row
   */
  const handleCopy = (row) => {
    navigator.clipboard.writeText(row?.user_unique_id);
    toast.success('User ID copied successfully!');
  };

  /**
   * handle copy function
   * @param {*} row
   */
  const handleCopySponsorId = (row) => {
    navigator.clipboard.writeText(row?.parent_sponsor_id);
    toast.success('Sponsor ID copied successfully!');
  };

  /**
   * datagrid columns
   */
  const columns = [
    {
      flex: 1,
      minWidth: 120,
      field: 'user_unique_id',
      headerName: 'ID',
      sortable: false,
      renderHeader: () => (
        <MUIBox
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <MUITypography fontSize={14} fontWeight="fontWeightMedium">
            ID
          </MUITypography>
          <MUITextField
            size="small"
            placeholder="User ID"
            name="unique_id"
            value={inputValues.unique_id}
            onChange={handleChange}
          />
        </MUIBox>
      ),
      renderCell: ({ row }) => (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title={row?.user_unique_id}>
            <span>{row?.user_unique_id}</span>
          </Tooltip>
          <Tooltip title="Copy">
            <IconButton size="small" onClick={() => handleCopy(row)} style={{ marginLeft: 5 }}>
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </span>
      ),
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'user_first_name',
      headerName: 'First name',
      sortable: false,
      renderHeader: () => (
        <MUIBox
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <MUITypography fontSize={14} fontWeight="fontWeightMedium">
            First name
          </MUITypography>
          <MUITextField
            size="small"
            placeholder="First name"
            name="first_name"
            value={inputValues.first_name}
            onChange={handleChange}
          />
        </MUIBox>
      ),
      renderCell: ({ row }) => (
        <Tooltip title={row?.user_first_name}>
          <span style={{ textTransform: 'capitalize' }}>{row?.user_first_name}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'user_last_name',
      headerName: 'Last name',
      sortable: false,
      renderHeader: () => (
        <MUIBox
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <MUITypography fontSize={14} fontWeight="fontWeightMedium">
            Last name
          </MUITypography>
          <MUITextField
            size="small"
            placeholder="Last name"
            name="last_name"
            value={inputValues.last_name}
            onChange={handleChange}
          />
        </MUIBox>
      ),
      renderCell: ({ row }) => (
        <Tooltip title={row?.user_last_name}>
          <span style={{ textTransform: 'capitalize' }}>{row?.user_last_name}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'user_email',
      headerName: 'Email',
      sortable: false,
      renderHeader: () => (
        <MUIBox
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <MUITypography fontSize={14} fontWeight="fontWeightMedium">
            Email
          </MUITypography>
          <MUITextField
            size="small"
            placeholder="Email"
            name="email"
            value={inputValues.email}
            onChange={handleChange}
          />
        </MUIBox>
      ),
      renderCell: ({ row }) => (
        <Tooltip title={row?.user_email}>
          <span>{row?.user_email}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'package',
      headerName: 'Package',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.package}>
          <span>{row?.package}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'total_earning',
      headerName: 'Total earning',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.total_earning}>
          <span>{row?.total_earning}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 80,
      field: 'total_paid',
      headerName: 'Total paid',
      sortable: false,
      renderCell: ({ row }) => (
        <Tooltip title={row?.total_paid}>
          <span>{row?.total_paid}</span>
        </Tooltip>
      ),
    },
    {
      flex: 1,
      minWidth: 120,
      field: 'parent_sponsor_id',
      headerName: 'Sponsor id',
      sortable: false,
      renderHeader: () => (
        <MUIBox
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <MUITypography fontSize={14} fontWeight="fontWeightMedium">
            Sponsor id
          </MUITypography>
          <MUITextField
            size="small"
            placeholder="Sponsor id"
            name="parent_sponsor_id"
            value={inputValues.parent_sponsor_id}
            onChange={handleChange}
          />
        </MUIBox>
      ),
      renderCell: ({ row }) => (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title={row?.parent_sponsor_id}>
            <span>{row?.parent_sponsor_id}</span>
          </Tooltip>
          <Tooltip title="Copy">
            <IconButton size="small" onClick={() => handleCopySponsorId(row)} style={{ marginLeft: 5 }}>
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </span>
      ),
    },
    {
      flex: 1,
      minWidth: 150,
      sortable: false,
      field: 'actions',
      headerName: 'Actions',
      renderCell: ({ row }) => (
        <RowOptions
          handleClickOpen={handleClickOpen}
          handleUserAction={handleActions}
          selectionModel={row}
          viewIcon
          editIcon={role?.includes('Admin')}
          deleteIcon={role?.includes('Admin') && row?.child_count === 0}
          addNewMemberIcon={role?.includes('Admin')}
          changePasswordIcon={role?.includes('Admin')}
        />
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>ApexTrade | User </title>
      </Helmet>
      <MUIContainer>
        <TableHeader
          muiTitle
          muiAddBtn
          muiClearBtn
          title="User List"
          clearBtnTitle="Clear filter"
          clearBtnVariant="contained"
          buttonTitle="New User"
          buttonVariant="contained"
          buttonStartIcon={<Iconify icon="eva:plus-fill" />}
          clearOnClick={handleClearInputValue}
          handleAdd={handleAddUser}
          clearDisabled={
            !(
              inputValues?.first_name?.length >= 1 ||
              inputValues?.middle_name?.length >= 1 ||
              inputValues?.last_name?.length >= 1 ||
              inputValues?.email?.length >= 1 ||
              inputValues?.unique_id?.length >= 1 ||
              inputValues?.parent_sponsor_id?.length >= 1
            )
          }
          clearBtnStartIcon={<ClearIcon />}
        />
        <MUIDataGrid
          rows={userList?.data?.results || []}
          columns={columns}
          rowCount={rowCount}
          page={paginationModel.page}
          getRowId={(row) => row.id}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          onRowSelectionModelChange={undefined}
          selectionModel={undefined}
          columnHeaderHeight={80}
        />
        <DeleteConfirmation
          title="Delete User"
          contentText="Are you sure you want to delete User?"
          isLoading={deleteLoadingState}
          selectedData={selectedUser}
          handleDelete={handleDeleteUser}
          open={open}
          handleClose={handleClose}
        />
        <AddUserDrawer
          handleClose={toggleAddUserDrawer}
          open={openAddUser}
          userAction={userAction}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          offset={offset}
          limit={limit}
          firstName={firstName}
          middleName={middleName}
          lastName={lastName}
          userEmail={userEmail}
          parentSponsorid={parentSponsorid}
        />
        <ChangePasswordDrawer
          handleClose={toggleChangePasswordDrawer}
          open={openChangePassword}
          userAction={userAction}
          selectedRow={selectedRow}
        />
      </MUIContainer>
    </>
  );
}

UserList.propTypes = {
  setLoading: PropTypes.func.isRequired,
};

export default withLoader(UserList);
