import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Chip, IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useDispatch, useSelector } from 'react-redux';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MUIBox from '../../../components/Common/MUIBox';
import MUIContainer from '../../../components/Common/MUIContainer';
import TableHeader from '../../../components/Common/TableHeader';
import { fetchGeneologyViewList, getGeneologyViewList, getLoadingState } from '../../../Redux/Reducer/levelsReducer';
import withLoader from '../../../components/HOC/withLoader';
import '../Genealogy/Style.css';
import MUIButton from '../../../components/Common/MUIButton';
import AddGenealogyUser from '../AddGenealogyUser/AddGenealogyUser';
import MUITypography from '../../../components/Common/MUITypography';
import MUIDivider from '../../../components/Common/MUIDivider';
import GenealogyViewModel from './GenealogyViewModel';

const MyGenealogyView = (props) => {
  const { setLoading } = props;

  const [open, setOpen] = useState(false);
  const [openGenealogy, setOpenGenealogy] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const dispatch = useDispatch();
  const geneology = useSelector(getGeneologyViewList);
  const isLoading = useSelector(getLoadingState);

  useEffect(() => {
    dispatch(fetchGeneologyViewList());
  }, [dispatch]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  /**
   * handle click open
   * @param {*} node
   */
  const handleClickOpen = (node) => {
    setOpen(true);
    setSelectedRow(node);
  };

  /**
   * handle click close
   */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * handle click open genealogy
   * @param {*} node
   */
  const handleClickOpenGenealogy = (node) => {
    setOpenGenealogy(true);
    setSelectedRow(node);
  };

  /**
   * handle close genealogy
   */
  const handleCloseGenealogy = () => {
    setOpenGenealogy(false);
  };

  const handleCopy = (data) => {
    navigator.clipboard.writeText(data);
    toast.success('User ID copied successfully!');
  };

  const renderTree = (node) => {
    if (!node) return null;
    const nodeId = node.id;
    const iconColor = node?.earning_status ? '#28A745' : '#F44336';

    return (
      <li key={nodeId} className="genealogy-node">
        <div className="node-header" role="button" tabIndex={0}>
          <div className="member-details">
            <Tooltip
              key={node.id}
              placement="bottom"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: '#5A72A0',
                    '& .MuiTooltip-arrow': {
                      color: 'common.black',
                    },
                  },
                },
              }}
              title={
                <>
                  <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                    <span style={{ fontWeight: 'bold' }}>User Id: </span>
                    {node?.unique_id}
                  </MUITypography>
                  <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                    <span style={{ fontWeight: 'bold' }}>Total own business: </span>
                    {node?.total_package_amount}
                  </MUITypography>
                  <MUIDivider sx={{ bgcolor: 'white' }} />
                  {node?.user_package?.map((item, index) => (
                    <React.Fragment key={index}>
                      <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                        <span style={{ fontWeight: 'bold' }}>Package amount: </span>
                        {item?.amount}
                      </MUITypography>
                      <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                        <span style={{ fontWeight: 'bold' }}>Package entry date: </span>
                        {item?.entry_date}
                      </MUITypography>
                      <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                        <span style={{ fontWeight: 'bold' }}>Package start date: </span>
                        {item?.earning_start_date}
                      </MUITypography>
                      <MUITypography variant="body2" sx={{ padding: '5px', textTransform: 'capitalize' }}>
                        <span style={{ fontWeight: 'bold' }}>Package end date: </span>
                        {item?.earning_end_date}
                      </MUITypography>
                      <MUIDivider sx={{ bgcolor: 'white' }} />
                    </React.Fragment>
                  ))}
                </>
              }
            >
              <AccountCircleIcon sx={{ color: iconColor }} fontSize="large" />
              <div className="details-content">
                <p style={{ fontWeight: 'bold', color: '#003366', margin: 0, textTransform: 'capitalize' }}>
                  {node?.full_name}
                </p>
                <div className="copy-section" style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={{ fontWeight: 'bold', color: iconColor, margin: 0 }}>{node?.unique_id}</p>
                  <Tooltip title="Copy">
                    <IconButton size="small" onClick={() => handleCopy(node?.unique_id)} style={{ marginLeft: 5 }}>
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
                <p style={{ fontWeight: 'bold', color: 'black', margin: 0 }}>Package: {node?.total_package_amount}</p>
                <Chip
                  size="small"
                  skin="light"
                  label={node?.is_active ? 'Active' : 'Inactive'}
                  sx={{
                    '& .MuiChip-label': { textTransform: 'capitalize' },
                    color: node?.is_active ? '#4CAF50' : '#F44336',
                    backgroundColor: node?.is_active ? '#E8F5E9' : '#FFEBEE',
                    cursor: 'context-menu',
                  }}
                />
              </div>
            </Tooltip>
            <div>
              <ul>
                <li>
                  <MUIButton
                    title="Add member"
                    startIcon={<AddIcon />}
                    onClick={() => handleClickOpen(node)}
                    style={{ marginLeft: 'auto' }}
                    variant="outlined"
                    size="small"
                  />
                </li>
              </ul>
              {!node.children && (
                <ul>
                  <li>
                    <MUIButton
                      title="View"
                      startIcon={<VisibilityIcon />}
                      onClick={() => handleClickOpenGenealogy(node)}
                      style={{ marginLeft: 'auto' }}
                      variant="outlined"
                      size="small"
                    />
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
        {node.children && node.children.length > 0 && <ul>{node.children.map((child) => renderTree(child))}</ul>}
      </li>
    );
  };

  return (
    <MUIContainer>
      <TableHeader muiTitle title="Genealogy" />
      <MUIBox sx={{ border: '1px solid rgb(224, 224, 224)', borderRadius: '5px', p: 2 }}>
        <MUIBox className="genealogy-body genealogy-scroll">
          <MUIBox className="genealogy-tree">
            {geneology?.data?.data && <ul>{renderTree(geneology.data.data[0] || {})}</ul>}
          </MUIBox>
        </MUIBox>
      </MUIBox>
      <AddGenealogyUser
        open={open}
        handleClose={handleClose}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
      <GenealogyViewModel
        open={openGenealogy}
        handleClose={handleCloseGenealogy}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
    </MUIContainer>
  );
};

export default withLoader(MyGenealogyView);
