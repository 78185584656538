import React from 'react';
import { Card } from '@mui/material';

function MUICard(props) {
  /**
   * props
   */
  const { children, ...rest } = props;

  return <Card {...rest}>{children}</Card>;
}

export default MUICard;
