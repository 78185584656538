import React, { useEffect } from 'react';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import DiamondIcon from '@mui/icons-material/Diamond';
import WalletIcon from '@mui/icons-material/Wallet';
import RedeemIcon from '@mui/icons-material/Redeem';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useDispatch, useSelector } from 'react-redux';
import MUIContainer from '../../components/Common/MUIContainer';
import MUITypography from '../../components/Common/MUITypography';
import MUIGrid from '../../components/Common/MUIGrid';
import { AppWidgetSummary } from '../../sections/@dashboard/app';
import { fetchUserDashboard, getLoadingState, getUserDashboardDetails } from '../../Redux/Reducer/authReducer';
import MyDetails from './MyDetails';
import withLoader from '../../components/HOC/withLoader';

function Dashboard(props) {
  /**
   * props
   */
  const { setLoading } = props;
  /**
   * hooks
   */
  const dispatch = useDispatch();

  /**
   * get user dashboard details
   */
  const userData = useSelector(getUserDashboardDetails)?.data;

  useEffect(() => {
    dispatch(fetchUserDashboard());
  }, [dispatch]);

  /**
   * get loading state
   */
  const isLoading = useSelector(getLoadingState);

  useEffect(() => {
    setLoading(isLoading);
  }, [setLoading, isLoading]);

  return (
    <MUIContainer>
      <MUITypography variant="h4" gutterBottom>
        Dashboard
      </MUITypography>
      <MUIGrid container spacing={3}>
        <MUIGrid item xs={12} md={8}>
          <MUIGrid container spacing={2}>
            {userData?.reminder_message.status === 'warning' && (
              <MUIGrid item xs={12} sm={12} md={12}>
                <MUITypography sx={{
                  fontSize: "16px",
                  color: "#FF6251",
                  textAlign: "center",
                  borderRadius: "12px",
                  border: "2px solid #FF6251",
                  padding: "10px",
                  fontWeight: "bold"
                }}>
                  {userData?.reminder_message.reminder}
                </MUITypography>
              </MUIGrid>
            )}
            <MUIGrid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title="Daily Cashback Income"
                total={userData?.daily_cashback_income || 0}
                icon={<AccountBalanceWalletIcon />}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title="Daily Level Income"
                total={userData?.daily_level_income || 0}
                color="info"
                icon={<AccountBalanceWalletIcon />}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title="Total Daily Income"
                total={userData?.total_daily_income || 0}
                color="warning"
                icon={<AccountBalanceWalletIcon />}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title="Total Cashback Income"
                total={userData?.total_cashback_income || 0}
                color="error"
                icon={<AccountBalanceWalletIcon />}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title="Total Level Income"
                total={userData?.total_level_income || 0}
                color="success"
                icon={<AccountBalanceWalletIcon />}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title="Total Earning"
                total={userData?.total_earning || 0}
                color="secondary"
                icon={<DiamondIcon />}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title="Total Level Business"
                total={userData?.total_level_business || 0}
                color="secondary"
                icon={<BusinessCenterIcon />}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title="Total Direct"
                total={userData?.total_direct || 0}
                color="error"
                icon={<GroupIcon />}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title="Total Members"
                total={userData?.total_members || 0}
                color="warning"
                icon={<GroupIcon />}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title="Total Paid Earning"
                total={userData?.total_paid_earning || 0}
                color="error"
                icon={<HomeIcon />}
              />
            </MUIGrid>
            <MUIGrid item xs={12} sm={6} md={4}>
              <AppWidgetSummary title="Wallet" total={userData?.wallet || 0} color="warning" icon={<WalletIcon />} />
            </MUIGrid>
            <MUIGrid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title="Withdraw Pending"
                total={userData?.withdraw_pending || 0}
                color="error"
                icon={<RedeemIcon />}
              />
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>

        <MUIGrid item xs={12} md={4}>
          <MyDetails />
        </MUIGrid>
      </MUIGrid>
    </MUIContainer>
  );
}

export default withLoader(Dashboard);
