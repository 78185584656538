import axios from 'axios';
import toast from 'react-hot-toast';
import { getAccessToken, getRefreshToken, saveAccessToken } from '../utils/Helper';
import { getRefreshTokenURL } from './ApiConfig';

const axiosInstance = axios.create({
  baseURL: process.env.FOREX_REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          if (getRefreshToken()) {
            const response = await axios.post(getRefreshTokenURL(), {
              refresh: getRefreshToken(),
            });
            if (response.status === 200) {
              const access = response?.data?.data?.access_token;
              saveAccessToken('access', access);
              return axiosInstance(originalConfig);
            }
          }
        } catch (_error) {
          if (_error?.response?.data?.status === 'failure') {
            toast.error('Your session has expired. Please log in again to continue');
            localStorage.clear();
            window.location.replace('/');
          }
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

const axiosObj = {
  get: axiosInstance.get,
  post: (url, data, options) => axiosInstance.post(url, data, options),
  put: (url, data) => axiosInstance.put(url, data),
  patch: (url, data) => axiosInstance.patch(url, data),
  delete: axiosInstance.delete,
};

export default axiosObj;
