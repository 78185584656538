import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import ThemeProvider from './theme';
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import RouteManager from './Route/RouteManager';
import './index.css'

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Toaster
            position="top-right"
            toastOptions={{
              duration: 5000,
            }}
          />
          <RouteManager />
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
